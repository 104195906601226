import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import '../common/style.css';
import cus from './image/Contact_us.jpg';

const styles = theme => ({
  bigAvatar: {
    width: 250,
    height: 250,
    margin: '0 auto',
    marginTop: 5,
  }
});

export class Contact extends Component {
	constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
 }

  
hideSpinner = () => {
    this.setState({loading: false});
  };
  
render() {
  // const width = window.innerWidth;
  const { classes } = this.props;
 
   // - ((window.innerWidth*10)/100);
    return (
      <div className="container" align="center">
        <Avatar className={classes.bigAvatar} src={cus} />
        <br/>
        <br/>
        <div align="left">
<Typography className="typography"><b>Tel Exchange No.:</b> 0183-2870200</Typography>
<Typography className="typography"><b>Emergency Tel No.:</b> 0183-2870281</Typography>
<Typography className="typography"><b>Ambulance Tel No.:</b> 0183-2870368</Typography>
<Typography className="typography"><b>Admission Enquiry No.:</b> 0183-2870217,0183-2870218</Typography>
<Typography className="typography"><b>Toll Free Number:</b> 8725005188</Typography>
<Typography className="typography"><b>Fax:</b> 0183-2870205, 2870344</Typography>
<Typography className="typography"><b>email:</b> info@sgrduhs.in, sgrduhs@gmail.com</Typography>
</div>
      </div>

      );
  }
}

export default withStyles(styles, { withTheme: true })(Contact);
