import React from 'react';
import PropTypes from 'prop-types';
// import { Redirect } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
import HomeIcon from '@material-ui/icons/Home';
import AccessibleIcon from '@material-ui/icons/Accessible';
import GradeIcon from '@material-ui/icons/Grade';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PaymentIcon from '@material-ui/icons/Payment';
import EmailIcon from '@material-ui/icons/Email';
import ScheduleIcon from '@material-ui/icons/Schedule';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ListItemText from '@material-ui/core/ListItemText';
// import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';
 import { Home } from '../home/home';
 import { About } from '../about/about';
import { Location } from '../univlocation/Location';
 // import { News } from '../news/News';
 import Attendance from '../attendance/Attendance';
 import AttendanceTillDate from '../attendance/AttendanceTillDate';
 import Marks from '../attendance/marks';
 import { removeLocalData } from '../adapter/UserInfo';
 import { getLocalData } from '../adapter/UserInfo';

 import StudentNotification from '../notice/StudentNotification';
 import ClassNotification from '../notice/ClassNotification';
 import Management from '../organization/management';
 import Leadership from '../organization/leadership';
 import Testimonial from '../testimonial/testimonial';
 import Events from '../events/Events';
 import IssuedBooks from '../issuedbooks/IssuedBooks';
 import Tieups from '../tieups/Tieups';

 import Contact from '../contactus/Contact';
 import MailContact from '../contact/MailContact';
 import PaidFee from '../fee/PaidFee';
// import RPCheckout from '../rpfee/RPCheckout';
 
import PayByChallan from '../fee/PayByChallan';    
import FeeDetails from '../fee/feedetail';  
import feepayment from '../fee/feepayment';
import Schedule from '../schedule/Schedule';
import ExaminationForm from '../examinationform/ExaminationForm';

import RegularExamForm from '../examform/ExamForm';
import Assignment from '../assignment/Assignment';

import Notfound from '../notfound/Notfound'; 
import Grid from '@material-ui/core/Grid';
import SvgIcon from '@material-ui/core/SvgIcon';
import Avatar from '@material-ui/core/Avatar';
// import  Login  from '../login/Login';
import OnlineLibrary from '../onlinelibrary/OnlineLibrary';
import MainVisitor from '../mainvisitor/MainVisitor';
import ChangePassword from '../changepassword/ChangePassword';
import ReactDOM from 'react-dom';
import '../common/style.css';
import logo from '../main/utils/7676.png';
// import MoreLessExample from '../examples/MoreLessExample';
// import MLExample from '../examples/MLExample';
// import biglogo from './utils/big-logo.png'

// import ReactDOM, { BrowserHistory } from 'react-dom';
const drawerWidth = 260;


const styles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
   appBar: {
    position: 'fixed',
    marginLeft: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,

  },
  content: {
    flexGrow: 1,
    width: '100&',
    overflow: 'auto',
 //   padding: theme.spacing.unit * 3,
  },

  link: {
    textDecoration: 'none',

  },

blink: {
textDecoration: 'none', fontSize: '5px', fontColor: '#f00',
},

  textstyle: {
fontSize: 15,
marginLeft: 10,
  },

title: {
  fontSize: 20,
  textAlign: 'center',
  marginTop: 5,
},

bigAvatar: {
    width: 150,
    height: 150,
    margin: '0 auto',
    marginTop: 5,
  },

iconHover: {
fontSize: 30, 
float: 'right', 
marginTop: -5,
marginRight: 20,
color: '#fff',
[theme.breakpoints.up('sm')]: {
      display: 'none',
    }, 
}
 
});



function HomeIconh(props) {
  return (
    <SvgIcon {...props}>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </SvgIcon>
  );
}



class Main extends React.Component {
 constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.routeChange = this.routeChange.bind(this);
  }




  routeChange() {
    let path = `/`;
    this.props.history.push(path);
  }

  logout() {
    if(removeLocalData()){
    const root = document.getElementById('root');
    ReactDOM.render(<MainVisitor />, root);
 }

  }


  handleClick() {
   // console.log('Click happened');
  }

  state = {
    mobileOpen: false,
  };

  handleDrawer = () => {
    this.setState(state => ({ mobileOpen: false }));
  };


  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  render() {

  // var base = "/ss";

if(getLocalData()==null) {
       return (<MainVisitor />);
}

const { classes, theme } = this.props;
 
const info = getLocalData();

// console.log(info);
 
     const drawer = (
      <div>
          <Typography style={{color: "white"}} className={classes.title} >SGRD University</Typography>   
     <Typography className={classes.title} >Student</Typography>   
     <Divider />
     { ((info.data) && (info.data.studentDetail) ) ? 
      (
      <div>
      <Avatar alt="" src={info.data.studentDetail.studentPic} className={classes.bigAvatar} align='center'></Avatar>
      <Divider />
     <Typography className={classes.textstyle} >Name: {info.data.studentDetail.name.toUpperCase()}</Typography>
     <Typography className={classes.textstyle} >Roll No.: { info.data.studentDetail.rollNo } </Typography>
     <Typography className={classes.textstyle} >Course: { info.data.studentDetail.course } </Typography>
     <Typography className={classes.textstyle} >Batch: { info.data.studentDetail.batch.substring(0,4) } </Typography>
     <Typography className={classes.textstyle} >Year/Sem.: { info.data.studentDetail.prof } </Typography>
     <Typography className={classes.textstyle} >Blood Group: { info.data.studentDetail.bloodGroup } </Typography>
  </div>
): "" }   
     
<Divider />
<List>
<Link to={'/home'} className={classes.link} onClick={this.handleDrawer}>
<ListItem button key="1">
<ListItemIcon><HomeIcon /></ListItemIcon>
<ListItemText primary="Home" ></ListItemText>
</ListItem>
</Link>
<Link to={'/attendance'} className={classes.link} onClick={this.handleDrawer}>
<ListItem button key="2">
<ListItemIcon><AccessibleIcon /></ListItemIcon>
<ListItemText primary="Day&nbsp;Attendance" ></ListItemText>
</ListItem>
</Link>

<Link to={'/attendancetilldate'} className={classes.link} onClick={this.handleDrawer}>
<ListItem button key="3">
<ListItemIcon><AccessibleIcon /></ListItemIcon>
<ListItemText primary="Subject&nbsp;Attendance" ></ListItemText>
</ListItem>
</Link>


<Link to={'/marks'} className={classes.link} onClick={this.handleDrawer}>
<ListItem button key="4">
<ListItemIcon><GradeIcon /></ListItemIcon>
<ListItemText primary="Marks" ></ListItemText>
</ListItem>
</Link>
<Link to={'/studentnotification'}  className={classes.link} onClick={this.handleDrawer}>
<ListItem button key="5">
<ListItemIcon><LocalOfferIcon /></ListItemIcon>
<ListItemText primary="Notifications" ></ListItemText>
</ListItem>
</Link>
<Link to={'/schedule'}  className={classes.link} onClick={this.handleDrawer}>
<ListItem button key="5">
<ListItemIcon><ScheduleIcon /></ListItemIcon>
<ListItemText primary="Schedule" ></ListItemText>
</ListItem>
</Link>

<Link to={'/issuedbooks'}  className={classes.link} onClick={this.handleDrawer}>
<ListItem button key="6">
<ListItemIcon><LocalLibraryIcon /></ListItemIcon>
<ListItemText primary="Issued Books" ></ListItemText>
</ListItem>
</Link>

<Link to={'/uploadassignment'}  className={classes.link} onClick={this.handleDrawer}>
<ListItem button key="6">
<ListItemIcon><LocalLibraryIcon /></ListItemIcon>
<ListItemText primary="Upload Assignment" ></ListItemText>
</ListItem>
</Link>


<Link to={'/feedetails'}  className={classes.link} onClick={this.handleDrawer}>
<ListItem button key="11">
<ListItemIcon><PaymentIcon /></ListItemIcon>
<ListItemText primary="Fee Detail" ></ListItemText>
</ListItem>
</Link>

<Link to={'/paidfee'}  className={classes.link} onClick={this.handleDrawer}>
<ListItem button key="12">
<ListItemIcon><PaymentIcon /></ListItemIcon>
<ListItemText primary="Paid Fee" ></ListItemText>
</ListItem>
</Link>

{/*
<Link to={'/examinationform'}  className={classes.link} onClick={this.handleDrawer}>
<ListItem button key="12">
<ListItemIcon><InsertDriveFileIcon /></ListItemIcon>
<ListItemText primary="Examination Form" ></ListItemText>
</ListItem>
</Link>
*/}



<Link to={'/examform'}  className={classes.link} onClick={this.handleDrawer}>
<ListItem button key="15">
<ListItemIcon><InsertDriveFileIcon /></ListItemIcon>
<ListItemText primary="Examination Form" ></ListItemText>
</ListItem>
</Link>





<Link to={'/mailcontact'}  className={classes.link} onClick={this.handleDrawer}>
<ListItem button key="13">
<ListItemIcon><EmailIcon /></ListItemIcon>
<ListItemText primary="Contact" ></ListItemText>
</ListItem>
</Link>



{/*<Link to={'/feepayinstructions'}  className={classes.blink} >

<ListItem button key="14">
<ListItemIcon style={{color: 'red'}}><LocalOfferIcon /></ListItemIcon>
<ListItemText primary={ <div style={{color: 'red'}} onClick={() => { window.open('https://sgrduhs.in/classschedule/payfeeinstructions.pdf', '_feeinstr', 'clearsessioncache=yes,clearcache=yes' );}}> {'Instructions To Pay Fee'} </div>}></ListItemText>
</ListItem>
</Link>*/}


<Link to={'/onlinelibrary'}  className={classes.blink} >

<ListItem button key="14">
<ListItemIcon><LocalLibraryIcon /></ListItemIcon>
<ListItemText primary={ <div onClick={() => { window.open('https://api.sgrduhs.in:8181/univmgmtsys/online-library.jsp', '_onlinelibrary' );}}> {'E-Library'} </div>}></ListItemText>
</ListItem>
</Link>








{/*
<Link to={'/onlinelibrary'}  className={classes.link} onClick={this.handleDrawer}>
<ListItem button key="20">
<ListItemIcon><VpnKeyIcon /></ListItemIcon>
<ListItemText primary="Online Library" ></ListItemText>
</ListItem>
</Link>
*/}




<Link to={'/changepassword'}  className={classes.link} onClick={this.handleDrawer}>
<ListItem button key="14">
<ListItemIcon><VpnKeyIcon /></ListItemIcon>
<ListItemText primary="Change Password" ></ListItemText>
</ListItem>
</Link>


</List>

<Button color="primary" className="centerText fullWidth" onClick={this.logout}>
    Logout
</Button>
      </div>
    );

    return (

<Router>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" style={{backgroundImage: "linear-gradient(to right, #113653, #7186a3)", paddingTop: "env(safe-area-inset-top)"}} className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" noWrap >
              SGRD University
            </Typography>

        <Link to={'/'}  onClick={this.handleDrawer} style= {{ position: 'absolute', right: 0, top: 18 }} >
           <HomeIconh className={classes.iconHover} />
</Link>

    </Toolbar>
        </AppBar>
        <nav className={classes.drawer}>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer  
              container={this.props.container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main style={{paddingTop: "env(safe-area-inset-top)"}} className={classes.content}>
          <div className={classes.toolbar} />

<Grid
  container
  spacing={0}
  direction="column"
  alignItems="center"
  justify="center"
  style={{ minHeight: '50vh' }}
>

 <Switch>

              <Route exact path='/home' component={Home} />
              
              <Route path='/attendance' component={Attendance} />
              <Route path='/attendancetilldate' component={AttendanceTillDate} />
              <Route path='/marks' component={Marks} />
              <Route path='/studentnotification' component={StudentNotification} />
              <Route path='/schedule' component={Schedule} />
              
              <Route path='/classnotification' component={ClassNotification} />
              <Route path='/location' component={Location} />
              <Route path='/events' component={Events} />
              <Route path='/feepayment' component={feepayment} />
              <Route path='/management' component={Management} />
              <Route path='/leadership' component={Leadership} />
              <Route path='/testimonial' component={Testimonial} />
              <Route path='/issuedbooks' component={IssuedBooks} />
              <Route path='/tieups' component={Tieups} />
              <Route path='/contact' component={Contact} />
              <Route path='/mailcontact' component={MailContact} />
              
	      <Route path='/uploadassignment' component={Assignment} />

              <Route path='/feedetails' component={FeeDetails} />
              <Route path='/paybychallan' component={PayByChallan} />
              <Route path='/paidfee' component={PaidFee} />
		<Route path='/examinationform' component={ExaminationForm} />
		<Route path='/examform' component={RegularExamForm} />
		

		<Route path='/changepassword' component={ChangePassword} />
	
              
              <Redirect from="/" to="/home" />
              
              <Route component={Notfound} />
                  
              <Route path='/sgrduhs' component={() => {
              window.location.href = 'https://sgrduhs.in';
              return null;
              } } />


		<Route path='/payfee' component={(url) => {
              window.location.href = url;
              return null;
              } } />




	      <Route path='/feepayinstructions' component={() => {
              window.location.href = 'https://sgrduhs.in';
              return null;
              } } />



            
          </Switch>
 </Grid>

        </main>
      </div>
</Router>
    );
  }
}

Main.propTypes = {
  classes: PropTypes.object.isRequired,
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Main);
