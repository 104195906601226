import React from 'react';
import { getAuthentication } from '../adapter/UserInfo';
import Login from '../login/Login';
import Main from '../main/Main';
import MainVisitor from '../mainvisitor/MainVisitor';

export default function StudentAuth () {
  
  if(getAuthentication()===false)
   return ( <MainVisitor /> );
  else
    return ( <Main /> );
}
