import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
//import InboxIcon from '@material-ui/icons/MoveToInbox';
//import MailIcon from '@material-ui/icons/Mail';
import { getLocalData } from '../adapter/UserInfo';
import { getStudentIssuedBooks } from '../adapter/infoURL';
import { addIB } from '../adapter/UserInfo';
import { getIB } from '../adapter/UserInfo';
// import { checkStatus } from '../status/Status';
// import { checkStatus } from '../adapter/checkStatus';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../common/style.css';

const color = () => "#"+Math.floor(Math.random()*255*150*150).toString(16);

const styles = theme => ({
  colorAvatar: {
    width: 50,
    height: 50,
    margin: '0 auto',
    marginTop: 5,
 //   backgroundColor: color()
  }
});

class IssuedBooks extends Component {
constructor(props) {
    super(props);
    this.state = {
    data: [],
    status: false,
    listener: "OFFLINE",
 
  };
}

getStudentId() {
 let user = getLocalData();
  // console.log(user);
 return user.data.studentDetail.studentId;
}



submitRequest = async () => {
  this.datas = {
  "studentId": `${this.getStudentId()}`,
}
this.setState({status: true});
this.setState({listener: ""});
let info=[];
info = await getStudentIssuedBooks(this.datas);
if(info!=null) {
this.setState({data: info.data});
if((this.state) && (this.state.data) &&  (this.state.data.length > 0)){
addIB(this.state.data);
}
}
this.setState({status: false});
}

webListener() {
this.submitRequest();  
}

localListener() {
this.setState({status: true});
// console.log(getSNF());
this.setState({data: getIB()});
this.setState({status: false});
this.setState({listener: "OFFLINE"});
}


componentDidMount() {
/*
if(checkStatus()) {
  this.webListener();
}
else {
this.localListener();
}
*/
this.localListener();
this.webListener();
}

render() {
  const { classes } = this.props;
return (
<div className="container">

{ this.state.status ? <div><CircularProgress className="loader" align="center" variant='indeterminate' color='secondary' /></div>  : "" }
{((this.state) && (this.state.data) && (this.state.data.length > 0) && (this.state.data[0].bookId)) ? 
(<List className="list">
{this.state.data.map((text, index) => (
<ListItem key={index+1} alignItems="flex-start" className="listitem" style={{borderRadius: '10px', border: '1px solid #aaa', boxShadow: '5px 5px 5px #aaa', marginBottom: '20px'}}>
        <ListItemAvatar>
         <Avatar className={classes.colorAvatar} style={{backgroundColor: color()}}>{index+1}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={<b>{"Author Name: "+text.author}</b>}
          secondary={
            <React.Fragment>
            <Typography
                component="span"
                variant="body2"
                className="inline"
                color="textPrimary"
              >
                <b>Book Name: {text.bookname}</b>
              </Typography>
            <Typography
                component="span"
                variant="body2"
                className="inline"
                color="textPrimary"
              >
                Issue Date: {text.issueDate}
              </Typography>
               <Typography
                component="span"
                variant="body2"
                className="inline"
                color="textPrimary"
              >
                Return Date: {text.returnDate}
              </Typography>
<Typography
                component="span"
                variant="body2"
                className="inline"
                color="textPrimary"
              >
                Returned On: {(text.returnedOn!="") ? (<b style={{color: "#090"}}>{text.returnedOn}</b>):(<b style={{color: "#f00"}}>{"Not Returned"}</b>)}
              </Typography>
<Typography
                component="span"
                variant="body2"
                className="inline"
                color="textPrimary"
              >
                Fine Per Day After Due Date: Rs.{text.fine}
              </Typography>
                                    
               <i className="right">{text.publishDate}</i>
            </React.Fragment>
          }
        />

      </ListItem>
  
          ))}
</List>) : "" }

{ ((this.state) && (this.state.data) && (this.state.data.message) && (this.state.data.message ==="null")) ? <Typography align="center" className="typography">No Book Issued</Typography>:""}
{ ((this.state) && (this.state.data) && (this.state.data.message) && (this.state.data.message ==="error")) ? <Typography align="center" className="typography">Problem with Input</Typography>:""}
{ ((this.state) && (this.state.data) && (this.state.data.message) && (this.state.data.message ==="unauthorized")) ? <Typography align="center" className="typography">Unauthorized Access</Typography>:""}
{this.state.con!=true} 

 <div className="mode">{this.state.listener}</div>    
</div>


);
}

}
export default withStyles(styles, { withTheme: true })(IssuedBooks);
