import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import Divider from '@material-ui/core/Divider';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import AttachmentIcon from '@material-ui/icons/Attachment';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from '@material-ui/icons/Mail';
import { getLocalData } from '../adapter/UserInfo';
import { addCNF } from '../adapter/UserInfo';
import { getCNF } from '../adapter/UserInfo';
// import { checkStatus } from '../status/Status';
// import { checkStatus } from '../adapter/checkStatus';
import { getClassNotification }from '../adapter/infoURL';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../common/style.css';


const color = () => "#"+Math.floor(Math.random()*255*150*150).toString(16);

const styles = theme => ({
  colorAvatar: {
    width: 50,
    height: 50,
    margin: '0 auto',
    marginTop: 5,
    backgroundColor: color() //'rgb('+Math.floor(Math.random()*255)+','+Math.floor(Math.random()*255)+','+Math.floor(Math.random()*255)+')'
  }
});


class ClassNotification extends Component {
constructor(props) {
    super(props);
  this.state = {
    data: [],
    status: false,
    showData: false,
    listener: "OFFLINE"
  };
}

getSessionIdentifier() {
 let user = getLocalData();
 // console.log(user.data.studentDetail.sessionIdentifier);
return user.data.studentDetail.sessionIdentifier;
}



submitRequest = async () => {
  this.datas = {
  sessionIdentifier: `${this.getSessionIdentifier()}`,
}
this.setState({status: true});
this.setState({listener: ""});

let info = await getClassNotification(this.datas);
// console.log(info);
if(info!=null) {
this.setState({data: info.data});
}

this.setState({status: false});
if((this.state) && (this.state.data) &&  (this.state.data.length > 0)){
addCNF(this.state.data);
}
}

webListener() {
this.submitRequest();  
}

localListener() {
this.setState({data: getCNF()});
this.setState({listener: "OFFLINE"});
}

  componentDidMount() {
this.localListener();
this.webListener();
}

handleShowChange(e) {
 const { data } = this.state;
 if(e.target.id==="" || e.target.id===null || e.target.id===undefined){}
 else {
 const { id } = e.target;
 data[id].show = !this.state.data[id].show
 this.setState({ data });

}
}

render() {
 // const { classes } = this.props;
return (
<div className="container">

{ (this.state.status===true) ? <div><CircularProgress className="loader" align="center" variant='indeterminate' color='secondary' /></div>  : "" }
{((this.state) && (this.state.data) && (this.state.data.length > 0)) ? 
(<List className="list">
{this.state.data.map((text, index) => (
<ListItem button id={index} key={index} className="listitem" style={{borderRadius: '10px', border: '1px solid #aaa', boxShadow: '5px 5px 5px #aaa', marginBottom: '20px'}} onClick={(e) => this.handleShowChange(e)} alignItems="flex-start">
        <ListItemAvatar id={index}>
         <Avatar style={{backgroundColor: color()}}>{index+1}</Avatar>
        </ListItemAvatar>
        <ListItemText id={index} 
          primary={<div id={index}>{(text.attachmentUrl.trim().length > 3) ? (<AttachmentIcon  className="right" fontSize="large" style={{color: color()}} onClick={() => { window.open(text.attachmentUrl.trim(), '_blank', 'clearsessioncache=yes,clearcache=yes' );}} />) : ""}{(text.subject.toUpperCase())}</div>}

          secondary={
 <React.Fragment id={index}>
 
            {(text.show) ?
            (<Typography  id={index} 
                component="span"
                variant="body2"
                
                color="textPrimary"
              ><br/>
                {text.notice}
              </Typography>):""}
               
               <i id={index} className="right"><br/>{text.publishDate}</i>

            </React.Fragment>
          }
        />
        
      </ListItem>
  
          ))}
</List>) : "" }

{ ((this.state) && (this.state.data) && (this.state.data.message) && (this.state.data.message ==="null")) ? <Typography align="center" className="typography">No Notifications</Typography>:""}
{ ((this.state) && (this.state.data) && (this.state.data.message) && (this.state.data.message ==="error")) ? <Typography align="center" className="typography">Problem with Input</Typography>:""}
{ ((this.state) && (this.state.data) && (this.state.data.message) && (this.state.data.message ==="unauthorized")) ? <Typography align="center" className="typography">Unauthorized Access</Typography>:""}
  <div className="mode">{this.state.listener}</div>    
</div>
);
}

}
export default withStyles(styles, { withTheme: true })(ClassNotification);
