import React, { Component } from 'react';
// import DataTable2 from '../components/DataTable2';
import { getLocalData } from '../adapter/UserInfo';
import { getExamSubjectsResponse } from '../adapter/infoURL';
import { fillExaminationFormResponse } from '../adapter/infoURL';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../styles/formstyles.css';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import '../common/style.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';



import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

// import { checkStatus } from '../status/Status';
// import { checkStatus } from '../adapter/checkStatus';

export default class ExaminationForm extends Component {
  getLocalStorage = async () => {
  let user = await getLocalData();
// console.log("\n\n\n\nUser Data\n\n\n\n", user.data);
  return user.data;
} 

  state={
studentId: 0,
    status: false,
    open: false,
    data: [],
    subjectId: 0, // `${this.getSubjects()[0].subjectId}`,
    listener: "",
    con: true,
    connected: false,
semester: "0",
prof: "0",
semesterList: [],
stateOne: false,
type: "Regular",
subjectArray: [],
session: ""
   }

getStudentSessionId = async () => {
let data = await this.getLocalStorage();
      return data.studentDetail.studentSessionId;
}

getStudentId = async ()  => {
let data = await this.getLocalStorage();
// console.log("Student Id: ", data.studentDetail.studentId);
this.setState({studentId: data.studentDetail.studentId});
return data.studentDetail.studentId;
}

async getSemester() {
let data = await this.getLocalStorage();
// console.log("Student sem: ", data.studentDetail.semester);
this.setState({semester: data.studentDetail.semester});
// console.log(this.state.semester);
      return parseInt(data.studentDetail.semester);
}

getProf = async () => {
let data = await this.getLocalStorage();
// console.log("Student Id: ", data.studentDetail.studentId);
this.setState({prof: data.studentDetail.prof});
// console.log("Prof: ", this.state.prof);
      return data.studentDetail.prof;

}



getSubjects = async () => {
// console.log("Student IDDDD: ",`${this.getStudentId()}`);
this.indata = {
'studentId': `${this.state.studentId}`,
'prof': `${this.state.prof}`,
'semester': `${this.state.semester}`
}
  let subjects = await getExamSubjectsResponse(this.indata);
  //   console.log("data subjects: ",subjects.data); 
this.setState({data: subjects.data});
  return subjects;
}

async getSemestersList() {
await this.getSemester();
     let items = [];         
     for (let i = 1; i <= parseInt(this.state.semester); i++) {             
          items.push(<MenuItem key={i} value={i}>{i}</MenuItem>);   
          //here I will be creating my options dynamically based on
          //what props are currently passed to the parent component
     }
// console.log("array data hai",items);
this.setState({semesterList: items});
return items;
 }  



submitRequest = async () => {
if(this.state.data[0] && this.state.data[0].id && this.state.data.length > 0) {
var subarray=[];
var type=window.document.getElementById("type").value;
if(type==null || type=="")
{
window.alert("Please Select Type of Exam");
return;
}

var session=window.document.getElementById("session").value;
if(session==null || session=="")
{
window.alert("Please Select Session");
return;
}
if(type=="Regular")
{
for( let sub=0; sub < this.state.data.length;sub++)
window.document.getElementById("subject"+sub).checked=true;
}

for( let sub=0; sub < this.state.data.length;sub++)
{
if(window.document.getElementById("subject"+sub).checked) {
var subid=window.document.getElementById("subject"+sub).value.toString();
// var data={"\"subjectId\"": "\""+subid+"\""};
var data={"subjectId": ""+subid+""};

subarray.push(data);
// console.log("value: ",window.document.getElementById("subject"+sub).value);
}
// console.log(window.document.getElementById("subject"+sub).value);
}
// console.log(subarray);
let subjectArray = subarray;
// console.log("subject array length: ", subjectArray.length);
if(subjectArray.length <= 0 )
{
window.alert("Please Select Subjects"); 
return;
}

this.indata = {
"studentId": `${this.state.studentId}`, /* `${this.getStudentId()}` */
"studentSessionId": `${this.getStudentSessionId()}`,
"type": `${this.state.type}`,
"session": `${this.state.session}`,
"subjectArray":  subarray,
}
// console.log("data sent ", this.getStudentId(), this.getStudentSessionId(), subarray);
this.setState({status: true});
this.setState({listener: ""});
const info = await fillExaminationFormResponse(this.indata);
 console.log(info);
if(info!=null && info.data.message=="success") {
this.setState({data: info.data});
this.setState({con: true});
window.alert("Form Submitted Successfully!\n Please Go To Fee Detail Link to Pay Examination Fee Online");
this.props.history.push("/feedetails");
}
else if(info!=null && (info.data.message!=="success") ) {
this.setState({data: info.data});
}
else if(info==null){
this.setState({con: false});

}
this.setState({status: false});

}

}

handleProfSemChange = async (event) => {
await     this.setState({semester: event.target.value});
     this.getSubjects();
    }

handleTypeChange = async (event) => {
await this.setState({type: event.target.value});
// console.log(this.state.type);
    }

handleSessionChange = async (event) => {
await this.setState({session: event.target.value});
// console.log(this.state.session);
    }

  
async componentDidMount() {
await this.getStudentId();
await this.getProf();
await this.getSemestersList();
// console.log("\n\n\nsem list\n\n\n", `${this.state.semesterList}`)
}


handleClose = () => this.setState({ open: false });

render() {


return(
<div style={{margin:'10px'}} className="container">
<div align="center" className="titlewidth">
<Typography variant='h4'>Examination Form</Typography>
 </div>
 <div>
 {(this.state.con!==true) ? (<Typography style={{color: '#ff0000'}} align="center">Internet Or Data Not Available<br/></Typography>) : ""}
 { 
  (this.state.status) ? 
  (<div align="center" ><CircularProgress className='loader' variant='indeterminate' color='secondary' /></div> )
  : (
  ((this.state.data) && (this.state.data.length > 0) && (this.state.data[0]) && this.state.data[0].id != null) ? (


<div align="center">
<br/>
<br/>
<Typography variant='h6'>Examination Semester/Prof: {this.state.semester}</Typography>
<br/>
<div>
<Typography variant='h6'>Select Type</Typography>
<Select id={"type"} name={"type"} onChange={e => this.handleTypeChange(e)} value={this.state.type}><MenuItem key="1" value="Regular">{"Regular"}</MenuItem><MenuItem key="2" value="Re-appear">{"Re-appear"}</MenuItem></Select>
<br/>

<br/>
</div>
<div>
<Typography variant='h6'>Select Session</Typography>
<Select id={"session"} name={"session"} onChange={e => this.handleSessionChange(e)} value={this.state.session}><MenuItem key="1" value="2020-09-01">{"Sep-2020"}</MenuItem></Select>
<br/>

<br/>
</div>


<div align="center">
<Table className="tbody" style={{paddingRight: "1px", paddingLeft: "1px"}}>
        <TableHead className="tablehead">
          <TableRow>
	    <TableCell size="medium" className="tablecell-title" align="center">Subject Code</TableCell>
	<TableCell size="medium" className="tablecell-title" align="center">Subject Name</TableCell>
<TableCell size="medium" className="tablecell-title" align="center">Subject Selection</TableCell>
        </TableRow>

        </TableHead>

        <TableBody>
          {this.state.data.map((subject, index) => (
            <TableRow key={index}  className="tablerow">
		<TableCell size="small" className="tablecell" align="left">{subject.code}</TableCell>
		<TableCell align="right">{subject.name}</TableCell>
		<TableCell align="right">{(<input type="checkbox" id={"subject"+index} name={"subject"+index} key={subject.id} value={subject.id} /* checked={(this.state.type=="Regular") ? true: false} */  />)} </TableCell>

</TableRow>
           
          ))}
      </TableBody>
      </Table>
<br/>
<Button align="center" name="submit" id="submit" value="Submit Form" variant="outlined" onClick ={(event) => this.submitRequest()}>Submit Form</Button> 
<br/>
<br/>

<Typography>Note: You Need Not To Check Subjects In Case of Regular Examination form Submission</Typography>
</div>
</div>

)
 
  : (
  ((this.state.data) && (this.state.data.message) && (this.state.data.message ==="null")) ? <Typography align="center" className="typography">Not Active</Typography> 
  : (
  ((this.state.data) && (this.state.data.message) && (this.state.data.message ==="error") && (this.state.data.error)) ? <Typography align="center" className="typography">{this.state.data.error.toString()}</Typography> 
  : (
  ((this.state.data) && (this.state.data.message) && (this.state.data.message ==="unauthorized")) ? <Typography align="center" className="typography">Unauthorized Access</Typography>
  : (
  <div>
      <Dialog
      open={this.state.open}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Message"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Internet Or Data Not Available
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>

)
)
)
)
)

}
</div>

<br/>
<br/>

<div align="center">
 <InputLabel align="center" className="padding">Select Prof/semester</InputLabel><br/>
<Select onChange={e => this.handleProfSemChange(e)} value={this.state.semester}>{this.state.semesterList} </Select>

</div>
<div className="mode">{this.state.listener}</div>    
</div>

);

}

} 

