import React from 'react';
import inWords from './RsToWords';
//import ReactDOM from 'react-dom';
// import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
// import { Table, DataTableCell, TableBody, TableCell, TableHeader } from '@david.kucsai/react-pdf-table';
// import { PDFViewer } from '@react-pdf/renderer';
// import { withStyles } from '@material-ui/core/styles';
// import { PDFViewer } from '@react-pdf/renderer';
import { getLocalData } from '../adapter/UserInfo';
import logo from './logo/big-logo.png';
import Page from '../components/Page';
import '../common/style.css';
import './responsive.css';
import Typography from '@material-ui/core/Typography';
import Pdf from "react-to-pdf";

import  jsPDF from "jspdf";

import Button from '@material-ui/core/Button';


import {Filesystem, Directory} from '@capacitor/filesystem';
import * as htmlToImage from 'html-to-image';
import { Capacitor } from '@capacitor/core';

// import { Browser } from '@capacitor/browser';


export const getLocalStorage = () => {
  let user = getLocalData();
// console.log(user.data);
   return user.data;
} 

let viewDownloadButtonOption = () => {
if(Capacitor.getPlatform() === "android" || Capacitor.getPlatform() === "ios") {
document.getElementById("browserdownload").display="none";
}
else {
document.getElementById("appdownload").display="none";
}
}





let downloadfile = async () => {

var filepath="";
var img = null;
var filename="Receipt_"+Date.now().toString()+".jpg";

if(Capacitor.getPlatform() === "android" || Capacitor.getPlatform() === "ios") {
if(window.confirm("Save File?")) {
htmlToImage.toJpeg(document.getElementById('savedoc'), { quality: 0.95 })
.then(function(dataurl) {

Filesystem.writeFile({ path: filename, data: dataurl, directory: Directory.Documents }).then((res) => { alert ("File Saved in "+res.uri); 

/*
 img = new Image();
    img.src = dataurl;
   // document.body.appendChild(img);


var htmldata = "<html><body><div>"+img+"</div></body></html>";

var w = window.open("doc.html", '_receipt', 'left=0,top=0,location=0,toolbar=0,titlebar=0,manubar=1,scrollbars=1,status=1, location=no,toolbar=no,titlebar=no,manubar=yes,scrollbars=yes,status=no').document.body.appendChild(img);
// w.document.write(htmldata);

//document.getElementById("savedoc").innerHTML = htmldata;

*/
	




/*

var filereader = new FileReader();
//  filereader.readAsDataURL(res.uri);
 //      var base64 = evt.target.result;
    //   return base64
 const file = new File([filename], res.uri); 
// const file = res.uri; 
filereader.onloadend = () => {
            // Use a regex to remove data url part
            const base64String = filereader.result
                .replace('data:', '')
                .replace(/^.+,/, '');

    //        console.log(base64String);
            // Logs wL2dvYWwgbW9yZ...

// var image = new Image();
    var    imgdata = "data:image/jpg;base64," + base64String;
        // var w = window.open(image.src, '_receipt', 'left=0,top=0,location=0,toolbar=0,titlebar=0,manubar=1,scrollbars=1,status=1, location=no,toolbar=no,titlebar=no,manubar=yes,scrollbars=yes,status=no');
 //        w.document.write(image.outerHTML);
var htmldata = "<html><body><img src='"+imgdata+"' /></body></html>";
var w = window.open("", '_receipt', 'left=0,top=0,location=0,toolbar=0,titlebar=0,manubar=1,scrollbars=1,status=1, location=no,toolbar=no,titlebar=no,manubar=yes,scrollbars=yes,status=no');
w.document.write(htmldata);


        };
 filereader.readAsDataURL(file);

*/









/*
window.open('https://sis.sgrduhs.in', '_receipt', 'left=0,top=0,location=0,toolbar=0,titlebar=0,manubar=1,scrollbars=1,status=1, location=no,toolbar=no,titlebar=no,manubar=yes,scrollbars=yes,status=no');


window.open('file:///'+res.uri, '_receipt', 'left=0,top=0,location=0,toolbar=0,titlebar=0,manubar=1,scrollbars=1,status=1, location=no,toolbar=no,titlebar=no,manubar=yes,scrollbars=yes,status=no');
*/


}, (err) => { alert(JSON.stringify(err) ); } ) 
});
}
}



/*
window.open(filepath, '_receipt', 'left=0,top=0,location=0,toolbar=0,titlebar=0,manubar=1,scrollbars=1,status=1, location=no,toolbar=no,titlebar=no,manubar=yes,scrollbars=yes,status=no');
*/



}




let jspdffromhtml = async () => {
/*
let content = document.getElementById('printreceipt').innerHTML;
if(window.cordova) {
this.createPDF(content);
}
*/

const doc = new jsPDF('p', 'pt', 'a4');

 doc.text(10,10,"content");
// await doc.html(10,10, content);
 doc.save("receipt.pdf");

var pdfOutput = doc.output();

console.log( pdfOutput );

/*
if(window.cordova) {

  //NEXT SAVE IT TO THE DEVICE'S LOCAL FILE SYSTEM
  console.log("file system...");
  window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, function(fileSystem) {

     console.log(fileSystem.name);
     console.log(fileSystem.root.name);
     console.log(fileSystem.root.fullPath);

     fileSystem.root.getFile("test.pdf", {create: true}, function(entry) {
        var fileEntry = entry;
        console.log(entry);

  entry.createWriter(function(writer) {
     writer.onwrite = function(evt) {
     console.log("write success");
  };

        console.log("writing to file");
           writer.write( pdfOutput );
        }, function(error) {
           console.log(error);
        });

     }, function(error){
        console.log(error);
     });
  },
  function(event){
   console.log( evt.target.error.code );
  });


}
else {
doc.save("receipt.pdf");
}

*/

}

const openChallan = (doc) => {
var WinPrint = window.open(doc, '_blank', 'left=0,top=0,location=0,toolbar=0,titlebar=0,manubar=1,scrollbars=1,status=1, location=no,toolbar=no,titlebar=no,manubar=yes,scrollbars=yes,status=no');
WinPrint.document.close();
// WinPrint.focus();
 WinPrint.print();
// WinPrint.close();
}


/*
function restartCordovaApp() {
  if (navigator.userAgent.indexOf('Android') > -1) {
    // Android
    window.open('file:///android_asset/www/index.html')
  } else {
    // IOS
    window.open('cdvfile://localhost/bundle/www/index.html')
  }
}
*/

 

let printdoc = async (data) => {
	let printdata = document.getElementById("printreceipt").innerHTML;
var mywindow ="";







var mywindow = await window.open('recipt', '_blank', 'status=no, toolbar=no, menubar=no, location=no, addressbar=no');
	mywindow.document.open();
    mywindow.document.write('<html><head><title></title><link rel="stylesheet" href="../common/style.css" /><link rel="stylesheet" href="../responsive.css" /></head>');
    mywindow.document.write('<body><style> .table {  border-collapse: collapse; margin-left: 10%; } .table, .th, .td {  border: 1px solid black; padding: 10px; }  .titlefont { text-align: center; font-size: 120%; } </style><div align="center">');

{/*
mywindow.document.write("<Pdf targetRef={ref} filename='feereceipt.pdf' x='-20' y='0'>{({ toPdf }) =><Button style={{border: '1px solid #aaa', left:'25%'}} onClick={toPdf}>Download</Button>}  </Pdf>");
*/}

    mywindow.document.write(printdata);
    
        mywindow.document.write('</div></body></html>');
        setTimeout(function() { // wait until all resources loaded 
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10
        // mywindow.print(); // change window to winPrint
       // mywindow.close(); // change window to winPrint
     }, 250);
    return true;
}

let printserverdoc = async () => {
	let printdata = document.getElementById("printreceipt").innerHTML;
    var mywindow = window.open('https://sgrduhs.in', '_system', 'status=no, toolbar=no, menubar=no, location=no, addressbar=no');
	mywindow.onload = await  function() { this.document.innerHTML += '<html><head><title></title><link rel="stylesheet" href="../common/style.css" /><link rel="stylesheet" href="../responsive.css" /></head><body><style> .table {  border-collapse: collapse; margin-left: 10%; } .table, .th, .td {  border: 1px solid black; padding: 10px; }  .titlefont { text-align: center; font-size: 120%; } </style><div align="center">'+printdata+'</div></body></html>';
}
return true;
}


const ref = React.createRef();



const FeeReceipt = ({id, data}) => {

let user = getLocalData();

/*
 if(Capacitor.getPlatform() === "android" || Capacitor.getPlatform() === "ios") {
 document.getElementById("docpdf").display="none";
}
else {
 document.getElementById("savedoc").display="none";
}
*/



// console.log(user);
// console.log(data);
return (

  <div style={{left: "-25mm", top: "-5mm" }} className="App">
 <br/><br/><br/><br/>
 
{ (Capacitor.getPlatform() === "android" || Capacitor.getPlatform() === "ios") ?  (<Button id="appdownload" style={{border: '1px solid #aaa', left:"25%"}} onClick={() => downloadfile()}>Download</Button>) : ( 
<Pdf id="docpdf" targetRef={ref} filename="feereceipt.pdf" x="-20" y="0">
        {({ toPdf }) =><Button id="browserdownload" style={{border: '1px solid #aaa', left:"25%"}} onClick={toPdf}>Download</Button>}
</Pdf> ) }


{/*
{((<Button style={{border: '1px solid #aaa', left:"25%"}} onClick={() => printdoc()}>Get Receipt</Button>))}

{((<Button style={{border: '1px solid #aaa', left:"25%"}} onClick={() => printserverdoc()}>Get Receipt</Button>))}
*/}



{/*
<Pdf targetRef={ref} filename="feereceipt.pdf" x="-20" y="0">
        {( openChallan({toPdf}) ) =><Button style={{border: '1px solid #aaa', left:"25%"}} onClick={openChallan(toPdf)}>Open Challan</Button>}
      </Pdf>
*/}



{/*
<Button style={{border: '1px solid #aaa', left:"25%"}} onClick={ () => printdoc()}>Print</Button>
*/}
<div style={{ width: "125%", height:"320mm", fontSize: "12px", marginLeft: "0%", overflow: "auto", backgroundColor: "#fff"}}>

<div id="savedoc" ref={ref} style={{width: "210mm", height:"297mm", fontSize: "12px", backgroundColor: "#fff"}}>
<div className="challan" style={{backgroundColor: "#fff"}}>

<div style={{height: "30px"}}></div>

<table className="topmargin"><tbody><tr><td>
<img src={ "big-logo.png" } alt="" className="logostyle"/></td>
<td className="titlefont" style={{fontSize: "18px", align: "center"}}>Sri Guru Ram Das University Of Health Sciences<br/>
<i style={{fontSize: '15px'}}>A Unit Of Sri Guru Ram Das Charitable Hospital Trust</i><br/>
Sri Amritsar</td>
</tr>
<tr><td></td><td></td></tr>
<tr className="titlefont" ><td></td><td>Fee Receipt</td></tr>

</tbody></table>
<br/>
<table border="1" style={{margin: "auto", width: "50%"}} className="feeinfo table">
<tbody>


<tr className="marginbetween"><td className="td">Name: </td><td className="td">{user.data.studentDetail.name.toUpperCase()}</td></tr>
<tr className="marginbetween"><td className="td">Roll No.: </td><td className="td">{user.data.studentDetail.rollNo}</td></tr>
<tr className="marginbetween"><td className="td">Course: </td><td className="td">{user.data.studentDetail.course}</td></tr>
<tr className="marginbetween"><td className="td">Batch: </td><td className="td">{user.data.studentDetail.batch.substring(0,4)}</td></tr>

<tr className="marginbetween"><td className="td">Contact No.: </td><td   className="td">{user.data.studentDetail.contact}</td></tr>
<tr className="marginbetween"><td className="td">Description: </td><td   className="td">{data.description}</td></tr>

<tr className="marginbetween"><td className="td">Payment ID: </td><td className="td">{data.paymentId}</td></tr>
<tr className="marginbetween"><td className="td">Payment Mode: </td><td className="td">{data.paymentMode.toUpperCase()}</td></tr>

<tr className="marginbetween"><td className="td">Date of Payment: </td><td className="td">{data.transactionDate}</td></tr>
<tr className="marginbetween"><td className="td">Transaction ID: </td><td className="td">{data.transactionId}</td></tr>

<tr className="marginbetween"><td className="td">Fee Paid Rs.: </td><td className="td">{data.transactionAmount}</td></tr>
<tr className="marginbetween totalinfo"><td colspan='5' className="td">Fee Paid Rs. (In Words): {inWords(parseInt(data.transactionAmount))}</td></tr>

</tbody>
</table>
<br/>
<Typography style={{marginLeft: '10%'}}><i style={{fontSize: '12px'}}>This is computer generated receipt needs no signature.</i></Typography>

</div>
</div>
</div>

</div>


  );

} 

export default FeeReceipt;

