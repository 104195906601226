import React, { Component } from 'react';
// import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { getLocalData } from '../adapter/UserInfo';
import { MobilePDFReader } from 'reactjs-pdf-reader';
// import { MobilePDFReader } from 'react-read-pdf';

// import { getPDFFile } from '../adapter/infoURL'; 
import '../common/style.css';

const styles = theme => ({
  
});

export class Schedule extends Component {
 
constructor(props) {
    super(props);
    this.state = {
      loading: true,
      connected: false,
      pdffile: ""
    };
   }

  
hideSpinner = () => {
    this.setState({loading: false});
  }


checkFileExists = (path) => {
console.log(path);
  try {
//console.log("dhjgfgdghjdgfhjdg fjhgfhj");
// console.log("dhjgfgdghjdgfhjdg fjhgfhj", require(path));
console.log(require(`${path}`).getName());
if(require(`${path}`)==true) {
console.log("true");
}
else {
console.log("false");
}
if(require(`${path}`))
return (`${path}`);
else 
return null;
  } catch (err) {
   return err;
  }
};



 /*
checkFileExists = () => {
var info = getLocalData();
var prof = info.data.studentDetail.prof;
var course = (info.data.studentDetail.course).toLowerCase();
var pathurl = "https://www.sgrduhs.in/webclassschedule/"+course+"/"+prof+"/"+course+prof+".pdf";
HttpWebResponse response = null;
var request = (HttpWebRequest)WebRequest.Create(pathurl);
request.Method = "HEAD";

try
{
    response = (HttpWebResponse)request.GetResponse();
   return pathurl;
console.log(pathurl);
}
catch (WebException ex)
{
 return null;
    
}
finally
{
   // Don't forget to close your response.
    if (response != null)
    {
        response.Close();
    }
}
};

*/


render() {
// const width = window.innerWidth; // - ((window.innerWidth*10)/100);
var info = getLocalData();
var prof = info.data.studentDetail.prof;
var course = (info.data.studentDetail.course).toLowerCase();

var pathurl =  "https://www.sgrduhs.in/webclassschedule/mbbs/1/mbbs1.pdf";
//  pathurl =  "http://localhost:3000/mbbs1.pdf";
 pathurl = "https://www.sgrduhs.in/webclassschedule/"+course+"/"+prof+"/"+course+prof+".pdf";


//  var pathurl = "https://www.sgrduhs.in/webclassschedule/"+course+"/"+prof+"/"+course+prof+".pdf";
 // var pathurl = "https://www.sgrduhs.in/classschedule/"+course+"/"+prof+"/"+course+prof+".pdf";
 

// var pathurl = "https://cors-anywhere.herokuapp.com/https://api.sgrduhs.in:8181/univmgmtsys/classschedule/"+course+"/"+prof+"/"+course+prof+".pdf";
// var pathurl = https://api.sgrduhs.in:8181/univmgmtsys/classschedule/"+course+"/"+prof+"/"+course+prof+".pdf";

 console.log(`${pathurl}`);


    return (
      <div className="container" >
       
<div style={{overflow:'auto', height:600}}><MobilePDFReader url={`${pathurl}`} /></div>


{/*


            { (getPDFFile(`${pathurl}`)==null) ? <div style={{color: 'red', textAlign: 'center'}}>{`${pathurl}`}</div> : <div style={{overflow:'scroll', height:600}}><MobilePDFReader url={`${pathurl}`} /></div>}
       

            { (this.checkFileExists(`${pathurl}`)==null) ? <div style={{color: 'red', textAlign: 'center'}}>{`${pathurl}`}</div> : <div style={{overflow:'scroll', height:600}}><MobilePDFReader url={`${pathurl}`} /></div>}

            { (this.checkFileExists()==null) ? <div style={{color: 'red', textAlign: 'center'}}>Not Available</div> : <div style={{overflow:'scroll', height:600}}><MobilePDFReader url={this.checkFileExists()} /></div>}

     */}

       
   </div>

      );
  }
}

export default withStyles(styles, { withTheme: true })(Schedule);
