import React from 'react';
//import SinglePage from "./components/SinglePage";

// import MultiPage from "./components/MultiPage";
import PrintButton from "../components/PrintButton";
import PayByChallan from "./PayByChallan";
import './responsive.css';
import Button from '@material-ui/core/Button';



import {Filesystem, Directory} from '@capacitor/filesystem';
import * as htmlToImage from 'html-to-image';
import { Capacitor } from '@capacitor/core';


let downloadfile = async () => {
if(window.confirm("Save File?")) {
htmlToImage.toJpeg(document.getElementById('challanpage'), { quality: 0.95 })
.then(function(dataurl) {
if(Capacitor.getPlatform() === "android" || Capacitor.getPlatform() === "ios") {
Filesystem.writeFile({ path: "Challan_"+Date.now().toString()+".jpeg", data: dataurl, directory: Directory.Documents }).then((res) => { alert ("File Saved in "+res.uri); }, (err) => { alert(JSON.stringify(err) ); } ) 
}
});
}
}




function printchallan() {
var prtContent = window.document.getElementById("challanpage");

var WinPrint = window.open('', '_blank', 'left=0,top=0,location=0,toolbar=0,titlebar=0,manubar=1,scrollbars=1,status=1, location=no,toolbar=no,titlebar=no,manubar=yes,scrollbars=yes,status=no');
WinPrint.document.write("<html><head><title></title></head><body>");

WinPrint.document.write("<script> .challan { width: 100%; } .topmargin { margin-top: -10%; } .logostyle { height: 50%; } .titlefont { text-align: center; font-size: 120%; } .copyname { text-align: center; font-size: 100%; margin-top: 10%; } .feeinfo { font-size: 90%; } .totalinfo { font-weight: bold; font-size: 120% } .margin { margin: 10%; } .printbtn { border: 1px solid #aaa; display: none } </script>");

WinPrint.document.write(prtContent.outerHTML);
WinPrint.document.write("</body></html>");
WinPrint.document.close();
// WinPrint.focus();
																																																																																																																																																																		
 WinPrint.print();
 WinPrint.close();
}


function getChallan() {
if(Capacitor.getPlatform() === "android" || Capacitor.getPlatform() === "ios") {
downloadfile();
}
else {
printchallan();
}
}





function Challan({data}) {

//	console.log(data);
return (
<div>


{/*
<PrintButton id={"challan"} label={" Download "} />
*/}
<Button key="b2" style={{flex: '0 0 100%', border: '1px solid #aaa'}} onClick={() => getChallan()}>&nbsp;Get Challan&nbsp;</Button>
<br/>
<br/>
<br/>
<br/>


<div id={"challanpage"} align="center" style={{backgroundColor: "#fff", width:"210mm"}}>
 <PayByChallan data={data} />
</div>





<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
</div>
);
}

export default Challan;
