import { getData } from './Source';

export const checkStatus = async () => {
var webstatus = false;
var res = await getData(); 
// console.log(res.data);

if((res.data) && (res.data.s == "1"))	
{
webstatus = true;
}
// console.log(webstatus);
return webstatus;
}
