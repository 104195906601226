import React from 'react';

class Notfound extends React.Component {
render() {
return (
<div id="container">Resource Not Found</div>
);
}
}

export default Notfound;

