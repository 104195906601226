import { getattendance } from '../adapter/infoURL';
import { getlectureattendance } from '../adapter/infoURL';

import { getattendanceCount } from '../adapter/infoURL';

/*
export const getAttendance = async (json)=>{
    return await getattendance(json);
}

*/

export const getAttendance = async (json)=>{
    return await getlectureattendance(json);
}




export const getAttendanceCount = async (json)=>{
    return await getattendanceCount(json);
}
