import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
//import { checkStatus } from '../status/Status';
// import { checkStatus } from '../adapter/checkStatus';
import '../common/style.css';

const styles = theme => ({
  
});

export class OnlineLibrary extends Component {
	constructor(props) {
    super(props);
    this.state = {
      loading: true,
      connected: true 
    };
   }

  
hideSpinner = () => {
    this.setState({loading: false});
  };

onError = () => {
    this.setState({connected: false});
  };



render() {
  const width = window.innerWidth; // - ((window.innerWidth*10)/100);
    return (
      <div className="container" >
        {((this.state.loading)) ? <div align="center"><CircularProgress className="loader" variant='indeterminate' color='secondary' /></div>  : <div></div>}
        {((this.state.connected)) ? (<iframe
          src="https://portal.dvlonline.com"
          width= {width}
          height= {window.innerHeight+"px"}
          onLoad={this.hideSpinner}
          onError={this.onError}
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          title="news"
        />) : (<div><Typography align="center">Internet Or Data Not Available</Typography></div>) }
      </div>
      );
  }
}

export default withStyles(styles, { withTheme: true })(OnlineLibrary);
