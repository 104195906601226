import React, { Component } from 'react';

// import { withRouter } from "react-router-dom";

// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TextField } from '@material-ui/core';
// import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { getLocalData } from '../adapter/UserInfo';
import { uploadAssignment } from '../adapter/infoURL';


/*
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
*/

export default class Assignment extends Component {
state = {
openexception: false,
opensuccess: false,
data: '',
subjectName: '',
assignmentName: '',
wrongAssignmentName: false,
base64PDF: '',
status: false,
connection: true,
filename: ''
}


checkSpecialChar(valu) {
var val=valu.trim();
var status = false;
for(var i=0;i<val.length;i++) {
if( val==null || val.length==0 || val.length > 100 || val.charAt(i)==';' || val.charAt(i)=='"' || val.charAt(i)=='!' || val.charAt(i)=='$' || val.charAt(i)=='%' || val.charAt(i)=='(' || val.charAt(i)==')' || val.charAt(i)=='*' || val.charAt(i)==':' || val.charAt(i)==';' || val.charAt(i)=='<' || val.charAt(i)=='=' || val.charAt(i)=='>' || val.charAt(i)=='?' || val.charAt(i)=='@' || val.charAt(i)=='[' || val.charAt(i)==']' || val.charAt(i)=='^' || val.charAt(i)=='`' || val.charAt(i)=='|' || val.charAt(i)=='{' || val.charAt(i)=='}' || val.charAt(i)=='~' || val.charAt(i)=='#' || val[i]=="'") {
status = true;
break;
}
}
return status;
}


getFileName = (e) => {
  this.setState({filename: e.target.value});
}


handleCloseException = () => this.setState({ openexception: false });

handleCloseSuccess = () => {
this.setState({ opensuccess: false });
if(this.state.data && this.state.data.message && this.state.data.message=="success") {
this.setState({data: ''});
this.setState({base64PDF: ''});
this.setState({assignmentname: ''});
this.setState({subjectName: ''});
this.setState({filename: ''});
window.document.getElementById('inputfile').value='';
window.document.getElementById('assignmentname').value='';
}
}

handleCloseWrongAssignmentName = () => this.setState({ wrongAssignmentName: false });

handleSubjectChange = (event) => {
     this.setState({subjectName: event.target.value});
    }

handleAssignmentNameChange = (e) => {
     this.setState({assignmentName: ''});
if(this.checkSpecialChar(e.target.value)==true) {
     this.setState({wrongAssignmentName: true});
}
else {
var asnm = e.target.value.trim();
     this.setState({assignmentName: asnm});
}
}


getMonth(event) {
var evt=""+event;
var month="01";
if(evt.substring(4,7)=="Jan")
month="01";
else if(evt.substring(4,7)=="Feb")
month="02";
else if(evt.substring(4,7)=="Mar")
month="03";
else if(evt.substring(4,7)=="Apr")
month="04";
else if(evt.substring(4,7)=="May")
month="05";
else if(evt.substring(4,7)=="Jun")
month="06";
else if(evt.substring(4,7)=="Jul")
month="07";
else if(evt.substring(4,7)=="Aug")
month="08";
else if(evt.substring(4,7)=="Sep")
month="09";
else if(evt.substring(4,7)=="Oct")
month="10";
else if(evt.substring(4,7)=="Nov")
month="11";
else if(evt.substring(4,7)=="Dec")
month="12";
return month;
}

getDay(event) {
var evt=""+event;
return evt.substring(8,10);
}

getYear(event) {
var evt=""+event;
return evt.substring(11,15);
}

 getLocalStorage = () => {
  let user = getLocalData();
  //console.log(user);
   return user.data;
} 

getSubjects = () => {
  let subjects = [];
      let data = this.getLocalStorage();
      if(data && data.studentDetail && data.studentDetail.subjects && data.studentDetail.subjects.length > 0)
    subjects = data.studentDetail.subjects;
  return subjects;
}

convertToBase64() {
let self = this;
        var selectedFile = window.document.getElementById("inputfile").files;
        //Check File is not Empty
        if (selectedFile!=null) {
            // Select the very first file from list
            var fileToLoad = selectedFile[0];
            // FileReader function for read the file.
            var fileReader = new FileReader();
            var base64pdf;
            // Onload of file read the file content
            fileReader.onload = async function(fileLoadedEvent) {
                base64pdf = fileLoadedEvent.target.result;
                // Print data in console
                // console.log("rrrrrrrrrrrrrrrr", base64pdf);
var base64 = base64pdf.substring((base64pdf.indexOf("base64,")+7),base64pdf.length);
self.setState({base64PDF: base64});
// console.log("tttttttttttttttt", self.state.base64PDF);
            };
            // Convert data to base64
          fileReader.readAsDataURL(fileToLoad);
        }
else {
self.setState({wrongAssignmentName: true});
return;
}
}


submitRequest = async () => {
this.setState({connection: true});
// this.convertToBase64();
let self = this;
self.setState({wrongAssignmentName: false});
var data='';
self.setState({data: ''});
self.setState({opensuccess: false});
var wrongassignmentname = true;

        var selectedFile = window.document.getElementById("inputfile").files;
        if (selectedFile.length > 0 && self.state.subjectName.length>0 && self.state.assignmentName!='' && (self.checkSpecialChar(self.state.assignmentName)!=true)) {
            var fileToLoad = selectedFile[0];
	var fileName = fileToLoad.name;
// console.log(fileName.substring(fileName.length-4,fileName.length)==".pdf"); //  lastIndexOf(".pdf"));
if(fileName.substring(fileName.length-4,fileName.length)!=".pdf") {
self.setState({wrongAssignmentName: true});
return;
}	
            var fileReader = new FileReader();
            var base64pdf;
            fileReader.onload = async function(fileLoadedEvent) {
            base64pdf = fileLoadedEvent.target.result;
var base64 = base64pdf.substring((base64pdf.indexOf("data:application/pdf;base64,")+28));
data = base64;
// console.log("direct", data);
self.setState({base64PDF: data});
var localdata = await self.getLocalStorage();
self.setState({status: true});
let indata = {
"name": `${localdata.studentDetail.name}`,
"rollno": `${localdata.studentDetail.rollNo}`,
"course": `${localdata.studentDetail.course}`,
"year_prof_sem": `${localdata.studentDetail.semester}`,
"subject": `${self.state.subjectName}`,
"assignment_name": `${self.state.assignmentName}`,
"pdffile": `${self.state.base64PDF}`,
};
// console.log("\n\nindata",indata);
var info = await uploadAssignment(indata);
if(!info.data) {
self.setState({connection: false});
}
else {

self.setState({data: info.data});

self.setState({opensuccess: true});
}
self.setState({status: false});
};
   fileReader.readAsDataURL(fileToLoad);
}
else {
self.setState({wrongAssignmentName: true});
}

}

getDate = () => {
const d = new Date(); 
return (d.getDate()+"-"+(d.getMonth()+1)+"-"+(d.getYear()+1900));
}
render() {
return (
<div>
<br/>
<Typography style={{fontSize: "18px", textAlign: "center"}}>Upload Assignment</Typography>
<br/>
<br/>

<InputLabel> Select Subject</InputLabel><br/>
        <Select value={this.state.subjectName} onChange={e => this.handleSubjectChange(e)} style={{width: "100%"}}>
          
          {this.getSubjects().map((subject, index) => (<MenuItem key={index+1} value={subject.subjectName}>{subject.subjectName}</MenuItem>))};
          
        </Select>

<br/>

<TextField name="assignmentname" id="assignmentname" label="Assignment Name" style={{width: "100%"}} onChange={e => this.handleAssignmentNameChange(e)} />
<br/>
<br/>

<Button
  variant="outlined" 
  component="label" style={{width: "100%", backgroundColor:"#caffcc"}}
>Attach Assignment (PDF)
  <input type="file" name="inputfile"  id="inputfile" style={{ display: "none" }} accept="application/pdf" onChange={e => this.getFileName(e)} />
</Button>
<br/>
<br/>
<div>File: {this.state.filename}</div>
<br/>

<div align="center">
<Button name="upload" id="upload" variant="outlined"  disabled={this.state.status} style={{width: "100%", backgroundColor:"#ffefa4"}} onClick={this.submitRequest}>Upload</Button>
</div>
{(this.state.data.message && this.state.data.message=="success")?
(
<div>
<Dialog
        open={this.state.opensuccess}
        onClose={this.handleCloseSuccess}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Message"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Successfully Uploaded! 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseSuccess} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
</div>
):("")}

{(this.state.data.message && this.state.data.message!="success")?
(
<div>
<Dialog
        open={this.state.openexception}
        onClose={this.handleCloseException}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Message"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Something Went Wrong, Please Try Again! 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseException} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
</div>
):("")}

{(this.state.wrongAssignmentName && this.state.wrongAssignmentName==true)?
(
<div>
<Dialog
        open={this.state.wrongAssignmentName}
        onClose={this.handleCloseWrongAssignmentName}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Message"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please Check The Uploaded File, Assignment Name, Subject! 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseWrongAssignmentName} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
</div>
):("")}




 { this.state.status ? <div align="center"><CircularProgress className="loader" variant='indeterminate' color='secondary' /></div>  : "" }

 { this.state.connection==false ? <div style={{color: "red", textAlign: "center"}}><br/><br/>Please Check Internet Connection !</div>:"" }

</div>
);
}
}

