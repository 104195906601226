import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../styles/formstyles.css';
// import Select from '@material-ui/core/Select';
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
import { getLocalData } from '../adapter/UserInfo';
import { getFeeDetails } from '../adapter/infoURL';
import { getRazorPayPaymentResponse } from '../adapter/infoURL';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import inWords from './RsToWords';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
// import { Link } from 'react-router-dom';
// import PaymentIcon from '@material-ui/icons/Payment';
// import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
// import PayByChallan from './PayByChallan';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
// import ReactDOM from 'react-dom';
// import { checkStatus } from '../adapter/checkStatus';
import '../common/style.css';
import Challan from "./challan";
import './responsive.css';
 import { getCurUrl } from '../adapter/UserInfo';
import { getRazorPayCreateOrder } from '../adapter/infoURL';


export default class FeeDetails extends Component {
    getDate = () => {
      var date = new Date();
      var month = date.getMonth();
      var day = date.getDate();
      (month < 10) ? month="0"+(month+1) : month=(month+1);
      if(day < 10)  day="0"+(day);
      let dt = (date.getYear()+1900)+"-"+month+"-"+day;
      return dt;
    }
 
  getLocalStorage = () => {
  let user = getLocalData();
//  console.log(user.data);
   return user.data;
} 

  state={
    status: false,
    open: false,
    data: '',
    date: `${this.getDate()}`,
    con: true,
    buttonisClicked: false,
    paybutton: true,
}




getStudentSessionId = () => {
let data = this.getLocalStorage();
      return data.studentDetail.studentSessionId;
}

getStudentId = ()  => {
let data = this.getLocalStorage();
// console.log(data.studentDetail.studentId);
// console.log(this.getStudentSessionId());
      return data.studentDetail.studentId;
}

getSessionIdentifier = ()  => {
let data = this.getLocalStorage();
      return data.studentDetail.sessionIdentifier;
}


paybuttonActive = () => {
this.setState({paybutton: true});
}

paybuttonInactive = () => {
this.setState({paybutton: false});
}

submitRequest = async () => {
  this.indata = {
"studentId": `${this.getStudentId()}`,
"studentSessionId":`${this.getStudentSessionId()}`,
"sessionIdentifier": `${this.getSessionIdentifier()}`,
"dateTo": `${this.getDate()}`,
}
this.setState({listener: ""})
this.setState({status: true});
let info = await getFeeDetails(this.indata);
 // console.log(info);
if(info!=null) {
 this.setState({data: info.data}); 
 this.setState({con: true}); 
}
else {
this.setState({con: false});  
}
// console.log(info.data);
this.setState({status: false});
}

challan = (data) => {
  this.setState({ buttonisClicked: true, text: data });
window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
}


getpaymenturl = (data) => {
var appurl=getCurUrl();
 if(data && data.fid && data.fid!=null) 
{
 return "https://api.sgrduhs.in:8181/univmgmtsys/payfeeonline.jsp?apurl="+appurl+"&fdata="+data.fid;
//     return "http://localhost:8080/univmgmtsys/payfeeonline.jsp?apurl="+appurl+"&fdata="+data.fid;

}
else
return "https://api.sgrduhs.in:8181/univmgmtsys/payfeeonline.jsp?apurl="+appurl+"&fdata=";

}


getFeeTillDate = () => {

} 



componentDidMount() {
  this.submitRequest();
}


getFine = (data) => {
if((data && data.fine && data.fine!=null && parseFloat(data.fine) > 0) && (data && data.daysMore && data.daysMore!=null && parseInt(data.daysMore) > 0)) {
return (parseFloat(data.fine)*parseInt(data.daysMore));
}
else
return 0;
}

getPendingFee = (data) => {
if((data && data.pendingFee && data.pendingFee!=null && parseFloat(data.pendingFee) > 0) && (data && data.transactionCount && data.transactionCount!=null && parseInt(data.transactionCount) > 0))
return parseFloat(data.pendingFee);
else 
return 0; 
}


getTotalFee = (data) => {
if(this.getPendingFee(data)>0)
return parseInt(this.getPendingFee(data))+parseInt(this.getFine(data));
else 
var cb=0;
var pb=0;
var oc=0;
if(data && data.currentBalance && data.currentBalance!=null && parseFloat(data.currentBalance) > 0)
cb = data.currentBalance;

if(data && data.previousBalance && data.previousBalance!=null && (parseFloat(data.previousBalance) >=0 || parseFloat(data.previousBalance) < 0))
pb = data.previousBalance;

if(data && data.otherCharges && data.otherCharges!=null && parseFloat(data.otherCharges) >= 0)
 oc = data.otherCharges;
if((parseInt(pb) + parseInt(cb)+parseInt(this.getFine(data))+parseInt(oc))>0) {
return (parseInt(pb) + parseInt(cb)+parseInt(this.getFine(data))+parseInt(oc));

}
else
return 0; 
}

/*
async openCheckout(data) {
let self = this;
var totalFee = (parseInt(this.getTotalFee(data)));
// console.log(totalFee);
var d = new Date();

let options = {
"order_id": d.getTime(),
"key": process.env.REACT_APP_RPKEY_ID,
"amount": `${totalFee*100}`, // 100,
"amount_paid": 0,
"created_at": 0,
"amount_due": 0, 
"currency": "INR",
"entity": "order",
"status": "created",
"attempts": 0, 
"name": "SGRDUHS",
"description": "student fee",
"image": "120120.png",
"receipt": ""+d.getTime(), // fee id of student can be given here from where transaction can be identified


"handler": async function(response) {
self.setState({status:true});
let rpresp = {
"paymentId": response.razorpay_payment_id, 
"orderId": response.razorpay_order_id, 
"signature": response.razorpay_signature,
"amount": `${totalFee*100}`
};
// console.log(rpresp);
 let info = await getRazorPayPaymentResponse(rpresp);
// console.log(info.data);
self.setState({status:false});
// console.log(response);
alert("Thanks!\nYour Payment ID: "+response.razorpay_payment_id);
},

"prefill": {
"name": "ajit singh",
"email": "ajit.sgrduhs@yahoo.com",
},
"notes": {
"name": this.getLocalStorage().studentDetail.name, // "ajit singh",
"email": this.getLocalStorage().studentDetail.email,
"contact": this.getLocalStorage().studentDetail.contact,
"course": this.getLocalStorage().studentDetail.course,
"batch": this.getLocalStorage().studentDetail.batch.substring(0,4),
"studentfeeid": `${data.id}`, // "11"
},
"theme": {

"color": "#f37254",
}

};

// console.log(options);

this.setState({paybutton: false});
let order = await getRazorPayCreateOrder(options);
// console.log("\n\n\norder data: ",order.data,"\n\n\n");
if(order && order.data && order.data.amount > 0) 
// console.log(order.data.amount);
options.amount=order.data.amount;
options.amount_paid = order.data.amount_paid;
options.amount_due=order.data.amount_due;
options.receipt=order.data.receipt;
options.order_id=order.data.order_id;
options.entity=order.data.entity;
options.status=order.data.status;
options.attempts=order.data.attempts;
// console.log("RP Data: "+order.data.order_id);

// console.log("rp data: ",options);

 let rzp = new window.Razorpay(options); 
 let rzpd = rzp.open();
// console.log(rzpd);
}
*/


render() {
let url=this.getpaymenturl();

// const { classes, theme } = this.props;
return(
<div align="center" id="container" className="container" style={{height: '100%'}}>

<div ref="point" className="maintitle">
<h3>Fee Detail</h3>
{this.state.buttonisClicked && <div style={{width: "90%", height: "400mm", overflow: "auto" }}><Challan  style={{left: "-50mm", top: "-5mm", width: "100%", overFlow: "auto"}}  data={this.state.text} /></div>}
</div>
<div>
 {(this.state.con!==true) ? (<Typography align="center" style={{color: '#ff0000'}}>Internet Or Data Not Available</Typography>) : ""}
  </div>
<div align="left">
{((this.state) && (this.state.data) && (this.state.data.length > 0) && (this.state.data[0].bankId)) ? 

(<List className="list">
{this.state.data.map((text, index) => (
<ListItem key={index+1} alignItems="flex-start" className="listitem" style={{borderRadius: '10px', border: '1px solid #aaa', boxShadow: '5px 5px 5px #aaa', marginBottom: '20px'}}>
        <ListItemText
          primary={""} 

          secondary={
            <React.Fragment>

<Typography
                component="span"
                variant="body2"
                className="inline"
                color="textPrimary"
                style={{fontWeight: 'bold', marginTop: '10px'}}
              >{text.description.toUpperCase()}
</Typography>


<Typography
                component="span"
                variant="body2"
                className="inline"
                color="textPrimary"
              >
                Previous Balance Rs.: {text.previousBalance}
              </Typography>

<Typography
                component="span"
                variant="body2"
                className="inline"
                color="textPrimary"
              >
                Fee Rs.: {text.currentBalance}
              </Typography>


 

              <Typography
                component="span"
                variant="body2"
                className="inline"
                color="textPrimary"
              >
                Other Charges Rs.: {text.otherCharges}
              </Typography>





               <Typography
                component="span"
                variant="body2"
                className="inline"
                color="textPrimary"
              >
               Fine Rs.: {this.getFine(text)}
              </Typography>
<Typography
                component="span"
                variant="body2"
                className="inline"
                color="textPrimary"
              >
                Fine Criteria: {text.fineCriteria}
              </Typography>

               <Typography
                component="span"
                variant="body2"
                className="inline"
                color="textPrimary"
              >
                {( this.getPendingFee(text) > 0)? ("Pending Rs. " + this.getPendingFee(text) + ""):""}            </Typography>




{/*

           <Typography
                component="span"
                variant="body2"
                className="inline"
                color="textPrimary"
              >
                Fee Rs.: {parseFloat(text.currentBalance) + parseFloat(text.previousBalance) + parseFloat(text.otherCharges)}
              </Typography>
            <Typography
                component="span"
                variant="body2"
                className="inline"
                color="textPrimary"
              >

                Description: {text.description}

              </Typography>

*/}









<Typography
                component="span"
                variant="body2"
                className="inline"
                color="textPrimary"
              >
                Last Date: {text.lastDate}
              </Typography>


<Typography
                component="span"
                variant="body2"
                className="inline"
                color="textPrimary"
                style={{fontWeight: 'bold', marginTop: '10px'}}
              >
                Total Amount Rs.: {( +text.pendingFee > 0 && +text.transactionCount > 0)? (parseInt(text.pendingFee)+(parseInt(text.fine)*parseInt(text.daysMore))): (parseInt( parseInt(text.currentBalance)+(parseInt(text.fine)*parseInt(text.daysMore))+parseInt(text.previousBalance)+parseInt(text.otherCharges)))}
              </Typography>

<Typography
                component="span"
                variant="body2"
                className="inline"
                color="textPrimary"
                style={{fontWeight: 'bold', marginTop: '10px'}}
              >
                Total Amount Rs. (In Words): {( +text.pendingFee > 0 && +text.transactionCount > 0)? inWords(parseInt(text.pendingFee)+(parseInt(text.fine)*parseInt(text.daysMore))).toUpperCase(): inWords(parseInt( parseInt(text.currentBalance)+(parseInt(text.fine)*parseInt(text.daysMore))+parseInt(text.previousBalance)+parseInt(text.otherCharges))).toUpperCase()}
              </Typography>

<br/>
<br/>

{/* For IOS App*/}

{(this.state.paybutton && this.state.paybutton===true && (+text.transactionCount == 0)) ? (<Button key="b2" style={{flex: '0 0 100%', border: '1px solid #aaa', backgroundColor:"#caffcc"}} onClick={() => { window.open(this.getpaymenturl(text), '_self', 'clearsessioncache=yes,clearcache=yes'); this.paybuttonInactive(); }}>&nbsp;&nbsp;&nbsp;&nbsp;Pay Online&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>): "" }


{/* for Android App */}

{/*
{(this.state.paybutton && this.state.paybutton===true && (+text.transactionCount == 0)) ? (<Button key="b2" style={{flex: '0 0 100%', border: '1px solid #aaa'}} onClick={() => { window.open(this.getpaymenturl(text), '_self', 'clearsessioncache=yes,clearcache=yes,hidenavigationbuttons=yes,hideurlbar=yes'); this.paybuttonInactive(); }}>&nbsp;&nbsp;&nbsp;&nbsp;Pay Online&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>): "" }
*/}

<br/>
<br/>           

{(this.state.paybutton && this.state.paybutton===true) ?
(<Button key="b1" style={{ display: 'block', flex: '0 0 100%', border: '1px solid #aaa', backgroundColor:"#ffefa4"}} onClick={() => this.challan(text)}>Pay By Challan</Button>):""}
<br/>

<br/>

{/*
{(this.state.paybutton && this.state.paybutton===true && (+text.transactionCount == 0)) ? (<Link to={'/feepayment'} style={{display: 'none', textDecoration: 'none'}} >
<Button key="b2" style={{flex: '0 0 100%', border: '1px solid #aaa'}} onClick={() => this.paybuttonInactive()}>&nbsp;&nbsp;&nbsp;&nbsp;Pay Online&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>
</Link>): "" }



{(this.state.paybutton && this.state.paybutton===true && (+text.transactionCount == 0)) ? (<Button key="b3" style={{display: 'none', flex: '0 0 100%', border: '1px solid #aaa'}} onClick={() => this.openCheckout(text)}>&nbsp;&nbsp;&nbsp;&nbsp;Pay Fee  Online&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>): ""}

*/}


            </React.Fragment>
          }
        />


        <Divider />
      </ListItem>
  
          ))}
</List>) : "" }

{ ((this.state.data) && (this.state.data.message) && (this.state.data.message ==="null")) ? <Typography align="center" className="typography textstyle">No Fee Detail Available</Typography>:""}
{ ((this.state.data) && (this.state.data.message) && (this.state.data.message ==="error")) ? <Typography align="center" className="typography textstyle">Problem with Input</Typography>:""}
{ ((this.state.data) && (this.state.data.message) && (this.state.data.message ==="unauthorized")) ? <Typography align="center" className="typography textstyle">Unauthorized Access</Typography>:""}

 <br/>
 </div>
 
 { this.state.status ? <div style={{width: '99%'}}><br/><br/><CircularProgress className="loader" align="center" variant='indeterminate' color='secondary' /></div> : "" }

<div className="mode">{this.state.listener}</div>
</div>

  );
}
} 

