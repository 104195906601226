import React, { Component } from 'react';
import MaterialUIForm from 'material-ui-form';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import '../styles/formstyles.css';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import { getAuth } from './LoginActions';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Main from '../main/Main';
import ReactDOM from 'react-dom';
// import { BrowserRouter as Router, Route, } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import { getLocalData } from '../adapter/UserInfo';
import { addLocalData } from '../adapter/UserInfo';
// import { getToken } from '../adapter/Source';
import { getAuthentication } from '../adapter/UserInfo';
import { messaging } from "../init-fcm";
import  VisibilityOff from '@material-ui/icons/VisibilityOff';
import  Visibility from '@material-ui/icons/Visibility';

// import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';
 import  MainVisitor from '../mainvisitor/MainVisitor';
import logo from '../main/utils/7676.png';

// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
// import ListItemText from '@material-ui/core/ListItemText';

// import AddToHomescreen from 'react-add-to-homescreen';


// import { FcmReceiver } from '../receiver/FcmReceiver';

// import { checkStatus } from '../status/Status';
// import { checkStatus } from '../adapter/checkStatus';


const styles = theme => ({
  root: {
    display: 'flex',
    position: 'relative',
    margin: '0 auto',

},

  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100%)`,
alignItems: 'center'
    },
  },

  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: '100%',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 1,
  },

  link: {
    textDecoration: 'none',
  },

pwdviewposition: {
position: 'relative', left: '1%', float:'right', color: '#999', marginTop: '10%',
}

});





// Detects if device is on iOS 
const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test( userAgent );
}
// Detects if device is in standalone mode
const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);





export class Login extends Component {

  state = { 
    registrationNo: "", 
    pwd: "",
    status: false,
    open: false,
    connected: true,
    fcmtoken: "",
viewpwd: true,
showInstallMessage: false
    };

/*
handleAddToHomescreenClick = () => {
  alert(`
    1. Open Share menu
    2. Tap on "Add to Home Screen" button`);
};

<AddToHomescreen onAddToHomescreenClick={this.handleAddToHomescreenClick} title="Download App" icon="7676.png" />

*/



checkIOS = () => {
// Checks if should display install popup notification:
if (isIos() && !isInStandaloneMode()) {
  this.setState({ showInstallMessage: true });
}
// console.log("\n\n\nios checked\n\n\n");
}




togglepwdview = () => this.setState({viewpwd: !this.state.viewpwd}); 

handleCloseConnection = () => this.setState({connected: false});
handleOpenConnection = () => this.setState({connected: true});

handleClickOpen = () => this.setState({ open: true });
handleClose = () => this.setState({ open: false });
  
handleUserChange = event => this.setState({ registrationNo: event.target.value })
handlePassChange = event => this.setState({ pwd: event.target.value })

async componentDidMount() {
try {
messaging.requestPermission().then(async () => {
        const fcmtoken = await messaging.getToken();
        // console.log("Token: ", fcmtoken);
        this.setState({ fcmtoken: `${fcmtoken}`});
      })
      .catch(error => console.log("error: ", error));
}
catch(e) {
console.log("exception: ",e);
}
this.checkIOS();
      
}


submitData = async () => {
// console.log(this.state);
this.setState({status: true});
this.data = {
  "registrationNo": `${this.state.registrationNo}`,
  "pwd": `${this.state.pwd}`,
  "fcmtoken": `${this.state.fcmtoken}`
}
let info = await getAuth(this.data);
// console.log(info);
//if(info && (info !== null || info !== "null") && info.data && (info.data!==null || info.data!=="null") && info.data.token && (info.data.token!==null || info.data.token!=="null")) {
if(info !== null && info.data && info.data!==null && (info.data.token) && (info.data.token!=="null" ) && (info.data.token!==null)) {
addLocalData(info); 
var token = getAuthentication();
console.log("loggedin");
if(token!==false) {
  // console.log(getLocalData());
   const root = document.getElementById('root');
   ReactDOM.render(<Main />, root);
 }
}
else if (info===null){
this.handleCloseConnection();

}
else {
  this.handleClickOpen();
}
this.setState({status: false});
}



visitor() {
   const root = document.getElementById('root');
   ReactDOM.render(<MainVisitor />, root);
}


render() {
const { classes } = this.props;

return (

<div className={classes.root} style={{width: "100%", backgroundImage: "linear-gradient(to bottom right, #fff, #fff)"}}>

<Grid  container
  spacing={0}
  direction="column"
  alignItems="center"
  justify="center"
  style={{ minHeight: '100vh' }}>

  <Grid item xs={8}>
  
  <CssBaseline />

{/*
        <AppBar position="fixed" alignItems="center" style={{backgroundImage: "linear-gradient(to bottom right, red, yellow)"}}>
          <Toolbar><img src={logo} alt={"logo"} style={{height: "50px"}}/>
     <Typography variant="h6" color="inherit" noWrap >
                <div style={{width: "100%", textAlign: "center"}}>SGRD University</div>
            </Typography>
          </Toolbar>
        </AppBar>
*/}

<div id="formdiv">
<MaterialUIForm>

<Typography className="formtitle" color="primary" variant="h5" align="center">Login</Typography>
  <div>      <TextField
          label="ID"
          type="text"
          name="userid"
          defaultValue={this.state.registrationNo}
          data-validators="isRequired,isAlpha"
          onChange={e => this.handleUserChange(e)}
	style={{width: "100%"}}
        /> </div>
 <br/>
<div>
 <TextField
          label="Password"
          type={this.state.viewpwd ? "password": "text"}
          name="password"
          defaultValue={this.state.pwd}
	data-validators="isRequired"
          onChange={e => this.handlePassChange(e)}
		style={{width: "85%"}}
        />
{(this.state.viewpwd) ? (<VisibilityOff onClick= {() => this.togglepwdview()} className={classes.pwdviewposition} /> 
) : (<Visibility onClick= {() => this.togglepwdview()} className={classes.pwdviewposition} />) }
</div>
  <br/>
      <br/>
       <div align="center"><Button variant="outlined" disabled = { this.state.status } onClick= {() => this.submitData()} style={{width: "100%", backgroundColor:"#cadffc"}}>Login</Button>
</div>
   <br/>
  
{/*
<div align="center"><Button variant="outlined" disabled = { this.state.status } onClick= {() => this.visitor()}  style={{width: "100%", backgroundColor:"#ffbb44"}}>Visitor</Button></div>
*/}


      { this.state.status ? <div align="center"><CircularProgress variant='indeterminate' color='secondary' /></div>  : <div></div> }
             
      </MaterialUIForm>
</div>
  </Grid>   

</Grid> 

  <div>
      <Dialog
        open={this.state.open}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Message"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Incorrect User ID or Password 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>

    <div>
      <Dialog
        open={!this.state.connected}
        onClose={this.handleOpenConnection}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Message"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            No Access To Source
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleOpenConnection} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>

</div>
);
}
}


export default withStyles(styles, { withTheme: true })(Login);
