import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from '@material-ui/icons/Mail';
// import { getLocalData } from '../adapter/UserInfo';
import { getTestimonial }from '../adapter/infoURL';
import { addTML } from '../adapter/UserInfo';
import { getTML } from '../adapter/UserInfo';
// import { checkStatus } from '../status/Status';
// import { checkStatus } from '../adapter/checkStatus';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../common/style.css';
 
const styles = theme => ({
  bigAvatar: {
    width: 250,
    height: 250,
    margin: '0 auto',
    marginTop: 5,
  },

  smallAvatar: {
    width: 50,
    height: 50,
    margin: '0 auto',
    marginTop: 5,
  }
});

export class Testimonial extends Component {
constructor(props) {
    super(props);
this.state = {
    data: [],
    status: false,
    showData: false,
    listener: "OFFLINE"
  };
}


submitRequest = async () => {
  this.datas = {
 }
this.setState({status: true});
this.setState({listener: ""});
let info = await getTestimonial(this.datas);
if(info!=null) {
this.setState({data: info.data});  
}
// console.log(info);

this.setState({status: false});
if((this.state) && (this.state.data) &&  (this.state.data.length > 0)){
addTML(this.state.data);
}
}

webListener() {
this.submitRequest();  
}

localListener() {
this.setState({status: true});
// console.log(getSNF());
this.setState({data: getTML()});
this.setState({status: false});
this.setState({listener: "OFFLINE"});
}


  componentDidMount() {
    /*
  if(checkStatus()) {
    this.webListener();
  }
  else {
    this.localListener();
  }
  */
  this.localListener();
  this.webListener();
}


handleShowChange(e) {
 const { data } = this.state;
 if(e.target.id==="" || e.target.id===null || e.target.id===undefined){}
 else {
 const { id } = e.target;
 data[id].show = !this.state.data[id].show
 this.setState({ data });
}
}

render() {
   const { classes } = this.props;
 
return (
<div className="container">

{ this.state.status ? <div><CircularProgress className="loader" align="center" variant='indeterminate' color='secondary' /></div>  : "" }
{((this.state) && (this.state.data) && (this.state.data.length > 0)) ? 
(<List className="list">
{this.state.data.map((text, index) => (
<ListItem  button  id={index} key={index} className="listitem" style={{borderRadius: '10px', border: '1px solid #aaa', boxShadow: '5px 5px 5px #aaa', marginBottom: '20px'}} onClick={(e) => this.handleShowChange(e)} alignItems="flex-start">
        <ListItemAvatar id={index}>
         <Avatar id={index} className={classes.smallAvatar} src={text.pic}></Avatar>
        </ListItemAvatar>
        <ListItemText id={index}
          primary={<div id={index}>{text.name.toUpperCase()}</div>}
          secondary={
            <React.Fragment id={index}>
              {(text.show) ? (<div><Typography id={index}
                component="span"
                variant="body2"
                className="inline"
                color="textPrimary"
              >
                {text.course}
              </Typography>
              <Typography  id={index}
                component="span"
                variant="body2"
                className="inline"
                color="textPrimary"
              >
                {text.achievements}
              </Typography>
               
         <Typography id={index}
                component="span"
                variant="body2"
                className="inline"
                color="textPrimary"
              >
                {text.message}
              </Typography></div>) : "" }
               
              <i id={index} className="right">Session: {text.session}</i>

            </React.Fragment>
          }
        />
        <Divider />
      </ListItem>
  
          ))}
</List>) : "" }

{ ((this.state) && (this.state.data) && (this.state.data.message) && (this.state.data.message ==="null")) ? <Typography align="center" className="typography">No Information Available</Typography>:""}
{ ((this.state) && (this.state.data) && (this.state.data.message) && (this.state.data.message ==="error")) ? <Typography align="center" className="typography">Problem with Input</Typography>:""}
{ ((this.state) && (this.state.data) && (this.state.data.message) && (this.state.data.message ==="unauthorized")) ? <Typography align="center" className="typography">Unauthorized Access</Typography>:""}
<div className="mode">{this.state.listener}</div>    
</div>
);
}

}
export default withStyles(styles, { withTheme: true })(Testimonial);
