import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../styles/formstyles.css';
// import Select from '@material-ui/core/Select';
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
import { getLocalData } from '../adapter/UserInfo';
import { getFeePaidDetails } from '../adapter/infoURL';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import inWords from './RsToWords';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import Divider from '@material-ui/core/Divider';
import PrintReceipt from './PrintReceipt';
// import { Link } from 'react-router-dom';
// import PaymentIcon from '@material-ui/icons/Payment';
// import PropTypes from 'prop-types';
// import Button from '@material-ui/core/Button';
// import PayByChallan from './PayByChallan';
import Typography from '@material-ui/core/Typography';
// import { Link } from 'react-router-dom';
// import ReactDOM from 'react-dom';
// import { checkStatus } from '../adapter/checkStatus';
import '../common/style.css';
import './responsive.css';

export default class PaidFee extends Component {


    getDate = () => {
      var date = new Date();
      var month = date.getMonth();
      var day = date.getDate();
      (month < 10) ? month="0"+(month+1) : month=(month+1);
      if(day < 10)  day="0"+(day);
      let dt = (date.getYear()+1900)+"-"+month+"-"+day;
      return dt;
    }
 
  getLocalStorage = () => {
  let user = getLocalData();
   return user.data;
} 

  state={
    status: false,
    open: false,
    data: '',
    date: `${this.getDate()}`,
    con: true,
    listener: '',
    buttonisClicked: false,
text: []
    }

getStudentId = ()  => {
let data = this.getLocalStorage();
// console.log(data.studentDetail.studentId);
// console.log(this.getStudentSessionId());
      return data.studentDetail.studentId;
}


getCourseId = () => {
  let data = this.getLocalStorage();
  return data.studentDetail.courseId;
}


submitRequest = async () => {
  this.indata = {
  "studentId": `${this.getStudentId()}`,
  "courseId": `${this.getCourseId()}`,
}
this.setState({listener: ""})
this.setState({status: true});
let info = await getFeePaidDetails(this.indata);
 console.log(info);
if(info!=null) {
 this.setState({data: info.data}); 
 this.setState({con: true}); 
}
else {
this.setState({con: false});  
}
// console.log(info.data);
this.setState({status: false});
}



receipt = (data) => {
  this.setState({ buttonisClicked: true, text: data });
window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
}


componentDidMount() {
  this.submitRequest();
}

render() {

// const { classes, theme } = this.props;
return(
<div align="center" id="container" className="container" style={{height: '100%'}}>

<div className="maintitle">
<h3>Paid Fee Detail</h3>
{this.state.buttonisClicked && <PrintReceipt data={this.state.text} />}
</div>
<div>
 {(this.state.con!==true) ? (<Typography align="center" style={{color: '#ff0000'}}>Internet Or Data Not Available</Typography>) : ""}
  </div>
<div align="left">
{((this.state) && (this.state.data) && (this.state.data.length > 0) && (this.state.data[0].paymentId)) ? 

(<List className="list">
{this.state.data.map((text, index) => (
<ListItem key={index+1} alignItems="flex-start" className="listitem" style={{ borderRadius: '10px', border: '1px solid #aaa', boxShadow: '5px 5px 5px #aaa', marginBottom: '20px'}}>
        <ListItemText
          primary={""}
          secondary={
            <React.Fragment>
<Typography
                component="span"
                variant="body2"
                className="inline"
                color="textPrimary"
		style={{fontWeight: "bold"}}
              >
                Paid On: {text.transactionDate}
              </Typography>
<Typography
                component="span"
                variant="body2"
                className="inline"
                color="textPrimary"
              >
                Description: {text.description.toUpperCase()}
              </Typography>            

<Typography
                component="span"
                variant="body2"
                className="inline"
                color="textPrimary"
              >
                Payment ID: {text.paymentId}
              </Typography>
<Typography
                component="span"
                variant="body2"
                className="inline"
                color="textPrimary"
              >
                Transaction ID: {text.transactionId}
              </Typography>

<Typography
                component="span"
                variant="body2"
                className="inline"
                color="textPrimary"
              >
                Payment Mode: {text.paymentMode.toUpperCase()}
              </Typography>

              
<Typography
                component="span"
                variant="body2"
                className="inline"
                color="textPrimary"
              >
                Fee Paid Rs.: {text.transactionAmount}
              </Typography>

<Typography
                component="span"
                variant="body2"
                className="inline"
                color="textPrimary"
                
              >
                Fee Paid Rs. (In Words): {inWords(parseInt(text.transactionAmount)).toUpperCase()}
              </Typography>

            
            </React.Fragment>
          }
        />
<br/>
<br/>

<button key="b1" style={{display: "block", fontSize: "20px", color: "blue", border: "none", padding: "0px", backgroundColor: "white"}} onClick={() => this.receipt(text)}><u>&darr;</u></button>

<br/>

      </ListItem>
  
          ))}
</List>) : "" }

{ ((this.state.data) && (this.state.data.message) && (this.state.data.message ==="null")) ? <Typography align="center" className="typography textstyle">No Transaction Detail Available</Typography>:""}
{ ((this.state.data) && (this.state.data.message) && (this.state.data.message ==="error")) ? <Typography align="center" className="typography textstyle">Problem with Input</Typography>:""}
{ ((this.state.data) && (this.state.data.message) && (this.state.data.message ==="unauthorized")) ? <Typography align="center" className="typography textstyle">Unauthorized Access</Typography>:""}

 <br/>
 </div>
 
 { this.state.status ? <div style={{width: '99%'}}><br/><br/><CircularProgress  align="center" className="loader" variant='indeterminate' color='secondary' /></div>  : "" }

<div className="mode">{this.state.listener}</div>
</div>

  );
}
} 

