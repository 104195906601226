const Adapter = {
  getBaseURL()  {
//   return 'http://192.168.122.1:8080/univmgmtsys';
//     return 'http://localhost:8080/univmgmtsys';
// return 'http://192.168.5.23:8080/univmgmtsys';
// return 'http://api.sgrduhs.in/univmgmtsys';
//    return 'https://psep.in/univmgmtsys';
 return "https://api.sgrduhs.in:8181/univmgmtsys";
 
  }
}

export default Adapter;

