import React from 'react'
import './MessageWindow.css'



function createMarkup(text) {
  return {__html: text};
}



const Message = ({ text, username, self }) => (
  <div className={'message' + (self ? ' message-self' : '')}>
    <div className='message-username'>{username}</div>
    <div className='message-text' dangerouslySetInnerHTML={createMarkup(text)}></div>


  </div>
)

export default class MessageWindow extends React.Component {
  constructor (props) {
    super(props)
    this.messageWindow = React.createRef()
  }
  componentDidUpdate () {
    const messageWindow = this.messageWindow.current
    messageWindow.scrollTop = messageWindow.scrollHeight - messageWindow.clientHeight
  }



isValidWebUrl = (url) => {
 
let regEx = new RegExp('^((https?:)?\\/\\/)?'+ // protocol
        '(?:\\S+(?::\\S*)?@)?' + // authentication
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locater

  return regEx.test(url);
}



  render () {
    const { messages = [], username } = this.props
    return (
      <div className='message-window' ref={this.messageWindow}>
        {messages.map((msg, i) => {
	var arr = msg.text.split(" ");
var data="";
	for (var i=0; i<arr.length; i++) {


if(this.isValidWebUrl(arr[i])) {

data += "<a href='//"+arr[i]+"' target='_blank'>"+arr[i]+"</a>";
data += " ";
}
else {
data +=  (arr[i]+" ");
}
}


	
         return <Message key={i} text={data} username={msg.username} self={username === msg.username} />
        })}
        <div>&nbsp;</div>
      </div>
    )
  }
}


