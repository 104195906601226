import React from 'react';
//import SinglePage from "./components/SinglePage";

// import MultiPage from "./components/MultiPage";
import PrintButton from "../components/PrintButton";
import FeeReceipt from "./FeeReceipt";
import './responsive.css';

function PrintReceipt({data}) {

//	console.log(data);
return (
<div>
  <FeeReceipt id={"feereceipt"} data={data} />
</div>
);
}

export default PrintReceipt;
