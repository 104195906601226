import React, { Component } from 'react';

import { withRouter } from "react-router-dom";

// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";

import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { TextField } from '@material-ui/core';
// import Avatar from '@material-ui/core/Avatar';
// import Typography from '@material-ui/core/Typography';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

/*
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
*/


import { fillReappearExamFormResponse } from '../adapter/infoURL';

class RegularExaminationForm extends Component {
// export default function RegularExaminationForm(props) {
state = {
opensSuccessDialog: false,
open: false,
connected: true,
errorMessage: "",
exception: false,
status: false,

examProfYearSemMessage: "",
examSessionMessage:"",
examYearMessage:"",
domicileMessage:"",
passedExamMessage:"",
appearingInMessage:"",
previousClassDurationMonthsMessage:"",
examinationCentreMessage:"",
disqualifiedExaminationMessage:"",
previousExamPassedMessage:"",
previousExamPassedBoardUniversityMessage:"",
// previousExamPassedBoardUniversityMessage:"",
previousExamPassedRollnoMessage:"",
previousExamPassedSessionMessage:"",
previousExamPassedYearMessage:"",
previousExamPassedResultMessage:"",
previousExamPassedMarksObtainedMessage:"",
previousExamPassedMaxMarksMessage:"",



PeriodFromDisqualification: '', 
PeriodToDisqualification: '',

presentAddress: ''
}

checkSpecialChar(valu) {
var val=valu;
var status = false;
for(var i=0;i<val.length;i++) {
if(val.charAt(i)==';' || val.charAt(i)=='"' || val.charAt(i)=='!' || val.charAt(i)=='$' || val.charAt(i)=='%' || val.charAt(i)=='(' || val.charAt(i)==')' || val.charAt(i)=='*' || val.charAt(i)==':' || val.charAt(i)==';' || val.charAt(i)=='<' || val.charAt(i)=='=' || val.charAt(i)=='>' || val.charAt(i)=='?' || val.charAt(i)=='@' || val.charAt(i)=='[' || val.charAt(i)==']' || val.charAt(i)=='^' || val.charAt(i)=='`' || val.charAt(i)=='|' || val.charAt(i)=='{' || val.charAt(i)=='}' || val.charAt(i)=='~' || val[i]=="'") {
status = true;
break;

}
}
return status;
}














getMonth(event) {
var evt=""+event;
var month="01";
if(evt.substring(4,7)=="Jan")
month="01";
else if(evt.substring(4,7)=="Feb")
month="02";
else if(evt.substring(4,7)=="Mar")
month="03";
else if(evt.substring(4,7)=="Apr")
month="04";
else if(evt.substring(4,7)=="May")
month="05";
else if(evt.substring(4,7)=="Jun")
month="06";
else if(evt.substring(4,7)=="Jul")
month="07";
else if(evt.substring(4,7)=="Aug")
month="08";
else if(evt.substring(4,7)=="Sep")
month="09";
else if(evt.substring(4,7)=="Oct")
month="10";
else if(evt.substring(4,7)=="Nov")
month="11";
else if(evt.substring(4,7)=="Dec")
month="12";
return month;
}

getDay(event) {
var evt=""+event;
return evt.substring(8,10);
}

getYear(event) {
var evt=""+event;
return evt.substring(11,15);
}

handlePeriodFromDisqualificationChange = event =>this.setState({ PeriodFromDisqualification: this.getYear(event)+"-"+this.getMonth(event)+"-"+this.getDay(event) })

handlePeriodToDisqualificationChange = event => this.setState({ PeriodToDisqualification: this.getYear(event)+"-"+this.getMonth(event)+"-"+this.getDay(event) })















printpage = () => {
var prtContent = window.document.getElementById("printpage");
var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
WinPrint.document.write(prtContent.innerHTML);
WinPrint.document.close();
WinPrint.focus();
WinPrint.print();
WinPrint.close();
}


handleCloseConnection = () => { this.setState({connected: false}) };
handleOpenConnection = () => this.setState({connected: true});

handleClickOpen = () => this.setState({ open: true });
handleClose = () => this.setState({ open: false });


handleOpenSuccessDialog = () => this.setState({opensSuccessDialog: true});
handleCloseSuccessDialog = () => this.setState({opensSuccessDialog: false});


submitRequest = async () => {
await this.setState({exception: false});
var subarray=[];
for( let sub=0; sub < this.props.sinfo.subjects.length;sub++)
{
if(window.document.getElementById("subject"+sub).checked) {
var subid=window.document.getElementById("subject"+sub).value.toString();
// var data={"\"subjectId\"": "\""+subid+"\""};
var data={"subjectId": ""+subid+""};
subarray.push(data);
}
}




var reappearedStudentSubjectData=[];
var reappearmessage="";

for(var reappear = 1; reappear <= 6; reappear++) { 
var reapperedFailedSession=window.document.getElementById("reapperedFailedSession"+reappear).value.trim();
var reapperedFailedYear=window.document.getElementById("reapperedFailedYear"+reappear).value.trim();
var reapperedFailedRollno=window.document.getElementById("reapperedFailedRollno"+reappear).value.trim();
var reapperedFailedResult=window.document.getElementById("reapperedFailedResult"+reappear).value.trim();


if(reapperedFailedSession.length > 0 && reapperedFailedYear.length > 0 && reapperedFailedRollno.length > 0 && reapperedFailedResult.length > 0){
var data={"reapperedFailedSession":reapperedFailedSession, "reapperedFailedYear":reapperedFailedYear, "reapperedFailedRollno": reapperedFailedRollno, "reapperedFailedResult": reapperedFailedResult};
reappearedStudentSubjectData.push(data);
}
else if(reapperedFailedSession.length == 0 && reapperedFailedYear.length == 0 && reapperedFailedRollno.length == 0 &&  reapperedFailedResult == 0 ) {
}
else {
reappearmessage +="\nPlease Check The entries of Examination Appeared in ["+reappear+"] Row";
await this.setState({exception: true});
}
}

var message="";
var examProfYearSem = window.document.getElementById("examProfYearSem").value.trim();
var examProfYearSemMessage="";
if((examProfYearSem.length > 2 || examProfYearSem.length < 1) || this.checkSpecialChar(examProfYearSem)==true)
{ 
examProfYearSemMessage="Please Enter Correct Examination Prof/Year/Sem";
await this.setState({"examProfYearSemMessage": examProfYearSemMessage});
await this.setState({exception: true});
message += examProfYearSemMessage+"\n";
window.document.getElementById("examProfYearSem").focus();
}


if(Object.keys(subarray).length<=0)
{
//window.alert("Please Select The Subject(s) of Examination");
message += "\nPlease Select The Subject(s) of Examination\n";
await this.setState({exception: true});
}


var examSession = window.document.getElementById("examSession").value.trim();
var examSessionMessage="";
if((examSession.length > 10 || examSession.length < 4) || this.checkSpecialChar(examSession)==true)
{
examSessionMessage="Please Enter Correct Examination Session";
await this.setState({"examSessionMessage": examSessionMessage});
await this.setState({exception: true});
message+= examSessionMessage+"\n";
window.document.getElementById("examSession").focus();
}

var examYear = window.document.getElementById("examYear").value.trim();
var examYearMessage="";
if((examYear.length > 4 || examYear.length < 4) || this.checkSpecialChar(examYear)==true)
{
examYearMessage="Please Enter Correct Examination Year";
await this.setState({"examYearMessage": examYearMessage});
await this.setState({exception: true});
message+= examYearMessage+"\n";
window.document.getElementById("examYear").focus();
}

var domicile = window.document.getElementById("domicile").value.trim();
var domicileMessage="";
if(domicile.length <= 0 || this.checkSpecialChar(domicile)==true)
{
domicileMessage="Please Enter Domicile";
await this.setState({"domicileMessage": domicileMessage});
await this.setState({exception: true});
message+= domicileMessage+"\n";
window.document.getElementById("domicile").focus();
}




var presentAddress = "NA"; //window.document.getElementById("presentAddress").value.trim();
var presentAddressMessage="";
if(presentAddress.length <= 1 || this.checkSpecialChar(presentAddress))
{
presentAddressMessage="Please Enter Present Address Correctly";
await this.setState({"presentAddressMessage": presentAddressMessage});
await this.setState({exception: true});
message+= presentAddressMessage+"\n";
// window.document.getElementById("presentAddress").focus();
}







var passedExam = ""; // window.document.getElementById("qualifyingExam").value.trim();
var passedExamMessage="";
if(this.checkSpecialChar(passedExam)==true)
{
passedExamMessage="Please Correct Enter Passed Examination";
// await this.setState({"passedExamMessage": passedExamMessage});
// await this.setState({exception: true});
// message+= passedExamMessage+"\n";
// window.document.getElementById("qualifyingExam").focus();
}


var appearingIn = ""; // window.document.getElementById("appearingIn").value.trim();
var appearingInMessage="";
if(this.checkSpecialChar(appearingIn)==true)
{
appearingInMessage="Please Correct Enter Appearing In";
// await this.setState({"appearingInMessage": appearingInMessage});
// await this.setState({exception: true});
// message+= appearingInMessage+"\n";
// window.document.getElementById("appearingIn").focus();
}

var previousClassDurationMonths = ""; // window.document.getElementById("previousClassDurationMonths").value.trim();
var previousClassDurationMonthsMessage="";
if((previousClassDurationMonths.length > 2) ||  this.checkSpecialChar(previousClassDurationMonths)==true)
{
previousClassDurationMonthsMessage="Please Enter Correct Previous Class Duration";
// await this.setState({"previousClassDurationMonthsMessage": previousClassDurationMonthsMessage});
// await this.setState({exception: true});
// message+= previousClassDurationMonthsMessage+"\n";
// window.document.getElementById("previousClassDurationMonths").focus();
}

var examinationCentre = window.document.getElementById("examinationCentre").value.trim();
var examinationCentreMessage="";
if(examinationCentre.length <= 0 || this.checkSpecialChar(examinationCentre)==true)
{
examinationCentreMessage="Please Enter Examination Centre";
await this.setState({"examinationCentreMessage": examinationCentreMessage});
await this.setState({exception: true});
message+= examinationCentreMessage+"\n";
window.document.getElementById("examinationCentre").focus();
}

var disqualifiedExamination = window.document.getElementById("disqualifiedExamination").value.trim();
var disqualifiedExaminationMessage="";

var disqualifiedExaminationRollno="";
var disqualifiedExaminationMonth="";
var disqualifiedExaminationYear="";
var periodFromDisqualification="";
var periodToDisqualification="";
var disqualificationUniversityBoard="";


if(disqualifiedExamination.length >= 1 && !this.checkSpecialChar(disqualifiedExamination) )
{
disqualifiedExaminationRollno = window.document.getElementById("disqualifiedExaminationRollno").value.trim();
disqualifiedExaminationMonth = window.document.getElementById("disqualifiedExaminationMonth").value.trim();
disqualifiedExaminationYear = window.document.getElementById("disqualifiedExaminationYear").value.trim();
periodFromDisqualification = window.document.getElementById("periodFromDisqualification").value.trim();
periodToDisqualification = window.document.getElementById("periodToDisqualification").value.trim();
disqualificationUniversityBoard = window.document.getElementById("disqualificationUniversityBoard").value.trim();


if(disqualifiedExaminationRollno.length == 0 && disqualifiedExaminationMonth.length == 0 && disqualifiedExaminationYear.length == 0 && periodFromDisqualification.length == 0 && periodToDisqualification.length ==0 && disqualificationUniversityBoard.length ==0) {
disqualifiedExaminationMessage="Please Check Data Filled In Disqualified Examination, Period Dates Format Should Be YYYY-MM-DD";
await this.setState({"disqualifiedExaminationMessage": disqualifiedExaminationMessage});
await this.setState({exception: true});
message+= disqualifiedExaminationMessage+"\n";
window.document.getElementById("disqualifiedExamination").focus();
}
}

var previousExamPassed = window.document.getElementById("previousExamPassed").value.trim();
var previousExamPassedMessage="";
if((previousExamPassed.length < 2 || previousExamPassed.length > 14) || this.checkSpecialChar(previousExamPassed)==true)
{
previousExamPassedMessage="Please Check Previous Examination Passed";
await this.setState({"previousExamPassedMessage": previousExamPassedMessage});
await this.setState({exception: true});
message+= previousExamPassedMessage+"\n";
window.document.getElementById("previousExamPassed").focus();
}

var previousExamPassedBoardUniversity = window.document.getElementById("previousExamPassedBoardUniversity").value.trim();
var previousExamPassedBoardUniversityMessage="";
if((previousExamPassedBoardUniversity.length < 2 || previousExamPassedBoardUniversity.length > 14) || this.checkSpecialChar(previousExamPassedBoardUniversity)==true)
{
previousExamPassedBoardUniversityMessage="Please Check Previous Examination Passed";
await this.setState({"previousExamPassedBoardUniversityMessage": previousExamPassedBoardUniversityMessage});
await this.setState({exception: true});
message+= previousExamPassedBoardUniversityMessage+"\n";
window.document.getElementById("previousExamPassedBoardUniversity").focus();
}

var previousExamPassedRollno = window.document.getElementById("previousExamPassedRollno").value.trim();
var previousExamPassedRollnoMessage="";
if((previousExamPassedRollno.length < 2 || previousExamPassedRollno.length > 10) || this.checkSpecialChar(previousExamPassedRollno)==true)
{
previousExamPassedRollnoMessage="Please Check Previous Examination Passed Roll Number";
await this.setState({"previousExamPassedBoardUniversityMessage": previousExamPassedRollnoMessage});
await this.setState({exception: true});
message+= previousExamPassedRollnoMessage+"\n";
window.document.getElementById("previousExamPassedRollno").focus();
}

var previousExamPassedSession = window.document.getElementById("previousExamPassedSession").value.trim();
var previousExamPassedSessionMessage="";
if((previousExamPassedSession.length < 2 || previousExamPassedSession.length > 10) || this.checkSpecialChar(previousExamPassedSession)==true)
{
previousExamPassedSessionMessage="Please Check Previous Examination Passed Session";
await this.setState({"previousExamPassedSessionMessage": previousExamPassedSessionMessage});
await this.setState({exception: true});
message+= previousExamPassedSessionMessage+"\n";
window.document.getElementById("previousExamPassedSession").focus();
}

var previousExamPassedYear = window.document.getElementById("previousExamPassedYear").value.trim();
var previousExamPassedYearMessage="";
if((previousExamPassedYear.length < 4 || previousExamPassedYear.length > 4) || this.checkSpecialChar(previousExamPassedYear)==true)
{
previousExamPassedYearMessage="Please Check Previous Examination Passed Year";
await this.setState({"previousExamPassedYearMessage": previousExamPassedYearMessage});
await this.setState({exception: true});
message+= previousExamPassedYearMessage+"\n";
window.document.getElementById("previousExamPassedYear").focus();
}

var previousExamPassedResult = window.document.getElementById("previousExamPassedResult").value.trim();
var previousExamPassedResultMessage="";
if((previousExamPassedResult.length < 4 || previousExamPassedResult.length > 12) || this.checkSpecialChar(previousExamPassedResult)==true)
{
previousExamPassedResultMessage="Please Check Previous Examination Passed Result";
await this.setState({"previousExamPassedResultMessage": previousExamPassedResultMessage});
await this.setState({exception: true});
message+= previousExamPassedResultMessage+"\n";
window.document.getElementById("previousExamPassedResult").focus();
}

var previousExamPassedMarksObtained = window.document.getElementById("previousExamPassedMarksObtained").value.trim();
var previousExamPassedMarksObtainedMessage="";
if((previousExamPassedMarksObtained.length <= 0 || previousExamPassedMarksObtained.length > 5) || this.checkSpecialChar(previousExamPassedMarksObtained)==true)
{
previousExamPassedMarksObtainedMessage="Please Check Previous Examination Passed Result";
await this.setState({"previousExamPassedMarksObtainedMessage": previousExamPassedMarksObtainedMessage});
await this.setState({exception: true});
message+= previousExamPassedMarksObtainedMessage+"\n";
window.document.getElementById("previousExamPassedMarksObtained").focus();
}

var previousExamPassedMaxMarks = window.document.getElementById("previousExamPassedMaxMarks").value.trim();
var previousExamPassedMaxMarksMessage="";
if((previousExamPassedMaxMarks.length <= 0 || previousExamPassedMaxMarks.length > 6) || this.checkSpecialChar(previousExamPassedMaxMarks)==true)
{
previousExamPassedMaxMarksMessage="Please Check Previous Examination Passed Max. Marks";
await this.setState({"previousExamPassedMaxMarksMessage": previousExamPassedMaxMarksMessage});
await this.setState({exception: true});
message+= previousExamPassedMaxMarksMessage+"\n";
window.document.getElementById("previousExamPassedMaxMarks").focus();
}
message += reappearmessage;
if(this.state.exception==true && message.length > 0) {
// window.alert(this.state.exception);
// await this.setState({errorMessage: message});
setTimeout(function() {
window.alert("Please Read Message Carefully:\n"+message);
}, 100);
return;
}
/*
else if(window.document.getElementById("agree").checked == false) {
window.alert("Please Check The Declaration Checkbox");
return;
}
*/


this.indata = {
"type":"Re-Appear",
"studentId": `${this.props.sinfo.studentInfo.studentId}`,
"subjects": subarray,
"semester": `${this.props.sinfo.studentInfo.studentSemester}` ,
"examProfYearSem": `${examProfYearSem}`,
"examSession": `${examSession}`,
"examYear": `${examYear}`,
"domicile": `${domicile}`,
"passedExam": `${passedExam}`,
"appearingIn": `${appearingIn}`,
"previousClassDurationMonths": `${previousClassDurationMonths}`,
"examinationCentre": `${examinationCentre}`,
"disqualifiedExamination": `${disqualifiedExamination}`,
"disqualifiedExaminationRollno": `${disqualifiedExaminationRollno}`,
"disqualifiedExaminationMonth": `${disqualifiedExaminationMonth}`,
"disqualifiedExaminationYear": `${disqualifiedExaminationYear}`,
"periodFromDisqualification": `${periodFromDisqualification}`,
"periodToDisqualification": `${periodToDisqualification}`,
"disqualificationUniversityBoard": `${disqualificationUniversityBoard}`,
"previousExamPassed": `${previousExamPassed}`,
"previousExamPassedBoardUniversity": `${previousExamPassedBoardUniversity}`,
"previousExamPassedRollno": `${previousExamPassedRollno}`,
"previousExamPassedSession": `${previousExamPassedSession}`,
"previousExamPassedYear": `${previousExamPassedYear}`,
"previousExamPassedResult": `${previousExamPassedResult}`,
"previousExamPassedMarksObtained": `${previousExamPassedMarksObtained}`,
"previousExamPassedMaxMarks": `${previousExamPassedMaxMarks}`,
"reappearedStudentSubjectData" : reappearedStudentSubjectData,
"detail":"",
"misc":"",
"presentAddress": `${presentAddress}`,
}

// window.alert("Checkbox: "+window.document.getElementById("agree").checked);
// console.log(this.indata);
if(this.state.exception==false) {
await this.setState({status: true});
var info = await fillReappearExamFormResponse(this.indata);
// console.log(info.data);
 console.log(info.data.message, info.data.error);
if(info.data.message && info.data.message=="success") {
window.alert("Form Submitted Successfully!\n Please Go To Fee Detail to Pay Examination Fee Online");

const { history } = this.props;
   if(history) history.push("/feedetails");
// this.props.history.push("/feedetails");
}

else if (info.data.message=="error" ) {
var allerrors=""
var data=info.data.error;
for(var i=0;i<data.length;i++) { allerrors += "\n"+data[i]}
setTimeout(function() {
window.alert("Message:\n"+allerrors);
}, 100);
}
else if(info.data.message != "error" || info.data.message != "success") {
setTimeout(function() {
window.alert("Exception Message:\n"+info.data.message);
}, 100);
}
}
else {
setTimeout(function() {
window.alert("Please Check The Filled Information");
}, 100);
}
await this.setState({status: false});
}

getExamSession = () => {
const d = new Date(); 
var session = "May-Jun-"+(d.getYear()+1900)

if((d.getMonth()+1) <= 5) {
session="May-Jun-"+(d.getYear()+1900);
}
else {
session="Nov-Dec-"+(d.getYear()+1900);
}
return session;
}

getDate = () => {
const d = new Date(); 
return (d.getDate()+"-"+(d.getMonth()+1)+"-"+(d.getYear()+1900));
}
render() {
return (
<div>


<div style={{width: "800px", minWidth: "800px", maxWidth: "800px", color: "#f00", textAlign: "left", fontWeight: "bold", backgroundColor: "#ffa", padding: "10px"}}>
<u>READ INSTRUCTIONS CAREFULLY BEFORE FILLING EXAMINATION FORM:</u>
<ol>
<li>Information must be correct, otherwise your form will be rejected.</li>
<li>Information is to be filled in the text boxes with light red background (editable).</li>
<li>Domicile (Must fill the state of your residence address).</li>
<li>In detail of previous examination passed:
<ol type="a">
<li>First fill the name of examination (class) passed.</li>
<li>Fill the name of Board/University in short form.</li>
<li>Fill the complete roll no. of passed examination.</li>
<li>Fill the session (name of month) in which you have passed examination (eg. Mar or Mar_Apr).</li>
<li>Fill the Year of passed examination (eg. 2018).</li>
<li>Fill the Result, Whatever is (eg. Pass).</li>
<li>Fill Marks Obtained (Marks you have got).</li>
<li>Fill Total Marks.</li>
</ol></li>
<li>In Disqualified/UMC case Section, Fill details if you have ever been, otherwise leave this section (blank).</li>
{/*
<li>In Certificate:<ol type="a">
<li>Fill the previously qualified examination name.</li>
<li>Fill the duration of previous class of qualified examination in number (eg. 12 if duration of previous class of qualified examination was of 12 months).</li>
<li>Fill the name of the class for appearing in examination.</li>
</ol>
</li>
<li>Certificate For Reappear Failed Student, Fill the rows upto applicable number, Leave rest of the rows blank if not applicable:<ol type="a">
<li>Fill the session (name of month) in which you have passed examination (eg. Mar or Mar_Apr).</li>
<li>Fill the Year of passed examination (eg. 2018).</li>
<li>Fill the complete roll no. of reappear/failed examination.</li>
<li>Fill the Result, Whatever is (eg. Reappear).</li>
</ol></li>
*/}

</ol>
</div>
<br/><br/>


<div>
<div style={{width: "800px", minWidth: "800px", maxWidth: "800px", border: "1px sold #777"}}  id="printpage">

<div style={{width: "100%"}}>
<div style={{width: "300px", visibility: "hidden", float: "left"}} >Sr.No.________________</div>
<div style={{width: "300px", float: "right"}} >Roll No.________________<br/>To&nbsp;be&nbsp;assigned&nbsp;by&nbsp;the&nbsp;University</div>
</div>
<br/>
<br/>
<div style={{width: "100%"}}>
<div  align="center" style={{fontSize: '130%'}}><table style={{width: "100%"}}><tr><td><img src="https://www.sgrduhs.in/temp/html2/image/big-logo.png" style={{width: "100px"}} /></td><td style={{color: "#2b374f"}}>SRI GURU RAM DAS UNIVERSITY OF HEALTH SCIENCES, SRI AMRITSAR
<div  align="center" style={{fontSize:'90%'}}>(Examination form for Re-Appear/Failed Students only)</div>
</td></tr></table></div>

</div>

<div align="left" style={{color: "#f00"}}><div style={{FonrWeight: "bold"}}><b><u>Important Instructions</u></b></div>
a. Please cross-verify the information filled below.<br/>
b. All the columns are mandatory.<br/>
</div>

<div style={{width: "100%", border: "1px solid #777"}}>
<div style={{width: "35%", float: "left"}}><table style={{width: "100%"}}><tr><td><b>Course/Class:</b></td><td><TextField id="courseclass" name='courseclass' value={this.props.sinfo.studentInfo.studentCourse} style={{width:'150px'}} /></td></tr></table></div>
<div style={{width: "10%", float: "left"}}><table><tr><td>Prof/Year:</td><td align="right"><TextField  inputProps={{maxLength: "2"}} id="examProfYearSem" name='examProfYearSem' style={{width:'20px'}} value={this.props.sinfo.studentInfo.studentSemester}/></td></tr></table></div>
<div style={{width: "30%", float: "left"}}><table><tr><td>Session:</td><td><TextField inputProps={{maxLength: "10"}} id="examSession"  name='examSession' style={{width:'100px'}} value={this.props.sinfo.studentInfo.studentExamSession}/></td></tr></table></div>
<div style={{width: "25%", float: "left"}}><table><tr style={{textAlign:"right"}}><td>Year:</td><td><TextField inputProps={{maxLength: "4"}} id="examYear" name='examYear' style={{width:'40px'}} value={this.props.sinfo.studentInfo.studentExamYear}/></td></tr></table></div>
</div>

<div style={{FontWeight: 'bold', marginTop: '15px', width: "100%"}}>
<table border="0" style={{width: '100%', padding: "0px", margin: "-1px"}}><tr><td>
<table>
<tr><td><b>1.&nbsp;Student&nbsp;Name:</b></td><td>{this.props.sinfo.studentInfo.studentName}</td></tr>
<tr><td><b>2.&nbsp;Father's&nbsp;Name:</b></td><td>{this.props.sinfo.studentInfo.studentFatherName}</td></tr>
<tr><td><b>3.&nbsp;Mother's&nbsp;Name:</b></td><td>{this.props.sinfo.studentInfo.studentMotherName}</td></tr>
<tr><td><b>4.&nbsp;Registraton&nbsp;No.:</b></td><td>{this.props.sinfo.studentInfo.studentRegistrationNumber}</td></tr>
<tr><td><b>5.&nbsp;Examination&nbsp;Centre:</b></td><td><TextField id="examinationCentre" name="examinationCentre" style={{width:"400px"}} inputProps={{maxLength: "50"}} value={this.props.sinfo.studentInfo.studentExamCentre} /></td></tr>
<tr><td><b>6.&nbsp;Gender:</b></td><td>{this.props.sinfo.studentInfo.studentGender}</td></tr>
<tr><td><b>7.&nbsp;Annual&nbsp;Family&nbsp;Income:</b></td><td>{this.props.sinfo.studentInfo.fatherAnnualIncome} {this.props.sinfo.studentInfo.fatherAnnualIncomeCurrency} + {this.props.sinfo.studentInfo.motherAnnualIncome} {this.props.sinfo.studentInfo.motherAnnualIncomeCurrency}</td></tr>
<tr><td><b>8.&nbsp;Category:</b></td><td>{this.props.sinfo.studentInfo.studentCategory}</td></tr>
<tr><td><b>9.&nbsp;Date&nbsp;Of&nbsp;Birth:</b></td><td>{this.props.sinfo.studentInfo.studentDOB}</td></tr>
<tr><td><b>10.&nbsp;Domicile:</b></td><td><TextField id="domicile" name="domicile" value={this.props.sinfo.studentInfo.studentState.toUpperCase()} style={{width:"400px", backgroundColor: "#fdd"}} inputProps={{maxLength: "50"}} placeholder="Domicile" /></td></tr>

</table>
</td><td><img src={this.props.sinfo.studentInfo.studentPic64} style={{width: "150px"}} /></td></tr></table>
</div>
<div>
</div>
<div>
<table style={{width: "100%"}}>
<tr><td><b>11.&nbsp;Permanent&nbsp;Address:</b></td><td colspan="6">{this.props.sinfo.studentInfo.studentAddress}</td></tr>
<tr><td><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pincode:</b>{this.props.sinfo.studentInfo.studentPincode}</td><td>
<b>Contact&nbsp;No.(Resi):</b>{this.props.sinfo.studentInfo.studentFatherContactNo}</td><td>
<b>(M):</b>{this.props.sinfo.studentInfo.studentContactNo}</td></tr>
</table>

<table style={{width: "100%"}}>
<tr><td><b>12.&nbsp;Correspondance&nbsp;Address:</b></td><td colspan="6">{this.props.sinfo.studentInfo.studentAddress}</td></tr>
<tr><td><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pincode:</b>{this.props.sinfo.studentInfo.studentPincode}</td><td>
<b>Contact&nbsp;No.(Resi):</b>{this.props.sinfo.studentInfo.studentFatherContactNo}</td><td>
<b>(M):</b>{this.props.sinfo.studentInfo.studentContactNo}</td></tr>
</table>
</div>

<div>
<table  border="1" style={{width: "100%", borderCollapse: "collapse",
    border: "1px"}}>
      <tr>
    	<th style={{textAlign: "left"}}>13.</th>
	    <th>Subject Code</th>
	<th>Subject Offered</th>
	<th>Abbreviation</th>
<th>Subject Selection</th>
        </tr>
          {this.props.sinfo.subjects.map((subject, index) => (
            <tr>
<td>{String.fromCharCode((index+97))}</td>
		<td>{subject.code}</td>
		<td>{subject.name}</td>
		<td>{subject.abbreviation}</td>
		<td style={{textAlign: "center"}}>{(<input type="checkbox" id={"subject"+index} name={"subject"+index} key={subject.id} value={subject.id} />)} </td>
</tr>
         
          ))}
 
      </table>
<br/>
<div align="left">
<div><b>14.&nbsp;Detail of Previous Examination Passed</b></div>
<div>
<table border="1" style={{width: "100%", borderCollapse: "collapse",
    border: "1px"}}>
<tr>
<th>Examination</th><th>Board/University</th><th>Roll No.</th><th>Session</th>
<th>Year</th><th>Result</th><th>Marks<br/>Obtained</th><th>Total<br/>Marks</th>
</tr>
<tr>
<td><TextField id="previousExamPassed" name="previousExamPassed" style={{width:"100%", backgroundColor: "#fdd"}} inputProps={{maxLength: "14"}}  /></td><td><TextField id="previousExamPassedBoardUniversity" name="previousExamPassedBoardUniversity" style={{width:"100%", backgroundColor: "#fdd"}} inputProps={{maxLength: "14"}}/></td><td><TextField id="previousExamPassedRollno" name="previousExamPassedRollno" style={{width:"100%", backgroundColor: "#fdd"}} inputProps={{maxLength: "10"}} /></td><td><TextField id="previousExamPassedSession" name="previousExamPassedSession" style={{width:"100%", backgroundColor: "#fdd"}} inputProps={{maxLength: "12"}} placeholder="Month" /></td>
<td><TextField id="previousExamPassedYear" name="previousExamPassedYear" style={{width:"100%", backgroundColor: "#fdd"}} inputProps={{maxLength: "4"}} placeholder="YYYY" /></td><td><TextField id="previousExamPassedResult" name="previousExamPassedResult" style={{width:"100%", backgroundColor: "#fdd"}} inputProps={{maxLength: "12"}} /></td><td><TextField id="previousExamPassedMarksObtained" name="previousExamPassedMarksObtained" style={{width:"100%", backgroundColor: "#fdd"}} inputProps={{maxLength: "5"}} /></td><td><TextField id="previousExamPassedMaxMarks" name="previousExamPassedMaxMarks" style={{width:"100%", backgroundColor: "#fdd"}} inputProps={{maxLength: "6"}}/></td>
</tr>
</table>
</div>
<div align="left">
<b>15.&nbsp;Have you ever been disqualified from appearing in any examination or any case of unfair mean (UMC) is pending against you in any university/board? if so (give details):</b> 
</div>
<table border="1" style={{width: "100%", borderCollapse: "collapse",
    border: "1px"}}>
<tr>
<th rowspan="2">
Examination
</th>
<th rowspan="2">
Roll No.
</th>
<th rowspan="2">
Month
</th>
<th rowspan="2">
Year
</th>
<th colspan="2">
Period of Disqualification
</th>
<th rowspan="2">
University/Board
</th>
</tr>
<tr>
<th>
From
</th>
<th>
To
</th>

</tr>
<tr>
<td><TextField id="disqualifiedExamination" name="disqualifiedExamination" style={{width:"100%", backgroundColor: "#fdd"}}  inputProps={{maxLength: "14"}}/></td>
<td><TextField id="disqualifiedExaminationRollno" name="disqualifiedExaminationRollno" style={{width:"100%", backgroundColor: "#fdd"}}  inputProps={{maxLength: "10"}}/></td>
<td><TextField id="disqualifiedExaminationMonth" name="disqualifiedExaminationMonth" style={{width:"100%", backgroundColor: "#fdd"}}  inputProps={{maxLength: "12"}} placeholder="Month" /></td>
<td><TextField id="disqualifiedExaminationYear" name="disqualifiedExaminationYear" style={{width:"100%", backgroundColor: "#fdd"}} inputProps={{maxLength: "4"}} placeholder="YYYY" /></td>

<td><TextField id="periodFromDisqualification" name="periodFromDisqualification" style={{width:"100%", backgroundColor: "#fdd"}} inputProps={{maxLength: "10"}} placeholder="YYYY-MM-DD" /></td>
<td><TextField id="periodToDisqualification" name="periodToDisqualification" style={{width:"100%", backgroundColor: "#fdd"}} inputProps={{maxLength: "10"}} placeholder="YYYY-MM-DD" /></td>



<td><TextField id="disqualificationUniversityBoard" name="disqualificationUniversityBoard" style={{width:"100%", backgroundColor: "#fdd"}} inputProps={{maxLength: "14"}}/></td>
</tr>
</table>
<b>16.&nbsp;I solemnly declare that the particulars filled in by me above are correct to the best of my knowledge and belief and if any discrepancy is found at any stage, I shall be responsible for the consequences.</b>
</div>
<div align="left" style={{width: "100%"}}>
<div style={{width: '30%', float: "right"}}><br/><img src={this.props.sinfo.studentInfo.studentSignature64} width="175px" height="75px"/><br/>Signature of Student</div><div align="left" style={{width: '30%', float: "left"}}><br/><br/><br/><br/><br/>Date: {this.getDate()}</div>
</div>
</div>
<div align="center">


</div>

{ /*
<div style={{pageBreakBefore: "always"}}>
<div align="center" style={{fontWeight: "bold", fontSize: "120%"}}><u>CERTIFICATE</u></div>
<br/>
<div align="center" style={{fontWeight: "bold", fontSize: "110%"}}><u>Certified that</u></div>

<div>
<table align="left" style={{width: "100%"}}>
<tr>
<td><img src={this.props.sinfo.studentInfo.studentPic64} style={{width: "150px"}} /></td>
<td>
<div align="justify"><ol type="I"><li><u>{this.props.sinfo.studentInfo.studentName}</u> S/O, D/O  <u>{this.props.sinfo.studentInfo.studentFatherName}</u> Roll No.<u>{this.props.sinfo.studentInfo.studentRollNo}</u> was admitted to <u>{this.props.sinfo.studentInfo.studentCourse}</u> course in year <u>{this.props.sinfo.studentInfo.studentBatch}</u>
</li><li>The student has been on the roll of this college for the academic year preceding the examination.
</li><li>The student has passed the qualifying <TextField inputProps={{maxLength: "45"}} id="qualifyingExam" name='qualifyingExam' style={{width:'300px', backgroundColor: "#fdd"}}/> professional/year examination not less than <TextField inputProps={{maxLength: "2"}} id="previousClassDurationMonths" name='previousClassDurationMonths' style={{width:'30px', backgroundColor: "#fdd"}}/> months previously and he/she has fulfilled the conditiond laid down under the University Ordinances/Regulation in force for appearing in <TextField inputProps={{maxLength: "45"}} id="appearingIn" name='appearingIn' style={{width:'300px', backgroundColor: "#fdd"}} /> Professional/Year examination.
</li><li>The student fulfilling the requirement of lectures as per University Ordinance in theory and practical in each subject separately (Given Below)</li>
</ol>    
</div>
</td>
</tr>
</table>
</div>
<div align="left">Further Certified that the student has satisfied me by production of authentic documents that the statement made by him/her overleaf are correct and that he/she has a good health and bears a good moral character.</div>
<br/><br/>
<div align="left" style={{width: '30%', float: "left"}}>Date:....................</div><div align="right" style={{width: '30%', float: "right"}}><b>Principal<br/>(Signature&nbsp;&&nbsp;Stamp)</b></div>
<div>
<br/><br/>
<b>Minimum lectures required for different courses as per University Ordinances are as under</b>
<table border="1" style={{width: "100%", borderCollapse: "collapse",
    border: "1px"}}>
<tr><th>Course</th><th>Lectures required in Theory</th><th>Lectures required in Practical</th></tr>
<tr><td>MBBS</td><td>75%</td><td>80%</td></tr>
<tr><td>B.Sc. (Nursing)/ Post Basic (Nursing)/ other B.Sc. & Diploma Courses</td><td>80%</td><td>100%</td></tr>
</table>
</div>
<br/>
<div>
<div align="center"><u><b>CERTIFICATE FOR REAPPEAR/FAILED STUDENT</b></u></div>
<div><b>Certified that</b></div>
<div align="left" style={{visibility: "hidden"}}>1.&nbsp;The student has alerady availed.............................number of chances below</div>
<div>
*/}

<table border="1" style={{width: "100%", borderCollapse: "collapse",
    border: "1px", display: "none"}}>
<tr>
<th>Chances</th><th>Session</th><th>Year</th><th>Roll No.</th><th>Result</th>
</tr>
<tr>
<td>Appeared 1<sup>st</sup> Time</td><td><TextField inputProps={{maxLength: "14"}} id="reapperedFailedSession1" name='reapperedFailedSession1' style={{width:'150px', backgroundColor: "#fdd"}} placeholder="Month" /></td><td><TextField inputProps={{maxLength: "4"}} id="reapperedFailedYear1" name='reapperedFailedYear1' style={{width:'150px', backgroundColor: "#fdd"}} placeholder="YYYY" /></td><td><TextField inputProps={{maxLength: "14"}} id="reapperedFailedRollno1" name='reapperedFailedRollno1' style={{width:'150px', backgroundColor: "#fdd"}}/></td><td><TextField inputProps={{maxLength: "14"}} id="reapperedFailedResult1" name='reapperedFailedResult1' style={{width:'150px', backgroundColor: "#fdd"}}/></td>
</tr>
<tr>
<td>Appeared 2<sup>nd</sup> Time</td><td><TextField inputProps={{maxLength: "14"}} id="reapperedFailedSession2" name='reapperedFailedSession2' style={{width:'150px', backgroundColor: "#fdd"}} placeholder="Month" /></td><td><TextField inputProps={{maxLength: "4"}} id="reapperedFailedYear2" name='reapperedFailedYear2' style={{width:'150px', backgroundColor: "#fdd"}} placeholder="YYYY" /></td><td><TextField inputProps={{maxLength: "14"}} id="reapperedFailedRollno2" name='reapperedFailedRollno2' style={{width:'150px', backgroundColor: "#fdd"}}/></td><td><TextField inputProps={{maxLength: "14"}} id="reapperedFailedResult2" name='reapperedFailedResult2' style={{width:'150px', backgroundColor: "#fdd"}}/></td>
</tr>
<tr>
<td>Appeared 3<sup>rd</sup> Time</td><td><TextField inputProps={{maxLength: "14"}} id="reapperedFailedSession3" name='reapperedFailedSession3' style={{width:'150px', backgroundColor: "#fdd"}} placeholder="Month" /></td><td><TextField inputProps={{maxLength: "4"}} id="reapperedFailedYear3" name='reapperedFailedYear3' style={{width:'150px', backgroundColor: "#fdd"}} placeholder="YYYY" /></td><td><TextField inputProps={{maxLength: "14"}} id="reapperedFailedRollno3" name='reapperedFailedRollno3' style={{width:'150px', backgroundColor: "#fdd"}}/></td><td><TextField inputProps={{maxLength: "14"}} id="reapperedFailedResult3" name='reapperedFailedResult3' style={{width:'150px', backgroundColor: "#fdd"}}/></td>
</tr>
<tr>
<td>Appeared 4<sup>th</sup> Time</td><td><TextField inputProps={{maxLength: "14"}} id="reapperedFailedSession4" name='reapperedFailedSession4' style={{width:'150px', backgroundColor: "#fdd"}} placeholder="Month" /></td><td><TextField inputProps={{maxLength: "4"}} id="reapperedFailedYear4" name='reapperedFailedYear4' style={{width:'150px', backgroundColor: "#fdd"}} placeholder="YYYY" /></td><td><TextField inputProps={{maxLength: "14"}} id="reapperedFailedRollno4" name='reapperedFailedRollno4' style={{width:'150px', backgroundColor: "#fdd"}}/></td><td><TextField inputProps={{maxLength: "14"}} id="reapperedFailedResult4" name='reapperedFailedResult4' style={{width:'150px', backgroundColor: "#fdd"}}/></td>
</tr>
<tr>
<td>Appeared 5<sup>th</sup> Time</td><td><TextField inputProps={{maxLength: "14"}} id="reapperedFailedSession5" name='reapperedFailedSession5' style={{width:'150px', backgroundColor: "#fdd"}} placeholder="Month" /></td><td><TextField inputProps={{maxLength: "4"}} id="reapperedFailedYear5" name='reapperedFailedYear5' style={{width:'150px', backgroundColor: "#fdd"}} placeholder="YYYY" /></td><td><TextField inputProps={{maxLength: "14"}} id="reapperedFailedRollno5" name='reapperedFailedRollno5' style={{width:'150px', backgroundColor: "#fdd"}}/></td><td><TextField inputProps={{maxLength: "14"}} id="reapperedFailedResult5" name='reapperedFailedResult5' style={{width:'150px', backgroundColor: "#fdd"}}/></td>
</tr>
<tr>
<td>Appeared 6<sup>th</sup> Time</td><td><TextField inputProps={{maxLength: "14"}} id="reapperedFailedSession6" name='reapperedFailedSession6' style={{width:'150px', backgroundColor: "#fdd"}} placeholder="Month" /></td><td><TextField inputProps={{maxLength: "4"}} id="reapperedFailedYear6" name='reapperedFailedYear6' style={{width:'150px', backgroundColor: "#fdd"}} placeholder="YYYY" /></td><td><TextField inputProps={{maxLength: "14"}} id="reapperedFailedRollno6" name='reapperedFailedRollno6' style={{width:'150px', backgroundColor: "#fdd"}}/></td><td><TextField inputProps={{maxLength: "14"}} id="reapperedFailedResult6" name='reapperedFailedResult6' style={{width:'150px', backgroundColor: "#fdd"}}/></td>
</tr>
</table>

{ /*
</div>
<div align="left">2. He/She is eligible to appear in the examination as a late college student under the Ordinances/Regulation in force.
<br/><br/><br/>
<div style={{float: "left"}}>Date:.......................</div><div align="right" style={{width: '30%', float: "right"}}><b>Principal<br/>(Signature & Stamp)</b></div>
<br/>
<br/>


</div>

</div>
<br/>
<div>
<b>Schedule for Submission of Examination Form and Fee</b>
<table border="1" style={{width: "100%", borderCollapse: "collapse",
    border: "1px"}}>
<tr><th>Session</th><th>Without Late Fee</th><th>With Late Fee of Rs.200/-</th><th>With Late Fee of Rs.500/-</th><th>With Late Fee of Rs.1500/-</th></tr>
<tr><td>May/June</td><td>1<sup>st</sup> March</td><td>15<sup>th</sup> March</td><td>31<sup>st</sup> March</td><td>15<sup>th</sup> April</td></tr>
<tr><td>Nov/Dec</td><td>15<sup>th</sup> September</td><td>30<sup>th</sup> September</td><td>15<sup>th</sup> October</td><td>31<sup>st</sup> October</td></tr>
</table>
</div>
<div>
<div align="left" style={{fontWeight: "bold"}}>Note:</div>
<div align="justify">
<ol>
<li>
The University may accept examination form and fee ten days before the commencement of examination with the permission of worthy Vice-Chancellor and late fee of <b>Rs.5000/-</b>. 
</li>
<li>
In case of late declaration of result due to any reason, the examination form shall be accepted within 15 days of the publication of the result without changing any late/extra fee. Otherwise late fee shall be applicable as per current schedule (Mentioned Above).  
</li>
<li>
Examination form may be submitted to University within <b>15 days</b> of the publication of the result without changing any late/extra fee failing which normal schedule as above will be applicable.   
</li>
</ol> 
</div>
</div>
<div align="justify">
<b><u>*CODE/ABBREVIATIONS:</u> ANATOMY (ANT), PHYSIOLOGY (PHY), BIOCHEMISTRY (BCH), PATHOLOGY (PAT), PHARMACOLOGY (PHA), MICROBIOLOGY (MIC), FORENSIC MEDICINE (FOR), OPHTHALMOLOGY (EYE), ENT (ENT), COMMUNITY MEDICINE (SPM), MEDICINE (MED), SURGERY (SUR), PAEDIATRICS (PAED), OBST. & GYNAE (OBG).</b>
</div>
</div>
*/}
<br/><br/><br/><br/><br/><br/><br/>
<input style={{visibility:"hidden"}} type="checkbox" name="agree" id="agree" />
<b>I solemnly declare that the particulars filled in by me above are correct to the best of my knowledge and belief and if any discrepancy is found at any stage, I shall be responsible for the consequences.</b>

<br/><br/>
</div>


</div>


<Button align="center" name="submit" id="submit" value="Submit Form" variant="outlined" disabled={this.state.status} onClick ={(event) => this.submitRequest()}>Agree & Submit {(this.state.status)?(<CircularProgress className='loader' variant='indeterminate' color='secondary' />):("")}
 </Button>


<br/>

<br/>
<br/>
<Button style={{visibility: "hidden"}} align="center" name="print" id="print" value="Print Forms" variant="outlined" onClick ={(event) => this.printpage()}>Print Forms</Button>

</div>
);
}

}

export default withRouter(RegularExaminationForm);




{ /*
<MuiPickersUtilsProvider utils={DateFnsUtils}>
            
        <KeyboardDatePicker
          margin="normal"
          id="Date From"
          label="Date From"
          format="yyyy-MM-dd"
          value={`${this.state.transactionDatetimeFrom}`}
          onChange={e => this.handlePeriodFromDisqualificationChange(e)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
<br/>
<br/>
<KeyboardDatePicker
          margin="normal"
          id="Date To"
          label="Date To"
          format="yyyy-MM-dd"
          value={`${this.state.transactionDatetimeTo}`}
	onChange={e => this.handlePeriodToDisqualificationChange(e)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        
    </MuiPickersUtilsProvider>

*/ } 


