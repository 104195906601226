import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
//import App from './App';
// import Login from './login/Login';
// import Main from './main/Main';
 import StudentAuth from './studentauth/checkAuth';

import * as serviceWorker from './serviceWorker';

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("./firebase-messaging-sw.js")
    .then(function(registration) {
      console.log("Registration successful, scope is:", registration.scope);
    })
    .catch(function(err) {
      console.log("Service worker registration failed, error:", err);
    });
}

const startApp = () => {
ReactDOM.render(<StudentAuth />, document.getElementById('root'));
}

/*
const startApp = () => {
ReactDOM.render(
  <App />,
  document.getElementById('root')
);
}
*/


if(!window.cordova) {
  startApp();
// window.alert("Cordova Not Started");
} else {
// window.alert("Cordova Started");
document.addEventListener('deviceready', startApp, false);

}




/*

let printdoc = (data) => {
	let printdata = document.getElementById("printreceipt").innerHTML;
var mywindow ="";
if(!window.cordova) {    
var mywindow = window.open('', '_blank', 'status=no, toolbar=no, menubar=no, location=no, addressbar=no');
	mywindow.document.open();
    mywindow.document.write('<html><head><title></title><link rel="stylesheet" href="../common/style.css" /><link rel="stylesheet" href="../responsive.css" /></head>');
    mywindow.document.write('<body><style> .table {  border-collapse: collapse; margin-left: 10%; } .table, .th, .td {  border: 1px solid black; padding: 10px; }  .titlefont { text-align: center; font-size: 120%; } </style><div align="center">');

    mywindow.document.write(printdata);
    
        mywindow.document.write('</div></body></html>');
        setTimeout(function() { // wait until all resources loaded 
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10
        // mywindow.print(); // change window to winPrint
       // mywindow.close(); // change window to winPrint
     }, 250);
}
else {



var mywindow = cordova.InAppBrowser.open('', '_blank', 'status=no, toolbar=no, menubar=no, location=no, addressbar=no');
	mywindow.document.open();
    mywindow.document.write('<html><head><title></title><link rel="stylesheet" href="../common/style.css" /><link rel="stylesheet" href="../responsive.css" /></head>');
    mywindow.document.write('<body><style> .table {  border-collapse: collapse; margin-left: 10%; } .table, .th, .td {  border: 1px solid black; padding: 10px; }  .titlefont { text-align: center; font-size: 120%; } </style><div align="center">');

    mywindow.document.write(printdata);
    
        mywindow.document.write('</div></body></html>');
        setTimeout(function() { // wait until all resources loaded 
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10
        // mywindow.print(); // change window to winPrint
       // mywindow.close(); // change window to winPrint
     }, 250);

}
    return true;
}

*/






/*
function download(filename, data, mimeType) {
  var blob = new Blob([data], {
    type: mimeType
  });
  if (window.cordova && cordova.platformId !== "browser") {
  	document.addEventListener("deviceready", function() {
      var storageLocation = "";

      switch (device.platform) {
        case "Android":
          storageLocation = cordova.file.externalDataDirectory;
          break;

        case "iOS":
          storageLocation = cordova.file.documentsDirectory;
          break;
      }

      var folderPath = storageLocation;

      window.resolveLocalFileSystemURL(
        folderPath,
        function(dir) {
          dir.getFile(
            filename,
            {
              create: true
            },
            function(file) {
              // The file...
            },
            function(err) {
              alert("Unable to download");
              console.error(err);
            }
          );
        },
        function(err) {
          alert("Unable to download");
          console.error(err);
        }
      );
    });
    } else {
      saveAs(blob, filename);
    }
}

*/





// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// serviceWorker.register();
serviceWorker.unregister();

