import { studentauth } from './Source';
import { infosource } from './Source';
import { unauthinfosource } from './Source';

import { getPDF } from './Source';
import { externalinfosource } from './Source';

export const getauth = async (json) => {
const res = await studentauth('/service/student/auth', json);
return res; 
}

export const getattendance = async (json) => {
const res = await infosource('/service/student/attendance', json); 
return res; 
}

export const getlectureattendance = async (json) => {
const res = await infosource('/service/student/lectureattendance', json); 
return res; 
}

export const getattendanceCount = async (json) => {
const res = await infosource('/service/student/betweendatessubjectattendancecount', json); 
return res; 
}

export const getTestMarks = async (json) => {
const res = await infosource('/service/student/testmarksbysubject', json); 
return res; 
}

export const getClassNotification = async (json) => {
const res = await infosource('/service/student/classnotification', json); 
return res; 
}

export const getStudentNotification = async (json) => {
const res = await infosource('/service/student/studentsessionidnotification', json); 
return res; 
}

export const getManagement = async (json) => {
const res = await unauthinfosource('/service/student/management', json); 
return res; 
}

export const getLeadership = async (json) => {
const res = await unauthinfosource('/service/student/leadership', json); 
return res; 
}

export const getTestimonial = async (json) => {
const res = await unauthinfosource('/service/student/testimonial', json); 
return res; 
}

export const getStudentIssuedBooks = async (json) => {
const res = await infosource('/service/student/issuedbooks', json); 
return res; 
}


export const getFeeDetails = async (json) => {
const res = await infosource('/service/student/studentidfeedetail', json); 
return res; 
}

export const getFeePaidDetails = async (json) => {
const res = await infosource('/service/student/feerecipt', json); 
return res; 
}

export const getSentEmailResponse = async (json) => {
const res = await infosource('/service/student/contactus', json); 
return res; 
}

export const getChangePasswordResponse = async (json) => {
const res = await infosource('/service/student/changepassword', json); 
return res; 
}


export const getRazorPayCreateOrder = async (json) => {
const res = await infosource('/service/student/createrazorpayorder', json); 
return res; 
}

export const getRazorPayPaymentResponse = async (json) => {
const res = await infosource('/service/student/addrazorpayonlinerecipt', json); 
return res; 
}

export const getExamSubjectsResponse = async (json) => {
const res = await infosource('/service/student/getexamsubjects', json); 
return res; 
}

export const fillExaminationFormResponse = async (json) => {
const res = await infosource('/service/student/fillexaminationform', json); 
return res; 
}

export const getExamSubjectAndTypeDetailResponse = async (json) => {
const res = await infosource('/service/student/getexamsubjectsandexamtype', json); 
return res; 
}

export const fillRegularExamFormResponse = async (json) => {
const res = await infosource('/service/student/fillregularexaminationform', json); 
return res; 
}

export const fillReappearExamFormResponse = async (json) => {
const res = await infosource('/service/student/fillreappearexaminationform', json); 
return res; 
}


export const uploadAssignment = async (json) => {
const res = await externalinfosource('https://sgrduhs.in/module/student_module/mpd.php', json); 
return res; 
}




export const getPDFFile = async (url) => {
	const res = await getPDF(url);
	return res;
}
