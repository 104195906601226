import React, { Component } from 'react';
import { getAttendance } from './AttendanceAction';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../styles/formstyles.css';
import Typography from '@material-ui/core/Typography';
import '../common/style.css';
import Calendar from 'react-calendar';
import { getLocalData } from '../adapter/UserInfo';
// import { checkStatus } from '../adapter/checkStatus';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';




export default class Attendance extends Component {

  constructor(props){
    super(props);

this.state={
    status: false,
    open: false,
    data: '',
    attendanceDate: `${this.getDate()}`,
    connected: false,
    listener: "OFFLINE",
    con: true
   }
   this.handleDate = this.handleDate.bind(this);
  }
 
 handleOpenConnection = () => this.setState({Connected: true});
 handleCloseConnection = () => this.setState({Connected: false});


    getDate = () => {
      var date = new Date();
      var month = date.getMonth();
      var day = date.getDate();
(month < 10) ? month="0"+(month+1) : month=(month+1);
(day < 10) ? day="0"+(day) : day=(day+0);
      let dt = (date.getYear()+1900)+"-"+month+"-"+day;
       return dt;
    }

  

   handleDate(event, date) {
    this.setState({attendanceDate: date})
  }

    getStudentId = ()  => {
     let user = getLocalData(); 
      return user.data.studentDetail.studentId;
    }

 
 submitRequest = async (dated) => {
  this.datas = {
  "studentId": `${this.getStudentId()}`,
  "dated": `${dated}`
}
// console.log(this.datas);

this.setState({status: true});
this.setState({listener: ""});
let info = await getAttendance(this.datas);
if(info!==null) {
this.setState({data: info.data});
this.setState({con: true});
// console.log(info);
}
else {
this.setState({con: false});
}
this.setState({status: false});
}

handleDateChange = event => {
    this.setState({ attendanceDate: event.target.value })
  //  console.log(this.state.attendanceDate);
    this.submitRequest(event.target.value);
 }

getCalendarDate = e => {
var d=""+e;
var data=d.split(" ");
// console.log(data[1]);

// console.log(data[0]+" "+data[1]+" "+data[2]+" "+data[3]);
var month="01";
switch(data[1]) {
  case "Jan": {
    month="01";
    break;
  }
  case "Feb": {
    month="02";
    break;
  }
  case "Mar": {
    month="03";
    break;
  }
case "Apr": {
    month="04";
    break;
  }
  case "May": {
    month="05";
    break;
  }
  case "Jun": {
    month="06";
    break;
  }
  case "Jul": {
    month="07";
    break;
  }
  case "Aug": {
    month="08";
    break;
  }
  case "Sep": {
    month="09";
    break;
  }
  case "Oct": {
    month="10";
    break;
  }
  case "Nov": {
    month="11";
    break;
  }
  case "Dec": {
    month="12";
    break;
  }
  default: {
    break;
  }
  
}
var selectedDate = data[3]+"-"+month+"-"+data[2];
// console.log(selectedDate);
this.setState({ attendanceDate: selectedDate })
this.submitRequest(selectedDate);

}  



componentDidMount() {
  this.getDate();
  this.submitRequest();
}


render() {

return(
<div align="center" className="container">
<div className="maintitle">
<h3>Attendance on {this.state.attendanceDate}</h3>
</div>
{ this.state.status ? <div><CircularProgress className="loader" align="center" variant='indeterminate' color='secondary' /></div>  : "" }
<div align="left">
 {(this.state.con!==true) ? (<Typography style={{color: '#ff0000'}} align="center">Internet Or Data Not Available</Typography>) : ""}
{ ((this.state.data) && (this.state.data.slot1)  && (this.state.data.slot1 !== "")) ? <Typography className="typography textstyle" style={{borderRadius: '10px', border: '1px solid #aaa', boxShadow: '5px 5px 5px #aaa', marginBottom: '20px'}}> ({ this.state.data.subjectName1 }) - { this.state.data.slot1 }</Typography>:"" }
{ ((this.state.data) && (this.state.data.slot2)  && (this.state.data.slot2 !== "")) ? <Typography className="typography textstyle" style={{borderRadius: '10px', border: '1px solid #aaa', boxShadow: '5px 5px 5px #aaa', marginBottom: '20px'}}> ({ this.state.data.subjectName2 }) - { this.state.data.slot2 }</Typography>:"" }
{ ((this.state.data) && (this.state.data.slot3)  && (this.state.data.slot3 !== "")) ? <Typography className="typography textstyle" style={{borderRadius: '10px', border: '1px solid #aaa', boxShadow: '5px 5px 5px #aaa', marginBottom: '20px'}}> ({ this.state.data.subjectName3 }) - { this.state.data.slot3 }</Typography>:"" }{ ((this.state.data) && (this.state.data.slot4)  && (this.state.data.slot4 !== "")) ? <Typography className="typography textstyle" style={{borderRadius: '10px', border: '1px solid #aaa', boxShadow: '5px 5px 5px #aaa', marginBottom: '20px'}}> ({ this.state.data.subjectName4 }) - { this.state.data.slot4 }</Typography>:"" }
{ ((this.state.data) && (this.state.data.slot5)  && (this.state.data.slot5 !== "")) ? <Typography className="typography textstyle" style={{borderRadius: '10px', border: '1px solid #aaa', boxShadow: '5px 5px 5px #aaa', marginBottom: '20px'}}> ({ this.state.data.subjectName5 }) - { this.state.data.slot5 }</Typography>:"" }
{ ((this.state.data) && (this.state.data.slot6)  && (this.state.data.slot6 !== "")) ? <Typography className="typography textstyle" style={{borderRadius: '10px', border: '1px solid #aaa', boxShadow: '5px 5px 5px #aaa', marginBottom: '20px'}}> ({ this.state.data.subjectName6 }) - { this.state.data.slot6 }</Typography>:"" }
{ ((this.state.data) && (this.state.data.slot7)  && (this.state.data.slot7 !== "")) ? <Typography className="typography textstyle" style={{borderRadius: '10px', border: '1px solid #aaa', boxShadow: '5px 5px 5px #aaa', marginBottom: '20px'}}> ({ this.state.data.subjectName7 }) - { this.state.data.slot7 }</Typography>:"" }
{ ((this.state.data) && (this.state.data.slot8)  && (this.state.data.slot8 !== "")) ? <Typography className="typography textstyle" style={{borderRadius: '10px', border: '1px solid #aaa', boxShadow: '5px 5px 5px #aaa', marginBottom: '20px'}}> ({ this.state.data.subjectName8 }) - { this.state.data.slot8 }</Typography>:"" }
{ ((this.state.data) && (this.state.data.slot9)  && (this.state.data.slot9 !== "")) ? <Typography className="typography textstyle" style={{borderRadius: '10px', border: '1px solid #aaa', boxShadow: '5px 5px 5px #aaa', marginBottom: '20px'}}> ({ this.state.data.subjectName9 }) - { this.state.data.slot9 }</Typography>:"" }
{ ((this.state.data) && (this.state.data.slot10)  && (this.state.data.slot10 !== "")) ? <Typography className="typography textstyle" style={{borderRadius: '10px', border: '1px solid #aaa', boxShadow: '5px 5px 5px #aaa', marginBottom: '20px'}}> ({ this.state.data.subjectName10 }) - { this.state.data.slot10 }</Typography>:"" }

{ ((this.state.data) && (this.state.data.message) && (this.state.data.message ==="null")) ? <Typography align="center" className="typography textstyle">No Lectures</Typography>:""}
{ ((this.state.data) && (this.state.data.message) && (this.state.data.message ==="error")) ? <Typography align="center" className="typography textstyle">Problem with Input</Typography>:""}
{ ((this.state.data) && (this.state.data.message) && (this.state.data.message ==="unauthorized")) ? <Typography align="center" className="typography textstyle">Unauthorized Access</Typography>:""}

</div>
<div>
<br/>
</div>
<div align="center">
<Typography>Select Date</Typography>

 <Calendar
 name="dated"
 defaultValue="" 
 onChange={e => this.getCalendarDate(e)}
 className="calendar"
/>
</div>

 <div>
      <Dialog
        open={this.state.connected}
        onClose={this.handleCloseConnection}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Message"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Internet Or Data Not Available
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={!this.handleCloseConnection} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  
 <div className="mode">{this.state.listener}</div>

</div>
);

}

} 

