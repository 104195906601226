
import React from 'react';
import '../styles/formstyles.css';
import "../common/style.css";
import { Link } from 'react-router-dom';
 
// import { messaging } from "../init-fcm";
// import { FcmReceiver } from '../receiver/FcmReceiver';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
// import image01 from '../media/images/ub.jpeg';
import "../mainvisitor/my-gallery.css";
// import cordova from 'cordova-plugin-firebase-messaging';
// import Button from '@material-ui/core/Button';
// import { getLocalData } from '../adapter/UserInfo';
//https://codeburst.io/how-to-add-push-notifications-on-firebase-cloud-messaging-to-react-web-app-de7c6f04c920


export class HomeVisitor extends React.Component {

state = {
    message: "",
    active: false
  }

handleCloseMessage = () => this.setState({active: false});
getTitle() { return `${this.state.message.title}` };
getMessage() { return `${this.state.message.message}` };

getappurl = () => {
// var cururl = window.location.href;
// addCurUrl(cururl);
}

onMessage = message => {
var messagedata="";
        // console.log(message);
       if(message.data && message.data["firebase-messaging-msg-data"] && message.data["firebase-messaging-msg-data"].data)
       messagedata = message.data["firebase-messaging-msg-data"].data;
     else if(message.data && message.data.data)
      messagedata = message.data.data;

      
      // var messagedata = message.data.data;

      this.setState({message: messagedata});
      // console.log('fcm message: ', messagedata);
      this.setState({active: true});
}

  async componentDidMount() {
this.getappurl();
  /*
    messaging.requestPermission()
      .then(async () => {
        const token = await messaging.getToken();
        console.log("FCM Token: ", token);
      })
      .catch(error => console.log(error));
*/
try {
    navigator.serviceWorker.addEventListener("message", message => {
      this.onMessage(message);

   });
}
catch(e) {
console.log(e);
}
      
  }



render() {
{/*
var publicloc = process.env.PUBLIC_URL;

const imgs = [
      {
        original: "images/p5.png",
      },
      {
        original: "images/p1.png",
      },
      {
        original: "images/p2.png",
      },
      {
        original: "images/p3.png",
      },
      {
        original: "images/p4.png",
      }

    ]

*/}


return (

    <div className="App">
{/*
<div>
<ImageGallery items={imgs} autoPlay={true} showThumbnails={false} showFullscreenButton={false}/> 
</div>
*/}

<br/>

      <div className="container effect">

          <div className="row effect">
          <div className="column" >
         <Link to={'/management'}  className="column3 anchor">
             <div className="title">Management</div>
          </Link>
          </div>
     
          <div className="column">
         <Link to={'/leadership'}  className="column4 anchor">
             <div className="title">Leadership</div>
          </Link>
          </div>
        </div>

{/*
        <div className="row effect">

          <div className="column">
          <Link to={'/tieups'}  className="column7 anchor">
            <div className="title">Tieups</div>
            </Link>
          </div>
 </div>
*/}

     
          


        <div className="row effect">
          <div className="column">
 	<Link to={'/testimonial'}  className="column8 anchor">
            <div className="title">Testimonial</div>
            </Link>
          </div>


<div className="column">
         <Link to={'/contact'}  className="column6 anchor">
             <div className="title">Contact Us</div>
          </Link>
          </div>
        </div>


<div className="row effect">
          <div className="column">
 	<Link to={'/chat'}  className="column10 anchor">
            <div className="title">Queries</div>
            </Link>
          </div>


<div className="column" onClick={() => { window.open('https://www.sgrduhs.in', '_web', ''); } } >
         <Link to={'/sgrduhs'}  className="column11 anchor">
             <div className="title">Website</div>
          </Link>
          </div>
        </div>

{/*
          <div className="column">
	 <Link to={'/events'}  className="column1 anchor">
             <div className="title">News & Events</div>
          </Link>
	  </div>
*/}
       

        <div className="row effect">
          <div className="column">
	<Link to={'/location'} className="column2 anchor">
         <div className="title">Location</div>
          </Link>
                  </div>
     
          <div className="column">
	<Link to={'/login'} className="column9 anchor">
         <div className="title">Login</div>
          </Link>

          </div>
        </div>

        </div>

         <Dialog
        open={this.state.active}
        onClose={this.handleCloseMessage}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{ `${this.getTitle()}` }</DialogTitle>
        <DialogContent>
          
           { `${this.getMessage()}` } 
          <DialogContentText id="alert-dialog-description"> 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          
        </DialogActions>
      </Dialog>
    </div>
  );
}
}

export default HomeVisitor;
