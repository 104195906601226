// const host = process.env.NODE_ENV === 'production' ? window.location.host : 'localhost:8080'

export let send

export const startWebsocketConnection = () => {
//  const ws = new window.WebSocket('ws://localhost:8080/websocketserver/websocketendpoint') || {}
//  const ws = new window.WebSocket('ws://192.168.5.23:8080/websocketserver/websocketendpoint') || {}
// const ws = new window.WebSocket('wss://psep.in:443/websocketserver/websocketendpoint') || {}
 const ws = new window.WebSocket('wss://api.sgrduhs.in:8181/websocketserver/websocketendpoint') || {}

  ws.onopen = () => {
    console.log('opened ws connection')
  }

  ws.onclose = (e) => {
    console.log('close ws connection: ', e.code, e.reason)
  }

  ws.onmessage = (e) => {
    onMessageCallback && onMessageCallback(e.data)
  }

  send = ws.send.bind(ws)
}

let onMessageCallback
export const registerOnMessageCallback = (fn) => {
  onMessageCallback = fn
}
