import React, { Component } from 'react'
import './TextBar.css'

export default class TextBar extends Component {
  constructor (props) {
    super(props)
    this.input = React.createRef()
  }







isValidWebUrl = (url) => {
 
let regEx = new RegExp('^((https?:)?\\/\\/)?'+ // protocol
        '(?:\\S+(?::\\S*)?@)?' + // authentication
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locater

  return regEx.test(url);
}








 sendMessage () {

var arr = this.input.current.value.split(" ");

var data="";
	for (var i=0; i<arr.length; i++) {


if(this.isValidWebUrl(arr[i])) {

var pattern1 = /^http:/i;
var pattern2 = /^https:/i;
 if(pattern1.test(arr[i]) == true) {
arr[i]=arr[i].substring(7);
}
 if(pattern2.test(arr[i]) == true ) {
arr[i]=arr[i].substring(8);
}
else {

}
}
data += arr[i]+" ";
}


    this.props.onSend && this.props.onSend(data)
    this.input.current.value = ''

  }
  sendMessageIfEnter (e) {
    if (e.keyCode === 13) {
      this.sendMessage()
e.preventDefault();
    }
  }

  render () {
    const sendMessage = this.sendMessage.bind(this)
    const sendMessageIfEnter = this.sendMessageIfEnter.bind(this)

    return (
      <div className='textbar'>
        <textarea className='textbar-input' id='msgtxtid' ref={this.input} onKeyDown={sendMessageIfEnter} ></textarea>
        <button className='textbar-send' onClick={sendMessage}>
          Send
        </button>
      </div>
    )
  }
}
