import * as firebase from "firebase/app";
import "firebase/messaging";
const initializedFirebaseApp = firebase.initializeApp({
	// Project Settings => Add Firebase to your web app
  apiKey: "AIzaSyDCZRqHV6XZlR7vBxgGOufNjYUJGmOK6Vo",
    authDomain: "api-project-842178298531.firebaseapp.com",
    databaseURL: "https://api-project-842178298531.firebaseio.com",
    projectId: "api-project-842178298531",
    storageBucket: "api-project-842178298531.appspot.com",
    messagingSenderId: "842178298531",
    appId: "1:842178298531:web:53ca840350c1b9a3"
});
let messaging="";
try {
messaging = initializedFirebaseApp.messaging();
messaging.usePublicVapidKey(
	// Project Settings => Cloud Messaging => Web Push certificates
  "BH2IEqQ4pVzdV19jVu5JEt-yTAcO_3MZmhsK9tc76BOJJ4CdKlmdAZx3jIKrF0aMkyJJpkO6DlZSh842kjZQVQA"
);
} catch(e) {

}
export { messaging };