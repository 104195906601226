import React, { Component } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import '../styles/formstyles.css';
// import Select from '@material-ui/core/Select';
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
import { getLocalData } from '../adapter/UserInfo';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { getSentEmailResponse } from '../adapter/infoURL';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import { checkStatus } from '../adapter/checkStatus';
import '../common/style.css';


export default class MailContact extends Component {

     getDate = () => {
      var date = new Date();
      var month = date.getMonth();
      var day = date.getDate();
  var m = 1;
      ((month+1) < 10) ? m="0"+(month+1) : m=(month+1);
      if(day < 10)  day="0"+(day);
      let dt = (date.getYear()+1900)+"-"+m+"-"+day;
      return dt;
    }

   

  getLocalStorage = () => {
  let user = getLocalData();
 // console.log(user.data);
   return user.data;
} 


  state={
    status: false,
    open: false,
    data: '',
    title: '',
    emailMessage: '',
    con: true,
    mailsent: false,
    messagingstatus: ''
   }



getStudentSessionId = () => {
let data = this.getLocalStorage();
      return data.studentDetail.studentSessionId;
}

getStudentId = ()  => {
let data = this.getLocalStorage();
      return data.studentDetail.studentId;
}

getStudentId = ()  => {
let data = this.getLocalStorage();
      return data.studentDetail.studentId;
}

getRegistrationNo = ()  => {
let data = this.getLocalStorage();
      return data.studentDetail.registrationNo;
}

getRollNo = ()  => {
let data = this.getLocalStorage();
      return data.studentDetail.rollNo;
}

getCurrentSemester = ()  => {
let data = this.getLocalStorage();
      return data.studentDetail.semester;
}

getBatch = ()  => {
let data = this.getLocalStorage();
      return data.studentDetail.batch;
}

getCourseId = ()  => {
let data = this.getLocalStorage();
      return data.studentDetail.courseId;
}

getNationality = ()  => {
let data = this.getLocalStorage();
      return data.studentDetail.nationality;
}

getName = ()  => {
let data = this.getLocalStorage();
      return data.studentDetail.name;
}

getContactNo = ()  => {
let data = this.getLocalStorage();
      return data.studentDetail.contact;
}

getEmailId = ()  => {
let data = this.getLocalStorage();
      return data.studentDetail.email;
}

getFatherName = ()  => {
let data = this.getLocalStorage();
      return data.studentDetail.fatherName;
}

getFatherContactNo = ()  => {
let data = this.getLocalStorage();
      return data.studentDetail.fathercontact;
}

getFatherEmail = ()  => {
let data = this.getLocalStorage();
      return data.studentDetail.fatheremail;
}

handleCheckBlank = () => this.setState({ open: true });
handleClose = () => this.setState({ open: false });

handleSentMessage = () => this.setState({ mailsent: true });


handleCloseSentMessageDialog = () => this.setState({ mailsent: false });

handleTitleChange = event => { 
  this.setState({ title: event.target.value })
if(event.target.value.length===0 ) this.setState({open: true});
}
handleMailMessageChange = event => {
 this.setState({ emailMessage: event.target.value })
if(event.target.value.length===0 ) this.setState({open: true});
}

submitRequest = async () => {
  this.indata = {
  "studentId": `${this.getStudentId()}`,
    "registrationNo": `${this.getRegistrationNo()}`,
    "rollNo": `${this.getRollNo()}`,
    "currentSemester": `${this.getCurrentSemester()}`,
    "batch": `${this.getBatch()}`,
    "courseId": `${this.getCourseId()}`,
    "nationality": `${this.getNationality()}`,
    "name": `${this.getName()}`,
    "contactNo": `${this.getContactNo()}`,
    "email": `${this.getEmailId()}`,
    "fatherName": `${this.getFatherName()}`,
    "fatherContactNo": `${this.getFatherContactNo()}`,
    "fatherEmail": `${this.getFatherEmail()}`,
    "message":`${this.state.emailMessage}`,
    "title":`${this.state.title}`
}
this.setState({ status: true });

if((this.state.emailMessage.length < 10 || this.state.emailMessage.length > 2000) || (this.state.title.length < 10 || this.state.title.length > 200)) {
this.handleCheckBlank();
this.setState({ status: false });
return;
}
// console.log(this.indata);
// this.setState({listener: ""})
// this.setState({status: true});
let info = await getSentEmailResponse(this.indata);
// console.log("\n\n\nREspnse Data\n\n\n", info);
if(info!==null && info.data && info.data.message) {
 this.setState({data: info.data}); 
 this.setState({con: true}); 

 if(((this.state.data) && (this.state.data.message) )) {  
  this.setState({ messagingstatus:  this.state.data.message });
  if(this.state.data.message==="success") {
    this.setState({ emailMessage: '' });
   this.setState({ title: '' });
   this.setState({ status: false });
  }
 this.handleSentMessage();
 this.setState({ status: false });
}
}
else {
this.setState({con: false});  
}
// console.log(info.data);
 this.setState({status: false});

}


render() {
//let i=0;

return(
<div align="center" className="container">
<div className="maintitle" >
<br/><br/>
    <form autoComplete="off">
<Typography className="formtitle" variant="h5" align="center">Contact Us</Typography>
        <TextField
          label="&nbsp;Title"
          type="text"
          name="title"
          multiline="true"
          rows="5"
          
          value={this.state.title}
          
          data-validators="isRequired"
	style={{width: '90%', borderRadius: '10px', border: '1px solid #aaa', boxShadow: '5px 5px 5px #aaa', marginBottom: '20px'}}
          onChange={e => this.handleTitleChange(e)}
        />
 <br/>
<br/>
 <TextField
          label="&nbsp;Message"
          type="text"
          name="message"
          multiline="true"
          rows="10"
          
          value={this.state.emailMessage}
          data-validators="isRequired"
	style={{width: '90%', borderRadius: '10px', border: '1px solid #aaa', boxShadow: '5px 5px 5px #aaa', marginBottom: '20px'}}
          onChange={e => this.handleMailMessageChange(e)} 
        />
        <br/>
        <br/><br/>
      
       <div align="center"><Button variant="outlined" disabled = { this.state.status } onClick= {() => this.submitRequest()} style={{ backgroundColor:"#ffefa4", width: "25%" }}>Send</Button></div>
           </form>
   <br/>
      <br/>

      <div>
      <Dialog
        open={this.state.open}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Title Length Should Be Between 10 and 200 Characters Including Spaces,<br/>Message Length Should Be Between 10 and 2000 Characters Including Spaces 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>


    <div>
      <Dialog
        open={this.state.mailsent}
        onClose={this.handleCloseSentMessageDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Message"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Messaging Status: {this.state.messagingstatus} 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseSentMessageDialog} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>

</div>
 {(this.state.con!==true) ? (<Typography align="center" style={{color: '#ff0000'}}>Internet Or Data Not Available</Typography>) : ""}
<div align="left">
 
{ ((this.state.data) && (this.state.data.message) && (this.state.data.message ==="success")) ? <Typography align="center" className="typography textstyle">Mail Sent</Typography>:""}
{ ((this.state.data) && (this.state.data.message) && (this.state.data.message ==="failure")) ? <Typography align="center" className="typography textstyle">Unable to Send</Typography>:""}

{ ((this.state.data) && (this.state.data.message) && (this.state.data.message ==="error")) ? <Typography align="center" className="typography textstyle">Problem with Input</Typography>:""}
{ ((this.state.data) && (this.state.data.message) && (this.state.data.message ==="unauthorized")) ? <Typography align="center" className="typography textstyle">Unauthorized Access</Typography>:""}

 <br/>

<Typography align="center" style={{margin: '5%'}}> Title Length Should Be Between 10 and 200 Characters Including Spaces, Message Length Should Be Between 10 and 2000 Characters Including Spaces</Typography>

 </div>
    

 { this.state.status ? <div><CircularProgress className="loader" variant='indeterminate' color='secondary' /></div>  : "" }


</div>
  );
}
} 

