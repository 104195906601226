import { getauth } from '../adapter/infoURL';

export const getAuth = async (data)=>{
var d = null;
	try {
    d = await getauth(data);
}
catch(e) {
	d = null;
}
return d;
}


