import React from 'react'
import './App.css'

import MessageWindow from './MessageWindow'
import TextBar from './TextBar'
import NameText from './NameText'
import { checkStatus } from '../adapter/checkStatus'

import { startWebsocketConnection, registerOnMessageCallback, send } from './websocket'

export class App extends React.Component {
  state = {
    messages: [],
    username: null,
    connected: true
  }

  constructor (props) {
    super(props)
    registerOnMessageCallback(this.onMessageReceived.bind(this))
    startWebsocketConnection()
  }

  onMessageReceived (msg) {
console.log(msg)
  var  mesg = JSON.parse(msg)
console.log(mesg)
    this.setState({
      messages: this.state.messages.concat(mesg)
    })
  }

  setUserName (name) {
var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/;
if(name.match(format)) {
window.alert("Only Alphabets Are Required");
return;
}

    this.setState({
      username: name
    })
  }

  sendMessage (text) {
var format = /[`^\\[\]{}()<>;'"\\|~]/;
if(text.match(format)) {
window.alert("Only Alphabets, Numerics & Mathematical Symbols Can Be Entered");
return;
}
var filtertext = ""
for( var i = 0; i < text.length; i++ )  
            if( (text[i] == '\n' || text[i] == '\r') ) 
                    filtertext += " "
else  
               filtertext += text[i];

    const message = {
      username: this.state.username,
      text: filtertext,
      user: "client",
	sendToSessionId: ""
    }
   try {
    send(JSON.stringify(message))
}
catch(exception) {
window.alert("No Internet Access");
}
  }





checkInternet = async () => {
var st = await checkStatus();
if(st == true) 
this.setState({ connected: true });
else
this.setState({ connected: false });
}


componentDidMount() {
  this.checkInternet();
}












  render () {
    const setUserName = this.setUserName.bind(this)
    const sendMessage = this.sendMessage.bind(this)

if( this.state.connected == true) {
    if (this.state.username === null) {
      return (
        <div className='container'>
<table style={{width: '100%'}}><tr><td>
          <div className='container-title'>Enter Name</div></td></tr>
          <tr><td><div className='container-nametext'><NameText onSend={setUserName} /></div></td></tr></table>
        </div>
      )
    }
    return (
      <div className='container'>
<div id="usermessages">
<div className='container-title'>Messages</div>
	<div className='container-msgwindow'><MessageWindow messages={this.state.messages} username={this.state.username} /></div>
<div className='container-msgtext'><TextBar onSend={sendMessage} /></div>
	</div>
      </div>
    )
  }

else {
return ( <div  style={{color: 'gray', align: 'center', textAlign: 'center' }}><i>Please Check Internet Connection !</i></div>)
}
}
}

export default App
