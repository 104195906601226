import React, { Component } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import '../styles/formstyles.css';
// import Select from '@material-ui/core/Select';
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
import { getLocalData } from '../adapter/UserInfo';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { getChangePasswordResponse } from '../adapter/infoURL';

// import Input from '@material-ui/core/Input';
// import InputAdornment from '@material-ui/core/InputAdornment';
// import IconButton from '@material-ui/core/IconButton';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import  Icon from '@material-ui/core/Icon';
import  VisibilityOff from '@material-ui/icons/VisibilityOff';
import  Visibility from '@material-ui/icons/Visibility';

 import { removeLocalData } from '../adapter/UserInfo';
import ReactDOM from 'react-dom';
// import  StudentAuth from '../studentauth/checkAuth';
import Login from '../login/Login';

// import { checkStatus } from '../adapter/checkStatus';
import '../common/style.css';
// import { Redirect } from 'react-router-dom'; 


export default class ChangePassword extends Component {

     getDate = () => {
      var date = new Date();
      var month = date.getMonth();
      var day = date.getDate();
  var m = 1;
      ((month+1) < 10) ? m="0"+(month+1) : m=(month+1);
      if(day < 10)  day="0"+(day);
      let dt = (date.getYear()+1900)+"-"+m+"-"+day;
      return dt;
    }

viewiconposition = { position: 'relative', left: '-21%', float:'right', color: '#999', marginTop: '3%',}


  getLocalStorage = () => {
  let user = getLocalData();
 // console.log(user.data);
   return user.data;
} 


  state={
    status: false,
    data: '',
    title: '',
    password: '',
    newPassword: '',
    reNewPassword: '',	
    con: true,
    inforeceived: false,
    messagingstatus: '',
	chkpwd: false,
hidden: true,
hiddenCurrentPassword: true,
hiddenNewPassword: true,
hiddenReNewPassword: true,
reset: false,

   }

toggleCurrentPassword = () => {
this.setState({hiddenCurrentPassword: !this.state.hiddenCurrentPassword});
}

toggleNewPassword = () => {
this.setState({hiddenNewPassword: !this.state.hiddenNewPassword});
}

toggleReNewPassword = () => {
this.setState({hiddenReNewPassword: !this.state.hiddenReNewPassword});
}



toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

logout() {
if(this.state.reset===true) {
    if(removeLocalData()){
this.props.history.push("/");
const root = document.getElementById('root');
ReactDOM.render(<Login />, root);
}
}
}



handleInfoReceived = () => {
this.setState({ inforeceived: false });
this.logout();
}

handlePasswordChange = event => { 
  this.setState({ password: event.target.value })
}
handleNewPasswordChange = event => {
 this.setState({ newPassword: event.target.value })
}

handleReNewPasswordChange = event => {
 this.setState({ reNewPassword: event.target.value })
}

handleCloseChkpwdDialog = () => this.setState({chkpwd: false});


submitRequest = async () => {
this.setState({data: ''});
this.setState({reset: false});
this.setState({status: true});
  this.indata = {
    "currentPassword":`${this.state.password}`,
    "newPassword":`${this.state.newPassword}`,
    "reNewPassword": `${this.state.reNewPassword}`
}

var regexp = /^(?=.*[A-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$!#*^@])(?!.*[iIoO])\S{6,12}$/;

if(this.indata.currentPassword.length > 1 &&  this.indata.newPassword.length >=6 && this.indata.newPassword.length <=12 && this.indata.newPassword === this.indata.reNewPassword && regexp.test(this.indata.newPassword))
{
this.setState({chkpwd: false});
}
else {
this.setState({chkpwd: true});
this.setState({status: false});
return;
}

// console.log(this.indata);
// this.setState({listener: ""})
// this.setState({status: true});
let info = await getChangePasswordResponse(this.indata);
// console.log("\n\n\nREspnse Data\n\n\n", info);
if(info!==null && info.data && info.data.message) {
 this.setState({data: info.data}); 
 this.setState({con: true}); 
// console.log(info.data);
     if(this.state.data.message==="success") {
    this.setState({ password: '' });
   this.setState({ newPassword: '' });
   this.setState({ reNewPassword: '' });
  this.setState({ messagingstatus:  "Password Changed Successfully"}); 
this.setState({reset: true});
  }
else
this.setState({ messagingstatus: info.data.message}); 
this.setState({inforeceived: true});
}
else {
this.setState({ messagingstatus: "Unauthorised"}); 
if(info==null)
 this.setState({con: false});  
}
// console.log(info.data);
this.setState({status: false});
}


render() {
//let i=0;

return(
<div align="center" className="container">
<div className="maintitle">
<br/><br/>
    <form autoComplete="off">
<Typography className="formtitle" variant="h5" align="center">Change Password</Typography>
<br/>

<br/>

<div>
<TextField
          label="Current Password"
          type={this.state.hiddenCurrentPassword ? "password": "text"}
          name="oldpassword"
          style={{width:'50%'}}
          value={this.state.password}
          
          data-validators="isRequired"
          onChange={e => this.handlePasswordChange(e)}
	
        />
{(this.state.hiddenCurrentPassword) ? (<VisibilityOff onClick= {() => this.toggleCurrentPassword()} style={this.viewiconposition} /> 
) : (<Visibility onClick= {() => this.toggleCurrentPassword()} style={this.viewiconposition} />) }
</div>
 
<br/>
<div>
        <TextField
          label="New Password"
          type={this.state.hiddenNewPassword ? "password": "text"}
          name="newpassword"
          style={{width:'50%'}}
          value={this.state.newPassword}
        
  data-validators="isRequired"
          onChange={e => this.handleNewPasswordChange(e)}
        />
{(this.state.hiddenNewPassword) ? (<VisibilityOff onClick= {() => this.toggleNewPassword()} style={this.viewiconposition} /> 
) : (<Visibility onClick= {() => this.toggleNewPassword()} style={this.viewiconposition} />) }
</div>
 
<br/>
<div>
 <TextField
          label="Retype New Password"
          type={this.state.hiddenReNewPassword ? "password": "text"}
          name="renewpassword"
          
	style={{width:'50%'}}
          value={this.state.reNewPassword}
          data-validators="isRequired"
          onChange={e => this.handleReNewPasswordChange(e)}
        />
{(this.state.hiddenReNewPassword) ? (<VisibilityOff onClick= {() => this.toggleReNewPassword()} style={this.viewiconposition} /> 
) : (<Visibility onClick= {() => this.toggleReNewPassword()} style={this.viewiconposition} />) }
</div>
        <br/>
        <br/><br/>

       <div align="center"><Button variant="outlined" disabled = { false } style = {{ backgroundColor:"#ffefa4", width: "25%" }} onClick= {() => this.submitRequest()} >Change</Button></div>
           </form>
   <br/>
      <br/>

      <div>
      <Dialog
        open={this.state.chkpwd}
        onClose={this.handleCloseChkpwdDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Password Should Contain Atleast 1 Upper Case Character, 1 Lower Case Character, 1 digit, 1 Special Character ($!#*@) & Length Should Be Between 6 to 12 Characters, New Password & Retype New Password Should Match 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseChkpwdDialog} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>


    <div>
      <Dialog
        open={this.state.inforeceived}
        onClose={this.handleInfoReceived}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Message"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {this.state.messagingstatus} 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleInfoReceived} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>


</div>
 {(this.state.con!==true) ? (<Typography align="center" style={{color: '#ff0000'}}>Internet Or Data Not Available</Typography>) : ""}
<div align="left">
 
{ ((this.state.data) && (this.state.data.message) && (this.state.data.message ==="success")) ? <Typography align="center" className="typography textstyle">Password Changed Sucessfully</Typography>:""}
{ ((this.state.data) && (this.state.data.message) && (this.state.data.message ==="failure")) ? <Typography align="center" className="typography textstyle">Sorry! Unauthorised Access</Typography>:""}

{ ((this.state.data) && (this.state.data.message) && (this.state.data.message ==="error")) ? <Typography align="center" className="typography textstyle">Problem with Input</Typography>:""}
{ ((this.state.data) && (this.state.data.message) && (this.state.data.message ==="unauthorized")) ? <Typography align="center" className="typography textstyle">Unauthorized Access</Typography>:""}

 <br/>
<Typography align="center" style={{margin: '5%'}}>Password Length Should Be Between 6 to 12 Characters & It Should Contain Digits, Characters & Special Characters ($!#*@) </Typography>
 </div>
    

 { this.state.status ? <div><CircularProgress className="loader" variant='indeterminate' color='secondary' /></div>  : "" }


</div>
  );
}
} 

