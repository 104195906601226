import React, { Component } from 'react';
import { getAttendanceCount } from './AttendanceAction';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../styles/formstyles.css';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { getLocalData } from '../adapter/UserInfo';
import Typography from '@material-ui/core/Typography';
// import { checkStatus } from '../adapter/checkStatus';
import '../common/style.css';

import { Doughnut } from "react-chartjs-2";
import { MDBContainer } from "mdbreact";


export default class AttendanceTillDate extends Component {

     getDate = () => {
      var date = new Date();
      var month = date.getMonth();
      var day = date.getDate();
	var m = 1;
      ((month+1) < 10) ? m="0"+(month+1) : m=(month+1);
      if(day < 10)  day="0"+(day);
      let dt = (date.getYear()+1900)+"-"+m+"-"+day;
      return dt;
    }

    getDateFrom() {
      var date = new Date();
      var month = date.getMonth();
      var day = date.getDate();
      var m = 1;
      ((month+1) < 10) ? m="0"+(month+1) : m=(month+1);
      if(day < 10) day="0"+(day);
      let dt = ((date.getYear()+1900)-5)+"-"+m+"-"+day;
      return dt;


    }

  getLocalStorage = () => {
  let user = getLocalData();
  //console.log(user);
   return user.data;
} 

getSubjects = () => {
  let subjects = [];
      let data = this.getLocalStorage();
      if(data && data.studentDetail && data.studentDetail.subjects && data.studentDetail.subjects.length > 0)
    subjects = data.studentDetail.subjects;
  return subjects;
}



  state={
    status: false,
    open: false,
    data: '',
    subjectId: 0,
    // subjectId: 0,// `${this.getSubjects()[0].subjectId}`,
    con: true,

 dataPolar: {
      datasets: [
        {
          data: [],
          backgroundColor: [
            "rgba(247, 70, 74, 0.5)",
            "rgba(0, 255, 0, 0.5)",
            "rgba(200, 200, 200, 0.5)",
          ],
          label: "My dataset" // for legend
        }
      ],
      labels: ["Percentage", "Short of 80%", "Full"]
    }

   }



getStudentSessionId = () => {
let data = this.getLocalStorage();
      return data.studentDetail.studentSessionId;
}

getStudentId = ()  => {
let data = this.getLocalStorage();
      return data.studentDetail.studentId;
}

getSessionIdentifier = ()  => {
let data = this.getLocalStorage();
      return data.studentDetail.sessionIdentifier;
}




submitRequest = async (subjectId) => {
  this.indata = {
  "studentId": `${this.getStudentId()}`,
"studentSessionId":`${this.getStudentSessionId()}`,
"sessionIdentifier": `${this.getSessionIdentifier()}`,
"subjectId": `${subjectId}`,
"facultyLoadId": "0",
"alliedSubjectId":"0",
"dateFrom":`${this.getDateFrom()}`,
"dateTo": `${this.getDate()}`,
}
this.setState({listener: ""})
this.setState({status: true});
let info = await getAttendanceCount(this.indata);
// console.log(info.data);
if(info!==null && info.data && info.data.studentTotalPresent.length >= 0 && info.data.studentTotalAbsent >= 0) {
 this.setState({data: info.data}); 
this.setState({con: true});  
 var percentage = ((parseFloat(info.data.studentTotalPresent) * 100) / (parseFloat(info.data.studentTotalPresent) + parseFloat(info.data.studentTotalAbsent)))
var short = 0;
var backgroundPercentage="rgba(0, 247, 0, 0.5)";
var full=100;
if(percentage < 80)  {
short = 80-percentage;
backgroundPercentage="rgba("+(255-percentage)+", "+(175+percentage)+", 0, 0.5)";
}
else {
  backgroundPercentage="rgba("+(0)+", "+(255)+", 0, 0.5)";
}
if (full > (percentage+short)) {
  full = full - (percentage+short);
}
else {
  full=0;
}
var datasets = {...this.state.dataPolar.datasets}

datasets[0].data = [percentage, short, full];
datasets[0].backgroundColor[0]=backgroundPercentage;
this.setState({datasets})

}
else {
this.setState({con: false});  
}
// console.log(info.data);
this.setState({status: false});



}




handleSubjectChange = (event) => {
     this.setState({subjectId: event.target.value});
     this.submitRequest(event.target.value);
    }
  

componentDidMount() {
  if(this.getSubjects().length > 0){
    this.setState({subjectId: this.getSubjects()[0].subjectId}); 
  
  this.submitRequest(this.getSubjects()[0].subjectId);
}
}

getSubject() {

  for(let i=0;i<this.getSubjects().length;i++) {
    if(this.getSubjects()[i].subjectId===this.state.subjectId) 
      return this.getSubjects()[i].subjectName.toUpperCase();
  }
} 

render() {
//let i=0;


return(
<div align="center" className="container">
<div className="maintitle">
<h3>Subject: {this.getSubject()}</h3>
</div>
 {(this.state.con===false) ? (<Typography align="center" style={{color: '#ff0000'}}>Internet Or Data Not Available</Typography>) : ""}
{ ((this.state.data) && (this.state.data.studentTotalPresent === "") && (this.state.data.studentTotalAbsent === ""))?<Typography align="center">No Attendance Available</Typography>:""}

<div align="left">

 
{ ((this.state.data) && (this.state.data.studentTotalPresent) && (this.state.data.studentTotalPresent !== "")) && ((this.state.data.studentTotalAbsent) && (this.state.data.studentTotalAbsent !== ""))? (<Typography className="typography textstyle" style={{borderRadius: '10px', border: '1px solid #aaa', boxShadow: '5px 5px 5px #aaa', marginBottom: '20px'}}>Total Attendance: {parseFloat(this.state.data.studentTotalPresent) + parseFloat(this.state.data.studentTotalAbsent)}</Typography>):(<Typography className="typography textstyle" style={{borderRadius: '10px', border: '1px solid #aaa', boxShadow: '5px 5px 5px #aaa', marginBottom: '20px'}}>Total Attendance:</Typography>)}
{ ((this.state.data) && (this.state.data.studentTotalPresent) && (this.state.data.studentTotalPresent !== "")) ? (<Typography className="typography textstyle" style={{borderRadius: '10px', border: '1px solid #aaa', boxShadow: '5px 5px 5px #aaa', marginBottom: '20px'}}>Total Present:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.data.studentTotalPresent}</Typography>):(<Typography className="typography textstyle" style={{borderRadius: '10px', border: '1px solid #aaa', boxShadow: '5px 5px 5px #aaa', marginBottom: '20px'}}>Total Present:</Typography>)}
{ ((this.state.data) && (this.state.data.studentTotalAbsent) && (this.state.data.studentTotalAbsent !== "")) ? (<Typography className="typography textstyle" style={{borderRadius: '10px', border: '1px solid #aaa', boxShadow: '5px 5px 5px #aaa', marginBottom: '20px'}}>Total Absent:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.data.studentTotalAbsent}</Typography>):(<Typography className="typography textstyle" style={{borderRadius: '10px', border: '1px solid #aaa', boxShadow: '5px 5px 5px #aaa', marginBottom: '20px'}}>Total Absent:</Typography>)}
{ ((this.state.data) && (this.state.data.studentTotalPresent) && (this.state.data.studentTotalPresent !== "") && (this.state.data.studentTotalPresent !== "0")) && ((this.state.data.studentTotalAbsent) && (this.state.data.studentTotalAbsent !== "")) ? (<Typography className="typography textstyle" style={{borderRadius: '10px', border: '1px solid #aaa', boxShadow: '5px 5px 5px #aaa', marginBottom: '20px'}}>Percentage:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{((parseFloat(this.state.data.studentTotalPresent) * 100) / (parseFloat(this.state.data.studentTotalPresent) + parseFloat(this.state.data.studentTotalAbsent))).toFixed(2)}</Typography>):(<Typography className="typography textstyle" style={{borderRadius: '10px', border: '1px solid #aaa', boxShadow: '5px 5px 5px #aaa', marginBottom: '20px'}}>Percentage:</Typography>)}
{ ((this.state.data) && (this.state.data.studentTotalPresent) && (this.state.data.studentTotalPresent !== "") && (this.state.data.studentTotalPresent !== "0")) && ((this.state.data.studentTotalAbsent) && (this.state.data.studentTotalAbsent !== "")) ? (<MDBContainer><Doughnut data={this.state.dataPolar} options={{ responsive: true }} /></MDBContainer>):("")}

{ ((this.state.data) && (this.state.data.message) && (this.state.data.message ==="null")) ? <Typography align="center" className="typography textstyle">No Lectures</Typography>:""}
{ ((this.state.data) && (this.state.data.message) && (this.state.data.message ==="error")) ? <Typography align="center" className="typography textstyle">Problem with Input</Typography>:""}
{ ((this.state.data) && (this.state.data.message) && (this.state.data.message ==="unauthorized")) ? <Typography align="center" className="typography textstyle">Unauthorized Access</Typography>:""}

 <br/>
 </div>


 <InputLabel> Select Subject</InputLabel><br/><br/>
        <Select onChange={e => this.handleSubjectChange(e)} className="compwidth" value={this.state.subjectId}>
          
          {this.getSubjects().map((subject, index) => (<MenuItem key={index+1} value={subject.subjectId}>{subject.subjectName}</MenuItem>))};
          
        </Select>

 { this.state.status ? <div><CircularProgress className="loader" variant='indeterminate' color='secondary' /></div>  : "" }

<div className="mode">{this.state.listener}</div>
</div>
  );
}
} 

