import React, { Component } from 'react';
// import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { getLocalData } from '../adapter/UserInfo';
import { checkStatus } from '../adapter/checkStatus';

import '../common/style.css';

const styles = theme => ({
  
});

export class Location extends Component {
 
constructor(props) {
    super(props);
    this.state = {
      loading: true,
      connected: true,
      pdffile: "",
    };
   }

  
hideSpinner = () => {
    this.setState({loading: false});
  }


checkFileExists = (path) => {
console.log(path);
  try {
//console.log("dhjgfgdghjdgfhjdg fjhgfhj");
// console.log("dhjgfgdghjdgfhjdg fjhgfhj", require(path));
console.log(require(`${path}`).getName());
if(require(`${path}`)==true) {
console.log("true");
}
else {
console.log("false");
}
if(require(`${path}`))
return (`${path}`);
else 
return null;
  } catch (err) {
   return err;
  }
};



 /*
checkFileExists = () => {
var info = getLocalData();
var prof = info.data.studentDetail.prof;
var course = (info.data.studentDetail.course).toLowerCase();
var pathurl = "https://www.sgrduhs.in/webclassschedule/"+course+"/"+prof+"/"+course+prof+".pdf";
HttpWebResponse response = null;
var request = (HttpWebRequest)WebRequest.Create(pathurl);
request.Method = "HEAD";

try
{
    response = (HttpWebResponse)request.GetResponse();
   return pathurl;
console.log(pathurl);
}
catch (WebException ex)
{
 return null;
    
}
finally
{
   // Don't forget to close your response.
    if (response != null)
    {
        response.Close();
    }
}
};

*/


checkInternet = async () => {
var st = await checkStatus();
if(st == true) 
this.setState({ connected: true });
else
this.setState({ connected: false });
}


componentDidMount() {
  this.checkInternet();
}


render() {

    return (
      <div className="container" >
<div>{ ( this.state.connected == true ) ? (<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3396.9669035576894!2d74.96636681498451!3d31.634756548650536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39197d55e4c09dab%3A0xf81bbdbd2476aff!2sSRI%20GURU%20RAM%20DAS%20UNIVERSITY%20OF%20HEALTH%20SCIENCES%2C%20SRI%20AMRITSAR!5e0!3m2!1sen!2sin!4v1653472833990!5m2!1sen!2sin" loading="lazy" style={{width: '100vw', height: '90.9vh'}}  referrerpolicy="no-referrer-when-downgrade"></iframe>) : (<div  style={{color: 'gray', align: 'center', textAlign: 'center' }}><i>Please Check Internet Connection !</i></div>) } </div>


{/*


            { (getPDFFile(`${pathurl}`)==null) ? <div style={{color: 'red', textAlign: 'center'}}>{`${pathurl}`}</div> : <div style={{overflow:'scroll', height:600}}><MobilePDFReader url={`${pathurl}`} /></div>}
       

            { (this.checkFileExists(`${pathurl}`)==null) ? <div style={{color: 'red', textAlign: 'center'}}>{`${pathurl}`}</div> : <div style={{overflow:'scroll', height:600}}><MobilePDFReader url={`${pathurl}`} /></div>}

            { (this.checkFileExists()==null) ? <div style={{color: 'red', textAlign: 'center'}}>Not Available</div> : <div style={{overflow:'scroll', height:600}}><MobilePDFReader url={this.checkFileExists()} /></div>}

     */}

       
   </div>

      );
  }
}

export default withStyles(styles, { withTheme: true })(Location);
