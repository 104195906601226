import React from "react";
// import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import Typography from "@material-ui/core/Typography";
import './DataTable.css';


function DataTable2({ title, data }) {
//  console.log("DataTable:", data)
  // const headers = Object.keys(data[0]);
const headers=[["conductDate","Date"],["marks","Marks"],["maxMarks","mm"],["type","Type"],["alliesSubject","Allied"]];

  return (
    <div>
      <Table className="tbody">
        <TableHead className="tablehead">
          <TableRow>
            {headers.map(header => ( (header[1]!=="mm") ?
              <TableCell size="small" className="tablecell-title">{header[1]}</TableCell>:"" 
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((emp, index) => (
            <TableRow key={index}  className="tablerow">
              {headers.map(header => ( (header[0]==="marks") ? 
                <TableCell size="small" className="tablecell">{emp[header[0]]}/{emp["maxMarks"]}</TableCell> : (header[0]==="maxMarks") ? "" : <TableCell>{emp[header[0]]}</TableCell> 
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

DataTable2.defaultProps = {
  title: ""
};

export default DataTable2;
