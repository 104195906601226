import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getLocalData } from '../adapter/UserInfo';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import  Adapter  from '../adapter/adapter';
import { getFeeDetails } from '../adapter/infoURL';
// import { checkStatus } from '../status/Status';
// import { checkStatus } from '../adapter/checkStatus';
import '../common/style.css';

const styles = theme => ({
  
});

export class feepayment extends Component {

  state = {
     
    }

getDate = () => {
      var date = new Date();
      var month = date.getMonth();
      var day = date.getDate();
      (month < 10) ? month="0"+(month+1) : month=(month+1);
      if(day < 10)  day="0"+(day);
      let dt = (date.getYear()+1900)+"-"+month+"-"+day;
      return dt;
    }

    state={
    status: false,
    open: false,
    data: '',
    date: `${this.getDate()}`,
    con: true,
    buttonisClicked: false,
    paybutton: true,
 
 loading: true,
 connected: true
   }

     getLocalStorage = () => {
  let user = getLocalData();
   return user.data;
} 


getStudentSessionId = () => {
let data = this.getLocalStorage();
      return data.studentDetail.studentSessionId;
}

getStudentId = ()  => {
let data = this.getLocalStorage();
// console.log(data.studentDetail.studentId);
// console.log(this.getStudentSessionId());
      return data.studentDetail.studentId;
}

getSessionIdentifier = ()  => {
let data = this.getLocalStorage();
      return data.studentDetail.sessionIdentifier;
}

async getInfo()  {
    this.indata = {
  "studentId": `${this.getStudentId()}`,
"studentSessionId":`${this.getStudentSessionId()}`,
"sessionIdentifier": `${this.getSessionIdentifier()}`,
"dateTo": `${this.getDate()}`,
}
let info = await getFeeDetails(this.indata);
if(info!=null && info.data) {
  this.setState({data: info.data}); 
 this.setState({con: true}); 
console.log(this.state.data);
}
else {
this.setState({con: false});  
}
}
    
hideSpinner = () => {
    this.setState({loading: false});
  }

handleError = () => {
    this.setState({connected: false});
  }


render() {
  //  const info = getLocalData();
   this.getInfo();
    // console.log(info);
    // console.log(info.data.studentDetail.course.substring(0,2));

    // let url="https://www.sgrduhs.in/page-fee_payment.html";

    /*
    if(info.data.studentDetail.course.substring(0,2).toUpperCase()==="MB" || info.data.studentDetail.course.substring(0,2).toUpperCase()==="MD" || info.data.studentDetail.course.substring(0,2).toUpperCase()==="MS") {
    url="https://www.sgrduhs.in/student/index.php";
    }
    else if(info.data.studentDetail.course.substring(0,2).toUpperCase()==="NU") {
        url="http://eps.eshiksa.net/DirectFeesv3/SGRD";
    }
    else if(info.data.studentDetail.course.substring(0,2).toUpperCase()==="PA") {
        url="https://www.onlinesbi.com/sbicollect/icollecthome.htm?corpID=957770";
    }
    else {
        url="https://www.sgrduhs.in/page-fee_payment.html";
    }

    */
    // console.log(info);
    let url = Adapter.getBaseURL()+"/payfeeonline.jsp?sdata=";

    if( (this.state.data.length>0) && (this.state.data[0]) && (this.state.data[0].sid) && (this.state.data[0].sid!=null)) {
    url = Adapter.getBaseURL()+"/payfeeonline.jsp?sdata="+this.state.data[0].sid;
  }
    // url+="/index.jsp";
        
    const width = window.innerWidth; // - ((window.innerWidth*10)/100);
    return (
      <div className="container" >
        {((this.state.loading) && (this.state.connected)) ? (<div align="center"><CircularProgress className="loader" variant='indeterminate' color='secondary' /></div>) : ""}
        {((this.state.connected)) ? (<iframe
          src={url} 
          width= {width}
          height= {window.innerHeight+"px"}
          onLoad={this.hideSpinner}
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          title="news"
        />): (<div align="center"><Typography>Internet Or Data Not Available</Typography></div>)}

      </div>

      );
  }
}

export default withStyles(styles, { withTheme: true })(feepayment);
