import React, { Component } from 'react';
import DataTable2 from '../components/DataTable2';
import { getLocalData } from '../adapter/UserInfo';
import { getTestMarks } from '../adapter/infoURL';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../styles/formstyles.css';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import '../common/style.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
// import { checkStatus } from '../status/Status';
// import { checkStatus } from '../adapter/checkStatus';

export default class Marks extends Component {

  getLocalStorage = () => {
  let user = getLocalData();
  return user.data;
} 

getSubjects = () => {
  let subjects = [];
      let data = this.getLocalStorage();
      if(data && data.studentDetail && data.studentDetail.subjects && data.studentDetail.subjects.length > 0)
    subjects = data.studentDetail.subjects;
  return subjects;
}



  state={
    status: false,
    open: false,
    data: [],
    subjectId: 0, // `${this.getSubjects()[0].subjectId}`,
    listener: "",
    con: true,
    connected: false
   }

getStudentSessionId = () => {
let data = this.getLocalStorage();
      return data.studentDetail.studentSessionId;
}

getStudentId = ()  => {
let data = this.getLocalStorage();
      return data.studentDetail.studentId;
}


submitRequest = async (subjectId) => {
this.indata = {
"studentId": `${this.getStudentId()}`,
"studentSessionId": `${this.getStudentSessionId()}`,
"subjectId": `${subjectId}`
}

/*
this.indata = {
"studentId": "6",
"studentSessionId":"35",
"subjectId": "1"
}
*/

this.setState({status: true});
this.setState({listener: ""});
const info = await getTestMarks(this.indata);
if(info!=null) {
this.setState({data: info.data});
this.setState({con: true});
}
else {
this.setState({con: false});
this.setState({data: []});
}
this.setState({status: false});
}


handleSubjectChange = (event) => {
     this.setState({subjectId: event.target.value});
     this.submitRequest(event.target.value);
    }
  
componentDidMount() {
  if(this.getSubjects().length > 0){
    this.setState({subjectId: this.getSubjects()[0].subjectId}); 
  this.submitRequest(this.getSubjects()[0].subjectId);
}
}

getSubject() {

  for(let i=0;i<this.getSubjects().length;i++) {
    if(this.getSubjects()[i].subjectId===this.state.subjectId) 
      return this.getSubjects()[i].subjectName.toUpperCase();
  }
} 

handleClose = () => this.setState({ open: false });

render() {


return(
<div className="container">
<div align="center" className="titlewidth">

<h3>Subject: {this.getSubject()}  </h3>
 </div>
 <div>
 {(this.state.con!==true) ? (<Typography style={{color: '#ff0000'}} align="center">Internet Or Data Not Available<br/></Typography>) : ""}
 { 
  (this.state.status) ? 
  (<div align="center" ><CircularProgress className='loader' variant='indeterminate' color='secondary' /></div> )
  : (
  ((this.state.data) && (this.state.data.length > 0)) ? (<DataTable2 title={"Marks"} data={this.state.data} className="marksdatatable" /> ) 
  : (
  ((this.state.data) && (this.state.data.message) && (this.state.data.message ==="null")) ? <Typography align="center" className="typography">No Data Available</Typography> 
  : (
  ((this.state.data) && (this.state.data.message) && (this.state.data.message ==="error")) ? <Typography align="center" className="typography">Problem with Input</Typography> 
  : (
  ((this.state.data) && (this.state.data.message) && (this.state.data.message ==="unauthorized")) ? <Typography align="center" className="typography">Unauthorized Access</Typography>
  : (
  <div>
      <Dialog
      open={this.state.open}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Message"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Internet Or Data Not Available
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>

)
)
)
)
)

}
</div>

<br/>
<br/>

<div align="center">
 <InputLabel align="center" className="padding">Select Subject</InputLabel><br/><br/>
        <Select onChange={e => this.handleSubjectChange(e)} className="compwidth" value={this.state.subjectId}>
          {this.getSubjects().map((subject, index) => (<MenuItem key={index+1} value={subject.subjectId}>{subject.subjectName}</MenuItem>))};
          
        </Select>

</div>
<div className="mode">{this.state.listener}</div>    
</div>

  );

}

} 

