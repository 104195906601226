import React from 'react';
import PropTypes from 'prop-types';
// import { Redirect } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
import HomeIcon from '@material-ui/icons/Home';
// import AccessibleIcon from '@material-ui/icons/Accessible';
// import GradeIcon from '@material-ui/icons/Grade';
// import LocalOfferIcon from '@material-ui/icons/LocalOffer';
// import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
// import VpnKeyIcon from '@material-ui/icons/VpnKey';
// import PaymentIcon from '@material-ui/icons/Payment';
import EmailIcon from '@material-ui/icons/Email';
// import ScheduleIcon from '@material-ui/icons/Schedule';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
// import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import MicIcon from '@material-ui/icons/Mic';
// import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import ForumIcon from '@material-ui/icons/Forum';
import EventIcon from '@material-ui/icons/Event';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import PersonPinIcon from '@material-ui/icons/PersonPin';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
// import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ListItemText from '@material-ui/core/ListItemText';
// import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';
 import { HomeVisitor } from '../mainvisitor/homeVisitor';
 import { About } from '../about/about';
import { Location } from '../univlocation/Location';
 // import { News } from '../news/News';

 import Management from '../organization/management';
 import Leadership from '../organization/leadership';
 import Testimonial from '../testimonial/testimonial';
 import Events from '../events/Events';
 import Tieups from '../tieups/Tieups';
 import Contact from '../contactus/Contact';
 import Login from '../login/Login';
import App from '../chat/App';

import Notfound from '../notfound/Notfound'; 
import Grid from '@material-ui/core/Grid';
import SvgIcon from '@material-ui/core/SvgIcon';
import Avatar from '@material-ui/core/Avatar';
// import ReactDOM from 'react-dom';
import '../common/style.css';
// import logo from '../main/utils/big-logo.png';

// import MoreLessExample from '../examples/MoreLessExample';
// import MLExample from '../examples/MLExample';
// import biglogo from './utils/big-logo.png'

// import ReactDOM, { BrowserHistory } from 'react-dom';
const drawerWidth = 260;


const styles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
   appBar: {
    position: 'fixed',
    marginLeft: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,

  },
  content: {
    flexGrow: 1,
    width: '100&',
    overflow: 'auto',
    
 //   padding: theme.spacing.unit * 3,
  },

  link: {
    textDecoration: 'none',

  },

  textstyle: {
fontSize: 15,
marginLeft: 10,
  },

title: {
  fontSize: 20,
  textAlign: 'center',
  marginTop: 5,
},

bigAvatar: {
    width: 150,
    height: 150,
    margin: '0 auto',
    marginTop: 5,
  },

iconHover: {
fontSize: 30, 
float: 'right', 
marginTop: -5,
marginRight: 20,
color: '#fff',
[theme.breakpoints.up('sm')]: {
      display: 'none',
    }, 
}
 
});



function HomeIconh(props) {
  return (
    <SvgIcon {...props}>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </SvgIcon>
  );
}



class MainVisitor extends React.Component {
 constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.routeChange = this.routeChange.bind(this);
  }




  routeChange() {
    let path = `/`;
    this.props.history.push(path);
  }




  


  handleClick() {
   console.log('\n\n\nClick happened\n\n\n');
  }

  state = {
    mobileOpen: false,
  };

  handleDrawer = () => {
    this.setState(state => ({ mobileOpen: false }));
  };


  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  render() {

  // var base = "/ss";

const { classes, theme } = this.props;
 


// console.log(info);
 
     const drawer = (
      <div>
     <Typography style={{color: "white"}} className={classes.title} >SGRD University</Typography>   
   <div align="center" className="biglogo" ></div>
     <Typography className={classes.title} >SGRD University</Typography>   
     <Divider />
    
     
<Divider />
<List>

<Link to={'/homevisitor'} className={classes.link} onClick={this.handleDrawer}>
<ListItem button key="1">
<ListItemIcon><HomeIcon /></ListItemIcon>
<ListItemText primary="Home" ></ListItemText>
</ListItem>
</Link>

<Link to={'/management'} className={classes.link} onClick={this.handleDrawer}>
<ListItem button key="1">
<ListItemIcon><PeopleOutlineIcon /></ListItemIcon>
<ListItemText primary="Management" ></ListItemText>
</ListItem>
</Link>

<Link to={'/leadership'} className={classes.link} onClick={this.handleDrawer}>
<ListItem button key="1">
<ListItemIcon><MicIcon /></ListItemIcon>
<ListItemText primary="Leadership" ></ListItemText>
</ListItem>
</Link>

{/*
<Link to={'/tieups'} className={classes.link} onClick={this.handleDrawer}>
<ListItem button key="2">
<ListItemIcon><ForumIcon /></ListItemIcon>
<ListItemText primary="Tieups" ></ListItemText>
</ListItem>
</Link>
*/}


<Link to={'/contact'} className={classes.link} onClick={this.handleDrawer}>
<ListItem button key="1">
<ListItemIcon><EmailIcon /></ListItemIcon>
<ListItemText primary="Contact Us" ></ListItemText>
</ListItem>
</Link>


<Link to={'/chat'} className={classes.link} onClick={this.handleDrawer}>
<ListItem button key="1">
<ListItemIcon><EmailIcon /></ListItemIcon>
<ListItemText primary="Queries" ></ListItemText>
</ListItem>
</Link>



<Link to={'/testimonial'} className={classes.link} onClick={this.handleDrawer}>
<ListItem button key="3">
<ListItemIcon>< PermIdentityIcon /></ListItemIcon>
<ListItemText primary="Testimonial" ></ListItemText>
</ListItem>
</Link>

{/*
<Link to={'/events'} className={classes.link} onClick={this.handleDrawer}>
<ListItem button key="4">
<ListItemIcon><EventIcon /></ListItemIcon>
<ListItemText primary="Events" ></ListItemText>
</ListItem>
</Link>
*/}


<Link to={'/location'}  className={classes.link} onClick={this.handleDrawer}>
<ListItem button key="5">
<ListItemIcon><PersonPinIcon/></ListItemIcon>
<ListItemText primary="Location" ></ListItemText>
</ListItem>
</Link>





<Link to={'/login'}  className={classes.link} onClick={this.handleDrawer}>
<ListItem button key="6">
<ListItemIcon><PersonPinIcon/></ListItemIcon>
<ListItemText primary="Login" ></ListItemText>
</ListItem>
</Link>
</List>
      </div>
    );

    return (

<Router>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed"  style={{backgroundImage: "linear-gradient(to right, #113653, #7186a3)", paddingTop: "env(safe-area-inset-top)", marginBottom: "env(safe-area-inset-top)"}} className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" noWrap >
              SGRD University
            </Typography>

        <Link to={'/'}  onClick={this.handleDrawer} style= {{ position: 'absolute', right: 0, top: 18 }} >
           <HomeIconh className={classes.iconHover} />
</Link>

    </Toolbar>
        </AppBar>
        <nav className={classes.drawer}>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer 
              container={this.props.container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main style={{paddingTop: "env(safe-area-inset-top)"}} className={classes.content}>
          <div className={classes.toolbar} />

<Grid
  container
  spacing={0}
  direction="column"
  alignItems="center"
  justify="center"
  style={{ minHeight: '50vh' }}
>

 <Switch>

              <Route exact path='/homevisitor' component={HomeVisitor} />
              
             <Route path='/location' component={Location} /> 

              <Route path='/events' component={Events} />

              <Route path='/management' component={Management} />
              <Route path='/leadership' component={Leadership} />
              <Route path='/testimonial' component={Testimonial} />

              {/* <Route path='/tieups' component={Tieups} /> */}

              <Route path='/contact' component={Contact} />
              <Route path='/chat' component={App} />

	      <Route path='/login' component={Login} />

              <Redirect from="/" to="/homevisitor" />
              
              
              <Route component={Notfound} />
                  
             
		 <Route path='/sgrduhs' component={() => {
              window.open('https://www.sgrduhs.in', '_blank', '');
              return null;
              } } />
	

              
          </Switch>
 </Grid>

        </main>
      </div>
</Router>
    );
  }
}

MainVisitor.propTypes = {
  classes: PropTypes.object.isRequired,
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(MainVisitor);
