import React from 'react';
import inWords from './RsToWords';
//import ReactDOM from 'react-dom';
// import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
// import { Table, DataTableCell, TableBody, TableCell, TableHeader } from '@david.kucsai/react-pdf-table';
// import { PDFViewer } from '@react-pdf/renderer';
// import { withStyles } from '@material-ui/core/styles';
// import { PDFViewer } from '@react-pdf/renderer';

import logo from './logo/big-logo.png';
import Page from '../components/Page';
import '../common/style.css';
import './responsive.css';
import Button from '@material-ui/core/Button';
import Pdf from "react-to-pdf";

 // import "./styles.css";


const ref = React.createRef();

const PayByChallan = ({id, data}) =>  {
  return (
    <div style={{left: "-20mm", top: "-0mm" }} className="App">

      <Pdf targetRef={ref} filename="challan.pdf" x="-20" y="0">
        {({ toPdf }) =><Button  id="printbtn" style={{border: '1px solid #aaa', left:"25%", display: "none" }} onClick={toPdf}>Download</Button>}
      </Pdf>

      <div ref={ref} style={{position: "relative", width:"210mm", height:"297mm", fontSize: "12px" }}>
        <div className="challan">

<div style={{height: "30px"}}></div>

<table className="topmargin"><tbody><tr><td>
<img src={ "big-logo.png" } alt="" className="logostyle" style={{width:"70px", height: "70px"}}/></td>
<td className="titlefont" style={{fontSize: "16px"}}>Sri Guru Ram Das University Of Health Sciences,  Sri Amritsar<br/>
<div style={{fontSize: '14px'}} align="center"><i>A Unit Of Sri Guru Ram Das Charitable Hospital Trust</i></div>
</td>
</tr>
</tbody></table>

<table className="feeinfo" style={{fontSize: "12px"}}>
<tbody>
<tr className="marginbetween totalinfo"><td colspan='5'><b><i>Note For Bank: Exact Challan Amount Must Be Transferred In Single Transaction,<br/>Amount Must Not Be Transferred If Challan Download Date Is Older Than Current Date.</i></b></td></tr>
<tr className="marginbetween totalinfo"><td><b>Challan Download Date:</b></td><td><b>{data.currentDate}</b></td></tr>

<tr className="marginbetween"><td>Name: </td><td>{data.name}</td></tr>
<tr className="marginbetween"><td>Roll No.: </td><td>{data.rollNo}</td></tr>
<tr className="marginbetween"><td>Contact No.: </td><td>{data.contactNo}</td></tr>
<tr className="marginbetween"><td>Description: </td><td>{data.description}</td></tr>

<tr className="marginbetween"><td>Mode of Payment: </td><td>RTGS-NEFT</td></tr>

<tr className="marginbetween"><td>Beneficiary Name: </td><td>{data.beneficiaryName}</td></tr>
<tr className="marginbetween"><td><b>Beneficiary Account No.: </b></td><td><b>{data.beneficiaryAcNo + data.id}</b></td></tr>
<tr className="marginbetween"><td>Bank Name: </td><td>{data.bankName}</td></tr>
<tr className="marginbetween"><td>Bank Branch: </td><td>{data.branchName}</td></tr>
<tr className="marginbetween"><td>Bank IFSC Code: </td><td>{data.ifscCode}</td></tr>

<tr className="marginbetween"><td>Last Date: </td><td>{data.lastDate}</td></tr>

<tr className="marginbetween totalinfo"><td><b> Total Fee: </b></td><td><b>Rs.{( +data.pendingFee > 0 && +data.transactionCount > 0)? (parseInt(data.pendingFee)+(parseInt(data.fine)*parseInt(data.daysMore))): (parseInt( parseInt(data.currentBalance)+(parseInt(data.fine)*parseInt(data.daysMore))+parseInt(data.previousBalance)+parseInt(data.otherCharges)))}</b></td></tr>
<tr className="marginbetween totalinfo"><td><b> Total Fee (In Words): </b></td><td colspan='5'><b>{inWords(( +data.pendingFee > 0 && +data.transactionCount > 0)? (parseInt(data.pendingFee)+(parseInt(data.fine)*parseInt(data.daysMore))): (parseInt( parseInt(data.currentBalance)+(parseInt(data.fine)*parseInt(data.daysMore))+parseInt(data.previousBalance)+parseInt(data.otherCharges))))}</b></td></tr>
<tr className="marginbetween totalinfo"><td colspan='5'><b> Deposit Date:</b></td></tr>
<tr className="marginbetween totalinfo"><td colspan='5'><b> Name & Seal of Bank with Signature:<br/>
</b></td></tr>

</tbody>
</table>
<p className="margin" ></p>



For any query regarding fee payment please contact 9815276200 / 8557999183 / 9877040457

<hr className="hrline"/>
<br/>
<br/>

<table className="topmargin"><tbody><tr><td>
<img src={ "big-logo.png" } alt="" className="logostyle" style={{width:"70px", height: "70px"}}/></td>
<td className="titlefont" style={{fontSize: "16px"}}>Sri Guru Ram Das University Of Health Sciences, Sri Amritsar<br/>
<div style={{fontSize: '14px'}} align="center"><i>A Unit Of Sri Guru Ram Das Charitable Hospital Trust</i></div><br/>
</td></tr>
</tbody></table>

<table className="feeinfo"  style={{fontSize: "12px"}}>
<tbody>
<tr className="marginbetween totalinfo"><td colspan='5'><b><i>Note For Bank: Exact Challan Amount Must Be Transferred In Single Transaction,<br/>Amount Must Not Be Transferred If Challan Download Date Is Older Than Current Date.</i></b></td></tr>
<tr className="marginbetween totalinfo"><td><b>Challan Download Date:</b></td><td><b>{data.currentDate}</b></td></tr>

<tr className="marginbetween"><td>Name: </td><td>{data.name}</td></tr>
<tr className="marginbetween"><td>Roll No.: </td><td>{data.rollNo}</td></tr>
<tr className="marginbetween"><td>Contact No.: </td><td>{data.contactNo}</td></tr>
<tr className="marginbetween"><td>Description: </td><td>{data.description}</td></tr>
<tr className="marginbetween"><td>Mode of Payment: </td><td>RTGS-NEFT</td></tr>

<tr className="marginbetween"><td>Beneficiary Name: </td><td>{data.beneficiaryName}</td></tr>
<tr className="marginbetween"><td><b>Beneficiary Account No.: </b></td><td><b>{data.beneficiaryAcNo + data.id}</b></td></tr>
<tr className="marginbetween"><td>Bank Name: </td><td>{data.bankName}</td></tr>
<tr className="marginbetween"><td>Bank Branch: </td><td>{data.branchName}</td></tr>
<tr className="marginbetween"><td>Bank IFSC Code: </td><td>{data.ifscCode}</td></tr>

<tr className="marginbetween"><td>Last Date: </td><td>{data.lastDate}</td></tr>

{/*
<tr className="marginbetween totalinfo"><td> Total Fee: </td><td>Rs.{parseInt(data.currentBalance)+(parseInt(data.fine)*parseInt(data.daysMore))+parseInt(data.previousBalance)+parseInt(data.otherCharges)}</td></tr>
*/}


<tr className="marginbetween totalinfo"><td><b> Total Fee: </b></td><td><b>Rs.{( +data.pendingFee > 0 && +data.transactionCount > 0)? (parseInt(data.pendingFee)+(parseInt(data.fine)*parseInt(data.daysMore))): (parseInt( parseInt(data.currentBalance)+(parseInt(data.fine)*parseInt(data.daysMore))+parseInt(data.previousBalance)+parseInt(data.otherCharges)))}</b></td></tr>

<tr className="marginbetween totalinfo"><td><b> Total Fee (In Words): </b></td><td colspan='5'><b>{inWords(( +data.pendingFee > 0 && +data.transactionCount > 0)? (parseInt(data.pendingFee)+(parseInt(data.fine)*parseInt(data.daysMore))): (parseInt( parseInt(data.currentBalance)+(parseInt(data.fine)*parseInt(data.daysMore))+parseInt(data.previousBalance)+parseInt(data.otherCharges))))}</b></td></tr>
<tr className="marginbetween totalinfo"><td colspan='5'><b> Deposit Date:</b></td></tr>
<tr className="marginbetween totalinfo"><td colspan='5'><b> Name & Seal of Bank with Signature:</b></td></tr>
</tbody>
</table>

</div>
      </div>
    </div>
  );
}

export default PayByChallan;

