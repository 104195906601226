
export const getAuthentication = () => {
const user = JSON.parse(localStorage.getItem('datab')); 
if(user && user.data && user.data.token && user.data.token.length > 10)
return true;
else 
	return false;
}

export const getLocalData = () => {
const user = JSON.parse(localStorage.getItem('datab')); 
return user;   	
}

export const addLocalData = (info) => {
	if( localStorage.setItem("datab", JSON.stringify(info))!=null)
		return true;
	else
		return false;
}

export const addcururl = (urlinfo) => {
	if( localStorage.setItem("datab.cururl", urlinfo)!=null)
		return true;
	else
		return false;
}

export const addCurUrl = (urlinfo) => {
	if( localStorage.setItem("datab.cururl", urlinfo)!=null)
		return true;
	else
		return false;
}

export const getCurUrl = () => {
let appurl = localStorage.getItem('datab.cururl'); 
return appurl;   
}



export const removeLocalData = () => {
		//localStorage.removeItem("datab");
		localStorage.clear();
if(localStorage.getItem('datab') === null)
return true;
else
return false;
}

export const addSNF = (info) => {
	if( localStorage.setItem("datab.snf", JSON.stringify(info))!=null)
		return true;
	else
		return false;
}

export const getSNF = () => {
let snf = JSON.parse(localStorage.getItem('datab.snf')); 
return snf;   	
}

export const addCNF = (info) => {
	if( localStorage.setItem("datab.cnf", JSON.stringify(info))!=null)
		return true;
	else
		return false;
}

export const getCNF = () => {
let cnf = JSON.parse(localStorage.getItem('datab.cnf'));
return cnf;   	
}


export const addMGMT = (info) => {
	if( localStorage.setItem("datab.mgmt", JSON.stringify(info))!=null)
		return true;
	else
		return false;
}

export const getMGMT = () => {
let mgmt = JSON.parse(localStorage.getItem('datab.mgmt')); 
return mgmt;   	
}

export const addLSP = (info) => {
	if( localStorage.setItem("datab.lsp", JSON.stringify(info))!=null)
		return true;
	else
		return false;
}

export const getLSP = () => {
let lsp = JSON.parse(localStorage.getItem('datab.lsp')); 
return lsp;   	
}

export const addTML = (info) => {
	if( localStorage.setItem("datab.tml", JSON.stringify(info))!=null)
		return true;
	else
		return false;
}

export const getTML = () => {
let tml = JSON.parse(localStorage.getItem('datab.tml')); 
return tml;   	
}


export const addIB = (info) => {
	if( localStorage.setItem("datab.ib", JSON.stringify(info))!=null)
		return true;
	else
		return false;
}

export const getIB = () => {
let ib = JSON.parse(localStorage.getItem('datab.ib')); 
return ib;   	
}

