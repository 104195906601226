import axios from 'axios';
import Adapter from './adapter';
import { getLocalData } from './UserInfo';

const headers = {
  "Content-Type": "application/json"
}


export const studentauth = async (url, json) => {
try {
const res = await axios.post(Adapter.getBaseURL()+url, json, {headers: headers});
return res; 
}
catch(error) {
// console.log(error);
return null;
}
}


export const getToken = () => {
// if(getLocalData()!==null) {
var user = getLocalData();
if(user && user.data && user.data.token && user.data.token.length > 4)
return user.data.token;
else 
	return null;
}

export const infosource = async (url, json) => {
try {
const res = await axios.post(Adapter.getBaseURL()+url, json, {headers:   {
  "Content-Type": "application/json",
  "Authorization": `${getToken()}`
}});
return res; 
}
catch(error) {
// console.log(error);
return null;
}
}


export const unauthinfosource = async (url, json) => {
try {
const res = await axios.post(Adapter.getBaseURL()+url, json, {headers:   {
  "Content-Type": "application/json"
}});
return res; 
}
catch(error) {
// console.log(error);
return null;
}
}





export const externalinfosource = async (url, json) => {
try {
const res = await axios.post(url, json, {headers:   {
  "Content-Type": "application/json", "Accept": "application/json", "Access-Control-Allow-Origin": "http://localhost:3000", "Access-Control-Allow-Methods":"POST", "Access-Control-Allow-Headers":"application/json",
}});

return res; 
}
catch(error) {
return error;
}
}


export const getData = async () => {
	var res;
	try {
	res = await axios.get(Adapter.getBaseURL()+"/service/sig");
//	console.log(res.data);
}
catch(error) {
// console.log(error);
res = false;
}
return res;
}


export const getURL = async (url) => {
	var st = false;
	var res;
	try {
	res = await axios.get("http://www.psep.in");
	// console.log("response status");
	
	// console.log(res.status);
	if(res.status==200)
	st = true; 
}
catch(error) {
//console.log(error);
st = false;
}
return st;
}


export const getPDF = async (url) => {
try {
const res = await axios.get(url, {headers: {
  "Content-Type": "application/pdf"
}});
return res; 
} 
catch(error) {
// console.log(error);
return null;
}
}










