import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
// import { checkStatus } from '../status/Status';
// import { checkStatus } from '../adapter/checkStatus';
import '../common/style.css';
const styles = theme => ({
  
});

export class Events extends Component {
	constructor(props) {
    super(props);
    this.state = {
      loading: true,
      connected: false
    };
   }

  
hideSpinner = () => {
    this.setState({loading: false});
  }


render() {

const width = window.innerWidth; // - ((window.innerWidth*10)/100);
    return (
      <div className="container" >
        {((this.state.loading)) ? (<div align="center"><CircularProgress className="loader" variant='indeterminate' color='secondary' /></div>): ""} 
        {(<div><iframe src="https://www.sgrduhs.in/newses.html"
          width= {width}
          height= {window.innerHeight+"px"}
          onLoad={this.hideSpinner}
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          title="news" /></div>)}

      </div>

      );
  }
}

export default withStyles(styles, { withTheme: true })(Events);
